import { SET_FILTER, RESET_FILTERS } from '../actions/actionTypes';
import { isUndefined, isNull, isFullString, isFullArray } from '../../shared/utility';

const initialState = {
    activeSearchLocation: null,
    activeMainType: null,
    selectedItemTypeIds: [],
    activeItemTypes: [],
    availableInPeriodStart: null,
    availableInPeriodEnd: null,
    availableInPeriodTimeIsSet: false,
    itemPropertyIds: [],
    itemCharacteristicIds: null,
    itemCharacteristicValues: null,
    pageNumber: 1,
    pageSize: 20,
    bounds: null,
    paymentAccountId: null
};


const setFilter = (state, action) => {
    const { identifier, value } = action;

    if (isNull(identifier) || state[identifier] === value) {
        return state;
    }

    const newState = { ...state, [identifier]: value };

    if (identifier === 'activeMainType') {
        newState.selectedItemTypeIds = [];
        newState.itemPropertyIds = [];
    }

    if (identifier !== 'pageNumber' && state.pageNumber > 1) {
        newState.pageNumber = 1;
    }
    
    return newState;
};

const resetFilters = (state, action) => {
    let { identifiers } = action;

    if (!isFullArray(identifiers) && !isFullString(identifiers)) {
        identifiers = Object.keys(initialState);
    }else if (isFullString(identifiers)) {
        identifiers = [identifiers];
    }

    return identifiers
        .filter(key => key !== 'paymentAccountId' && !isUndefined(initialState[key]))
        .reduce((newState, identifier) => setFilter(newState, { identifier, value: initialState[identifier] }), state);
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTER: return setFilter(state, action);
        case RESET_FILTERS: return resetFilters(state, action);
        default: return state;
    }
};

export default reducer;
