import React from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../shared/utility';

import Button from '../Button/Button';

import localStyles from './FlashMessage.module.scss';
const styles = getStyles(localStyles);

const FlashMessage = (props) => {
    const {
        success, error,
        title, subtitle,
        primaryButton, secondaryButton,
        noContainer
    } = props;

    const messageContent = (
        <>
            <div className={styles('circle', `circle--${success ? 'success' : error ? 'error' : 'default'}`)}/>
            <h3>{title}</h3>
            {subtitle ? (
                <p className={styles('c--text-light', 'small')}>{subtitle}</p>
            ) : null}
            <div className={secondaryButton ? styles('flash-buttons', 'flex', 'flex--justify-between') : styles('flash-buttons')}>
                {secondaryButton ? (
                    <div className={styles('flash-buttons__button')}>
                        <Button
                            href={secondaryButton.href}
                            events={secondaryButton.events}
                            modifiers={['border', 'stretch', 'm', 'spaced']}
                            classes={styles('flash_message__button')}
                        >{secondaryButton.text}</Button>
                    </div>
                ) : null}
                {primaryButton ? (
                    <div className={styles('flash-buttons__button')}>
                        <Button
                            href={primaryButton.href}
                            events={primaryButton.events}
                            modifiers={['stretch', 'm', 'spaced', (success ? 'primary' : error ? 'error' : 'default')]}
                            classes={styles('flash_message__button')}
                        >{primaryButton.text}</Button>
                    </div>
                ) : null}
            </div>
        </>
    );

    return noContainer ? (
        <div className={styles('align--center')}>
            {messageContent}
        </div>
    ) : (
        <div className={styles('container', 'b--gray-1')}>
            <div className={styles('content', 'content--panel', 'align--center')}>
                {messageContent}
            </div>
        </div>
    );
};

FlashMessage.propTypes = {
    // properties
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    primaryButton: PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
        events: PropTypes.object
    }),
    secondaryButton: PropTypes.shape({
        text: PropTypes.string,
        href: PropTypes.string,
        events: PropTypes.object
    }),
    success: PropTypes.bool,
    error: PropTypes.bool,
    noContainer: PropTypes.bool,
};

export default FlashMessage;
