import React from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../../../shared/utility';

import SearchAutocomplete from '../SearchAutocomplete/SearchAutocomplete';
import SearchToggles from '../SearchToggles/SearchToggles';

import localStyles from './SearchDropdown.module.scss';
const styles = getStyles(localStyles);

const searchDropdown = (props) => (
    <div className={styles(['search-dropdown', (props.isOpen ? 'search-dropdown--open' : null)])}>
        {props.mode !== 'autocomplete' ?
            <SearchToggles
                historyItems={props.historyItems}
                toggleMainItemType={props.toggleMainItemType}
                activateHistoryItem={props.setActiveHistoryItem}
                activateCurrentLocation={props.setActiveCurrentLocation} /> :
            <SearchAutocomplete
                hilighted={props.hilighted}
                updateHilighted={props.updateHilighted} />}
    </div>
);

searchDropdown.propTypes = {
    // properties
    isOpen: PropTypes.bool.isRequired,
    historyItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    hilighted: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    mode: PropTypes.oneOf(['autocomplete', 'toggle']).isRequired,

    // functions
    toggleMainItemType: PropTypes.func.isRequired,
    setActiveHistoryItem: PropTypes.func.isRequired,
    setActiveCurrentLocation: PropTypes.func.isRequired,
    updateHilighted: PropTypes.func.isRequired
};

export default searchDropdown;
