import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../Button/Button';

import { getStyles, isObject, isNull, isFullString, translate } from '../../../shared/utility';

const styles = getStyles();

const officeService = (props) => {
    const buttonContent = isObject(props.communityDetails.data) && isFullString(props.communityDetails.data.name) && 
        (
            props.communityDetails.data.name.toLowerCase() === 'arrangeit' || 
            props.communityDetails.data.name.toLowerCase() === 'nuttig' || 
            props.communityDetails.data.name.toLowerCase() === 'arrangegroup'
        ) ? (
            <Button
                modifiers={['primary','m', 'wide']}
                href={props.communityDetails.data.externalLinks[0].source}
                target={'_blank'}
                external
            >
                {props.communityDetails.data.externalLinks[0].title}
            </Button>
        ) : null;
    return !isNull(buttonContent) && props.container ? (
        <div className={styles('container', 'container--padding-evenly', 'align--center')}>
            <h4>{translate('pages.home.officeService', props.language)}</h4>
            <p >{translate('pages.home.officeServiceSubtitle', props.language)}</p>
            {buttonContent}
        </div>
    ) : buttonContent;
};

officeService.propTypes = {
    // properties
    container: PropTypes.bool,

    communityDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        communityDetails: state.details.community,

        language: state.global.localization.language
    };
};

export default connect(mapStateToProps, null)(officeService);
