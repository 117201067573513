import { translations } from './translations';
import globalStyles from '../scss/global.scss';
import chroma from 'chroma-js';
import cssVars from 'css-vars-ponyfill';

/* * * * * * * * * * * * *
 * DEVELOPMENT UTILITIES *
 * * * * * * * * * * * * */
export const isUndefined = (value) => (typeof value === 'undefined');
export const isDefined = (value) => !isUndefined(value);
export const isNull = (value) => (!value && typeof value === 'object');
export const isNumber = (value) => (typeof value === 'number');
// No support for Number.isInteger by IE
// export const isInteger = (value) => (Number.isInteger(value));
export const isInteger = (value) => (isNumber(value) && isFinite(value) && Math.floor(value) === value);
export const isBoolean = (value) => (typeof value === 'boolean');
export const isString = (value) => (typeof value === 'string');
export const isFullString = (value) => (isString(value) && !!value.trim().length);
export const isEmptyString = (value) => (isString(value) && !value.trim().length);
export const isArray = (value) => (typeof value === 'object' && Array.isArray(value));
export const isFullArray = (value) => (isArray(value) && !!value.length);
export const isEmptyArray = (value) => (isArray(value) && !value.length);
export const isSymbol = (value) => (typeof value === 'symbol');
export const isObject = (value) => (typeof value === 'object' && !isNull(value) && !isArray(value));
export const isFunction = (value) => (typeof value === 'function');
// No support for Object.is by IE
// export const areEqual = (firstValue, secondValue) => Object.is(firstValue, secondValue);
// eslint-disable-next-line
export const areEqual = (x, y) => (x === y ? (x !== 0 || 1 / x === 1 / y) : (x !== x && y !== y));

export const getNestedProperty = (object, props = '') => (isObject(object) && isString(props) ? (
    props.split('.').reduce((acc, prop) => (
        acc && !isNull(acc[prop]) && !isUndefined(acc[prop]) ? acc[prop] : null
    ), object)
) : null);

/* * * * * * * * * * * *
 * USER DATA UTILITIES *
 * * * * * * * * * * * */
export const getUserPreferences = () => (localStorage.getItem('userData') && isObject(JSON.parse(localStorage.getItem('userData')).preferences) ? JSON.parse(localStorage.getItem('userData')).preferences : null);
export const getLanguageCode = () => (localStorage.getItem('languageCode'));
export const getCommunityId = () => (localStorage.getItem('communityId') ? localStorage.getItem('communityId') : null);
export const showPricesIncludingVat = () => (isNull(getUserPreferences()) || getUserPreferences().showPricesIncludingVat);

export const priceFormat = (pricesObj) => (showPricesIncludingVat() ? pricesObj.priceIncludingVat : pricesObj.price);

export const discountFormat = (discountObj) => (showPricesIncludingVat() ? discountObj.discountIncludingVat : discountObj.discount);

/* * * * * * * * *
 * DOM UTILITIES *
 * * * * * * * * */
export const getComputedStyle = (element, property, dropUnit = true) => {
    if (!element || !property) {
        return false;
    }
    const value = window.getComputedStyle(element)[property];
    const floatValue = parseFloat(value);
    return !dropUnit || isNaN(floatValue) ? value : floatValue;
};

export const getElementHeight = (element, round = 'floor') => {
    if (!element) {
        return 0;
    }
    const height = element.getBoundingClientRect().height;
    switch (round) {
        case 'floor': return Math.floor(height);
        case 'ceil': return Math.ceil(height);
        case false: return height;
        default: return Math.round(height);
    }
};

/* * * * * * * * * * *
 * STRING UTILITIES  *
 * * * * * * * * * * */
export const translate = (key, language = 'nl', fallback) => {
    if (window.location.hash === '#debug') {
        return key;
    }

    return !key || !translations[language] ? '' :
        key.split('.').reduce((lookup, currentKey) => (
            isString(lookup) ? lookup : (!isUndefined(lookup[currentKey]) ? lookup[currentKey] : (fallback || (() => {
                console.warn(`"${key}" isn't translated in "${language}"!`);
                return key;
            })()))
        ), translations[language]);
};

export const setCase = (string, mode = 'capitals', opts = {}) => {
    if (!isString(string)) {
        return string;
    }

    string = string.trim();
    const options = { allWords: true, lowerCase: false, ...opts };

    let transform;
    switch (mode) {
        case 'capitals': {
            transform = (str) => str[0].toUpperCase() + (options.lowerCase ? str.substr(1).toLowerCase() : str.substr(1));
            break;
        }
        case 'uppercase': {
            transform = (str) => str.toUpperCase();
            break;
        }
        case 'lowercase': {
            transform = (str) => str.toLowerCase();
            break;
        }
        default: {
            transform = (str) => str;
        }
    }

    return options.allWords ? string.split(' ').map(transform).join(' ') : transform(string);
};

export const localizePrice = (amount, currency, locale = 'nl-NL') => (
    window.Intl ? Intl.NumberFormat(locale, { style: 'currency', currency: (currency || 'EUR') }).format(amount) : `${{ 'EUR': '€', 'USD': '$' }[currency]} ${amount.toFixed(2)}`
);

export const createUniqueIdentifier = (prefix, length = 5, seed = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-') => `${prefix ? prefix.toLowerCase() : ''}_${Array(Math.max(Math.min(32, length), 1)).fill().reduce((id) => `${id}${seed[Math.floor(Math.random() * seed.length)]}`, '')}`;

/* * * * * * * * * * * * * * *
 * OBJECT / ARRAY UTILITIES  *
 * * * * * * * * * * * * * * */
export const createArray = (obj) => obj ? Object.entries(obj).map((item) => ({ id: item[0], config: item[1] })) : [];
export const stringToArray = (input) => (isArray(input) ? input : isString(input) ? input.split(' ') : []);
export const addToArray = (newItems, currentArray) => (
    stringToArray(currentArray)
        .concat(stringToArray(newItems))
        .filter((value, index, outputArray) => value && outputArray.indexOf(value) === index)
);

/* * * * * * * * * * * * * * * * *
 * COMPONENT PROPERTY UTILITIES  *
 * * * * * * * * * * * * * * * * */
export const getStyles = (localStyles = {}) => function () {
    return [ ...arguments ]
        .reduce((output, classNames) => (
            output.concat(stringToArray(classNames).map((className) => (localStyles[className] || globalStyles[className] || className)))
        ), [])
        .filter((className, index, output) => className && output.indexOf(className) === index)
        .join(' ').trim();
};

export const addModifierClasses = (modifiers, baseClassName, currentClasses) => {
    modifiers = stringToArray(modifiers)
        .map((mod) => (baseClassName && mod) ? `${baseClassName}--${mod}` : false);
    return addToArray(modifiers, currentClasses);
};

export const getEventAttributes = (events, targetObject) => {
    const isObject = (test) => test && test instanceof Object && test.constructor === Object;
    events = isObject(events) ? events : {};
    targetObject = isObject(targetObject) ? targetObject : {};
    for (let event in events) {
        if (event) {
            const eventAttribute = (`on${event[0].toUpperCase()}${event.substr(1)}`);
            targetObject[eventAttribute] = events[event];
        }
    }

    return targetObject;
};

/* * * * * * * * * *
 * COLOR UTILITIES *
 * * * * * * * * * */
const textColors = { 'dark': '#383f47', 'light': '#fefefe' };
export const getTextColor = (backgroundColor) => chroma(backgroundColor).luminance() >= 0.5 ? textColors.dark : textColors.light;

const colorModifiers = {
    'color': (color) => color,
    'color-dark': (color) => chroma(color).darken().hex(),
    'color-light': (color) => chroma(color).brighten().hex(),
    'color-faded': (color) => chroma(color).alpha(0.4).css()
};
export const setBrandColors = (primaryBrandColor, secondaryBrandColor) => {
    const root = document.documentElement;
    const colorModifierNames = Object.keys(colorModifiers);

    for (let i = 0; i < colorModifierNames.length; ++i) {
        const mod = colorModifierNames[i];

        const primaryColor = colorModifiers[mod](primaryBrandColor);
        const secondaryColor = colorModifiers[mod](secondaryBrandColor);

        root.style.setProperty(`--primary-${mod}`, primaryColor);
        root.style.setProperty(`--primary-${mod}-text`, getTextColor(primaryColor));
        root.style.setProperty(`--secondary-${mod}`, secondaryColor);
        root.style.setProperty(`--secondary-${mod}-text`, getTextColor(secondaryColor));

        cssVars({
            variables: {
                [`primary-${mod}`]: primaryColor,
                [`primary-${mod}-text`]: getTextColor(primaryColor),
                [`secondary-${mod}`]: secondaryColor,
                [`secondary-${mod}-text`]: getTextColor(secondaryColor)
            }
        });
    }
};

/* * * * * * * * *
 * GTM UTILITIES *
 * * * * * * * * */
export const pushEvent = (event, { eventCategory, eventAction, eventLabel, eventData }) => {
    window.dataLayer = window.dataLayer || [];
    if (!isUndefined(event)) {
        window.dataLayer.push({
            event,
            ...(!isUndefined(eventCategory) && { eventCategory }),
            ...(!isUndefined(eventAction) && { eventAction }),
            ...(!isUndefined(eventLabel) && { eventLabel }),
            ...(!isUndefined(eventData) && { eventData }),
        });
    }
};
