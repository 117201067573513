import React from 'react';
import PropTypes from 'prop-types';
import { getStyles, translate, isFullArray } from '../../../shared/utility';

import Dots from '../../../components/UI/Loading/Dots/Dots';
import Card from '../../../components/UI/Card/Card';
import HorizontalScroll from '../../../components/UI/HorizontalScroll/HorizontalScroll';

import localStyles from './NearbyItems.module.scss';
const styles = getStyles(localStyles);

const nearbyItems = (props) => props.loading || isFullArray(props.data) ? (
    <div className={styles('container', 'container--padding-evenly', 'nearby')}>
        <div className={styles('content')}>
            <h4>
                {translate('pages.home.nearby', props.language)}{props.loading ? (
                    <Dots />
                ) : null}
            </h4>
        </div>
        <div className={styles('content', 'content--padding-collapsed')}>
            <HorizontalScroll noOverlay selectorQuery={'li'} buttonSize={'xl'}>
                <ul className={styles('no-list-style', 'nearby__items', 'flex')}>
                    {props.loading ? [0, 1, 2].map(item => (
                        <li key={item} className={styles('nearby__item')}>
                            <Card loading={true} viewMode={'card'} />
                        </li>
                    )) : props.data.map(item => (
                        <li key={item.id} className={styles('nearby__item')}>
                            <Card item={item} viewMode={'card'} />
                        </li>
                    ))}
                </ul>
            </HorizontalScroll>
        </div>
    </div>
) : null;


nearbyItems.propTypes = {
    // properties
    data: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

    language: PropTypes.string.isRequired
};

export default nearbyItems;
