import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import smoothScroll from 'smoothscroll';
import { getStyles, isUndefined, isNumber, isEmptyString, isFullString } from '../../../../shared/utility';

import Icon from '../../Icon/Icon';
import Popup from '../../Popup/Popup';

import DropdownOption from './DropdownOption/DropdownOption';

import localStyles from './Dropdown.module.scss';
const styles = getStyles(localStyles);

const MIN_SCROLL_DURATION = 60;
const MAX_SCROLL_DURATION = 280;

const Dropdown = React.memo((props) => {
    const {
        collapsed,
        noInput,
        callback,

        multiple,
        max,

        inputClasses,
        inputRef,
        inputHasFocus,

        id,
        name,
        required,
        disabled,

        placeholder,
        placeholderIcon,
        placeholderCustomIcon
    } = props;

    /* * * * *
     * VALUE *
    ** * * * */
    const selectRef = useRef();
    const listRef = useRef();

    const [value, setValue] = useState([].concat(props.value));

    // add change event to select programmatically
    // so it can be dispatched programmatically later
    useEffect(() => {
        const select = selectRef.current;
        if (select) {
            select.addEventListener('change', callback);
        }
        return () => {
            if (select) {
                select.removeEventListener('change', callback);
            }
        };
    }, [callback]);

    // callback when selecting an option
    const selectDropdownOption = useCallback((option) => {
        const { selected, index, value } = option;
        if (multiple) {
            setFocus(index);
            setValue(p => selected ? p.filter(v => v !== value) : max > 1 && p.length <= max ? p.concat(value) : p);
            return;
        }
        setValue([value]);
        setIsOpen(false);
    }, [max, multiple]);

    // select options programmatically
    // and dispatch change event
    useEffect(() => {
        const select = selectRef.current;
        if (select) {
            const optionElements = Array.from(select.querySelectorAll('option'));
            optionElements.forEach(el => {
                el.selected = value.map(v => `${v}`).includes(el.value);
            });
            const changeEvent = new Event('change');
            select.dispatchEvent(changeEvent);
        }
    }, [value]);

    /* * * * * *
     * OPTIONS *
    ** * * * * */
    const options = useMemo(() => {
        return (props.options || []).map((option, index) => {
            const { id, value: optVal, disabled } = option;
            const optionId = isNumber(id) || isFullString(id) ? id : isNumber(optVal) || isFullString(optVal) ? optVal : index;
            const optionValue = !isUndefined(optVal) ? optVal : optionId;
            const optionDisabled = disabled || false;
            const optionSelected = !optionDisabled && value.includes(optionValue);

            return {
                ...option,
                index,
                id: optionId,
                value: optionValue,
                disabled: optionDisabled,
                selected: optionSelected
            };
        })
            // merge 'duplicate' options
            .reduce((carry, option, index, allOptions) => {
                const sameOptionIndex = allOptions.findIndex(({ id }) => id === option.id);
                if (sameOptionIndex !== index) {
                    return carry.concat({ ...allOptions[sameOptionIndex], ...option });
                }
                return carry.concat(option);
            }, [])
            // filter out options with duplicate id's
            .filter((option, index, allOptions) => allOptions.findIndex(({ id }) => id === option.id) === index)
            // filter out selected options which shouldn't be selected
            .map((option, index, allOptions) => {
                const selectedOptions = allOptions.filter(({ selected }) => selected);
                const hasMax = max > 1;

                if (!option.selected || !hasMax || selectedOptions.length <= max) {
                    return option;
                }

                const actualMax = multiple ? max : 1;
                const selectedOptionIndex = selectedOptions.findIndex(({ id }) => id === option.id);
                return { ...option, selected: selectedOptionIndex < actualMax };
            });
    }, [props.options, value, max, multiple]);

    const hasIcons = !collapsed && options.some(({ icon, customIcon }) => icon || customIcon);

    /* * * * * * * * *
     * FOCUS OPTION  *
    ** * * * * * * * */
    const selectedOptionIndex = options.findIndex(({ selected }) => selected);
    const [focus, setFocus] = useState(selectedOptionIndex > -1 ? selectedOptionIndex : 0);

    const scrollDropdown = useCallback((itemIndex, smooth) => {
        const list = listRef.current;
        if (list) {
            const container = list.parentNode;
            const cHeight = container.offsetHeight;
            const cScrollTop = container.scrollTop;

            const item = list.childNodes[itemIndex];
            const height = item.offsetHeight;
            let offset = item.offsetTop;

            if (offset >= cScrollTop && offset + height <= cScrollTop + cHeight) {
                return;
            }

            if (smooth) {
                const duration = Math.max(Math.min(Math.abs(cScrollTop - offset), MAX_SCROLL_DURATION), MIN_SCROLL_DURATION);
                smoothScroll(offset, duration, null, container);
            } else {
                container.scrollTop = offset;
            }
        }
    }, []);

    /* * * * * * * * * *
     * DROPDOWN STATE  *
    ** * * * * * * * * */
    const [isOpen, setIsOpen] = useState(false);
    const openDropdown = useCallback(() => {
        setIsOpen(!disabled);
        if (!disabled) {
            scrollDropdown(focus, false);
        }
    }, [disabled, focus, scrollDropdown]);

    // open dropdown 'automatically' when noInput == true and there are options available
    useEffect(() => {
        if (noInput && inputHasFocus) {
            setIsOpen(true);
        }
    }, [noInput, inputHasFocus]);

    /* * * * * * * * *
     * SEARCH BUFFER *
    ** * * * * * * * */
    const [searchBuffer, setSearchBuffer] = useState('');
    const addToSearchBuffer = useCallback((key) => {
        setSearchBuffer(p => `${p}${key.toLowerCase()}`);
    }, []);

    const handleSearch = useCallback((search) => {
        const optionIndex = options.findIndex(({ text, value, disabled }) => (
            !disabled && (text || value).toLowerCase().substr(0, search.length) === search
        ));
        if (optionIndex > -1) {
            if (isOpen) {
                setFocus(optionIndex);
                scrollDropdown(optionIndex, true);
            } else {
                selectDropdownOption(options[optionIndex]);
            }
        }
    }, [options, isOpen, scrollDropdown, selectDropdownOption]);

    useEffect(() => {
        let timer;
        if (searchBuffer) {
            timer = setTimeout(() => { setSearchBuffer(''); }, 500);
            handleSearch(searchBuffer);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [searchBuffer, handleSearch]);

    /* * * * * * * * *
     * DISPLAY VALUE *
    ** * * * * * * * */
    const { displayValue, displayIcon } = useMemo(() => {
        const selectedOptions = options.filter(({ selected }) => selected);

        let displayValue;
        let displayIcon;

        if (!selectedOptions.length) {
            displayValue = placeholder;
            displayIcon = placeholderCustomIcon || placeholderIcon ? (
                <Icon
                    icon={placeholderIcon}
                    customIcon={placeholderCustomIcon}
                    classes={styles('display__icon')}
                />
            ) : null;
        } else if (selectedOptions.length > 1) {
            const valArr = selectedOptions.map(({ value, text }) => text || value);
            const lastValue = valArr.pop();
            displayValue = `${valArr.join(', ')} & ${lastValue}`;
            displayIcon = null;
        } else {
            const opt = selectedOptions[0];
            displayValue = opt.html || opt.text || opt.value;
            displayIcon = opt.icon || opt.customIcon ? (
                <Icon
                    icon={opt.icon}
                    customIcon={opt.customIcon}
                    classes={styles('display__icon')}
                />
            ) : null;
        }

        return { displayValue, displayIcon };
    }, [placeholder, placeholderIcon, placeholderCustomIcon, options]);

    /* * * * * * * * * *
     * KEYBOARD EVENTS *
    ** * * * * * * * * */
    // |-----------------|--------------------|--------------------|
    // | KEY             | ■ DROPDOWN OPENED  | ■ DROPDOWN CLOSED  |
    // |-----------------|--------------------|--------------------|
    // | ENTER           | ■ SELECT VALUE     | ■ DEFAULT          |
    // | SPACE           | ■ SELECT VALUE     | ■ OPEN DROPDOWN    |
    // | TAB             | ■ IGNORE           | ■ DEFAULT          |
    // | ARROW UP        | ■ HILIGHT PREV VAL | ■ OPEN DROPDOWN    |
    // | ARROW DOWN      | ■ HILIGHT NEXT VAL | ■ OPEN DROPDOWN    |
    // | /^[\S ]{1}$/    | ■ SEARCH           | ■ SEARCH & SELECT  |
    // | ESCAPE*         | ■ CLOSE DROPDOWN   | ■ DEFAULT          |
    // |-----------------|--------------------|--------------------|
    //  * handled by <Popup/> component

    const dropdownClosedKeyEvents = useCallback((event) => {
        if (noInput) {
            return;
        }

        const { key } = event;
        if (['ArrowUp', 'ArrowDown', ' '].includes(key)) {
            event.preventDefault();
            switch (key) {
                case 'ArrowUp':
                case 'ArrowDown': {
                    openDropdown();
                    return;
                }
                case ' ':
                    if (isEmptyString(searchBuffer)) {
                        openDropdown();
                        return;
                    }
                    break;
                default: {
                    return;
                }
            }
        }

        if (/^[\S ]{1}$/.test(key)) {
            event.preventDefault();
            addToSearchBuffer(key);
        }
    }, [searchBuffer, addToSearchBuffer, openDropdown, noInput]);

    const dropdownOpenedKeyEvents = useCallback((event) => {
        const { key } = event;
        const keysToHandle = ['ArrowUp', 'ArrowDown', 'Enter', 'Tab'].concat(noInput ? [] : ' ');
        if (keysToHandle.includes(key)) {
            event.preventDefault();
            const focussedOption = options[focus];
            const count = options.length;
            switch (key) {
                case 'Enter': {
                    selectDropdownOption(focussedOption);
                    return;
                }
                case ' ': {
                    if (isEmptyString(searchBuffer)) {
                        selectDropdownOption(focussedOption);
                        return;
                    }
                    break;
                }
                case 'ArrowUp': {
                    setFocus(p => {
                        let i = 1;
                        while (i < count && options[(count + p - i)%count].disabled) {
                            i++;
                        }
                        const newFocus = (count + p - i)%count;
                        scrollDropdown(newFocus, true);
                        return newFocus;
                    });
                    return;
                }
                case 'ArrowDown': {
                    setFocus(p => {
                        let i = 1;
                        while (i < count && options[(p + i)%count].disabled) {
                            i++;
                        }
                        const newFocus = (p + i)%count;
                        scrollDropdown(newFocus, true, true);
                        return newFocus;
                    });
                    return;
                }
                default: {
                    return;
                }
            }
        }

        if (!noInput && /^[\S ]{1}$/.test(key)) {
            event.preventDefault();
            addToSearchBuffer(key);
        }
    }, [options, focus, searchBuffer, addToSearchBuffer, selectDropdownOption, scrollDropdown, noInput]);

    useEffect(() => {
        if (isOpen) {
            document.body.addEventListener('keydown', dropdownOpenedKeyEvents);
        } else if (inputHasFocus) {
            document.body.addEventListener('keydown', dropdownClosedKeyEvents);
        }

        return () => {
            document.body.removeEventListener('keydown', dropdownOpenedKeyEvents);
            document.body.removeEventListener('keydown', dropdownClosedKeyEvents);
        };
    }, [dropdownClosedKeyEvents, dropdownOpenedKeyEvents, inputHasFocus, isOpen]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    const popupOutput = (
        <>
            <Popup
                isOpen={isOpen && !disabled}
                close={() => { setIsOpen(false); }}
                targets={inputRef.current}
                arrowPosition={collapsed ? 'center' : noInput ? 'left' : 'right'}
                collapsed={collapsed}
                stretch={!collapsed}
            >
                <ul ref={listRef} className={styles('list', 'no-list-style', 'b--background', (collapsed ? 'list--collapsed' : ''))}>
                    {options.map((opt, index) => (
                        <DropdownOption
                            key={opt.id}
                            html={opt.html}
                            text={opt.text}
                            icon={opt.icon}
                            customIcon={opt.customIcon}
                            collapsed={collapsed}
                            multiple={multiple}
                            hasIcons={hasIcons}
                            disabled={opt.disabled}
                            focussed={!opt.disabled && focus === index}
                            selected={!opt.disabled && value.includes(opt.value)}
                            focus={opt.disabled ? null : () => { setFocus(index); }}
                            select={opt.disabled ? null : () => { selectDropdownOption(opt); }}
                        />
                    ))}
                </ul>
            </Popup>
            <select
                ref={selectRef}
                id={id}
                name={name}
                className={styles('select')}
                multiple={multiple}
                defaultValue={multiple ? value : value[0]}
                required={required}
                disabled={disabled}
            >
                {options.map(({ value, disabled, text}, index) => (
                    <option key={`select-option-${index}`} value={value} disabled={disabled}>{text}</option>
                ))}
            </select>
        </>
    );

    if (noInput) {
        return popupOutput;
    }

    return (
        <div
            ref={inputRef}
            className={styles(['wrapper'].concat(inputClasses).concat(isOpen ? 'input--focus' : []))}
            tabIndex={0}
            onClick={openDropdown}
            disabled={disabled}
        >
            <div className={styles('display', 'flex', 'flex--align-center')}>
                {displayIcon}
                <div className={styles('display__value')}>
                    {displayValue}
                </div>
                {!collapsed ? (
                    <Icon icon={'arrow-down'} modifiers={['small']} classes={styles('display__caret')}/>
                ) : null}
            </div>
            {popupOutput}
        </div>
    );
});

Dropdown.propTypes = {
    id: PropTypes.any,
    name: PropTypes.string,
    value: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,

    placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    placeholderIcon: PropTypes.string,
    placeholderCustomIcon: PropTypes.string,

    inputRef: PropTypes.object.isRequired,
    inputClasses: PropTypes.arrayOf(PropTypes.string),
    inputHasFocus: PropTypes.bool.isRequired,

    callback: PropTypes.func.isRequired,

    multiple: PropTypes.bool,
    max: PropTypes.number,

    collapsed: PropTypes.bool,
    noInput: PropTypes.bool,

    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,
            value: PropTypes.any,
            text: PropTypes.string.isRequired,
            selected: PropTypes.bool,
            disabled: PropTypes.bool,
            html: PropTypes.node,
            classes: PropTypes.string,
            icon: PropTypes.string,
            customIcon: PropTypes.string
        })
    ).isRequired,

    language: PropTypes.string.isRequired,

    // separator: PropTypes.string,
    // functions
};

const mapStateToProps = state => {
    return {
        language: state.global.localization.language
    };
};

export default connect(mapStateToProps, null)(Dropdown);
