import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStyles, translate, isObject, isFullArray } from '../../../shared/utility';

import Logo from '../../Logo/Logo';
import Modal from '../../UI/Modal/Modal';
import Button from '../../UI/Button/Button';
import Bar from '../../UI/Loading/Bar/Bar';
import PoweredBy from '../../UI/PoweredBy/PoweredBy';
import AsyncContent from '../../UI/AsyncContent/AsyncContent';

import localStyles from './Footer.module.scss';
const styles = getStyles(localStyles);

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            termsConditionsModalIsOpen: false,
            termsContent: null
        };
    }

    openTermsConditionsModal = (terms) => {
        this.setState({ termsConditionsModalIsOpen: true, termsContent: terms });
    }
    closeTermsConditionsModal = () => {
        this.setState({ termsConditionsModalIsOpen: false, termsContent: null });
    }

    render() {
        const { data: communityDetailsData, loading: communityDetailsLoading } = this.props.communityDetails;
        const { data: mainTypesListData, loading: mainTypesListLoading } = this.props.mainTypesList;
        const { data: communityCompanyData, loading: communityCompanyLoading } = this.props.communityCompany;
        const { data: communityContactData, loading: communityContactLoading } = this.props.communityContact;
        const loadingFooter = communityDetailsLoading || mainTypesListLoading || communityCompanyLoading || communityContactLoading;
        const loadingItem = [0,1,2].map(item => <Bar key={item} />);

        const communityExternalLinks = isObject(communityDetailsData) && isFullArray(communityDetailsData.externalLinks) ? (
            communityDetailsData.externalLinks.map(item => (
                <a key={item.title}
                    href={item.source}
                    className={styles('inverted')}
                >
                    {item.title}
                </a>
            ))
        ) : [];
        const legalStatementReferences = isObject(communityDetailsData) && isFullArray(communityDetailsData.legalStatementReferences) ? (
            communityDetailsData.legalStatementReferences.map(item => (
                <Button key={item.description}
                    events={{ click: () => this.openTermsConditionsModal(item) }}
                    classes={styles('inverted')}
                    modifiers={['text']}
                >
                    {item.description}
                </Button>
            ))
        ) : [];

        const mainTypesContent = isFullArray(mainTypesListData) ? (
            mainTypesListData.map(item => (
                <NavLink key={`${item.id}-${item.translations[this.props.language]}`}
                    to={`/items/${item.id}`}
                    className={styles('inverted')}
                >
                    {item.translations[this.props.language]}
                </NavLink>
            ))
        ) : [];

        const addressContent = isObject(communityCompanyData) ? (
            [
                <Fragment key={`${communityCompanyData.id}-${communityCompanyData.name}`}>
                    {communityCompanyData.name}<br />
                    {communityCompanyData.address.street} {communityCompanyData.address.number}<br />
                    {communityCompanyData.address.postalCode} {communityCompanyData.address.city}<br/><br/>
                    {communityCompanyData.chamberOfCommerceNumber ? (
                        <>
                            {`${translate('footer.chamberOfCommerce', this.props.language)}: ${communityCompanyData.chamberOfCommerceNumber}`}
                            <br />
                        </>
                    ) : null}
                    {communityCompanyData.vatNumber ? (
                        <>
                            {`${translate('footer.tax', this.props.language)}: ${communityCompanyData.vatNumber}`}
                            <br />
                        </>
                    ) : null}
                    {communityCompanyData.bankAccountNumber ? `${communityCompanyData.bankAccountNumber}` : null}
                </Fragment>
            ]
        ) : [];

        const contactContent = isObject(communityContactData) ? ([
            <Fragment key={communityContactData.name}>
                {communityContactData.name}<br />
                <a href={`mailto:${communityContactData.emailAddress}`} className={styles('inverted')}>
                    {communityContactData.emailAddress}
                </a><br />
                <a href={`tel:${communityContactData.telephoneNumber}`} className={styles('inverted')}>
                    {communityContactData.telephoneNumber}
                </a>
            </Fragment>
        ]) : [];

        const socialContent = [];

        const footerContent = [
            {
                title: translate('footer.columns.discover', this.props.language),
                items: [...(loadingFooter ? loadingItem : mainTypesContent)]
            },
            {
                title: translate('footer.columns.company', this.props.language),
                items: [...(loadingFooter ? loadingItem : [...communityExternalLinks, ...legalStatementReferences])]
            },
            {
                title: translate('footer.columns.address', this.props.language),
                items: [...(loadingFooter ? loadingItem : addressContent)]
            },
            {
                title: translate('footer.columns.contact', this.props.language),
                items: [...(loadingFooter ? loadingItem : contactContent)]
            },
            {
                title: translate('footer.columns.social', this.props.language),
                items: socialContent
            }
        ];

        const termsAndConditionsModal = this.state.termsConditionsModalIsOpen && this.state.termsContent ? (
            <Modal
                isOpen={this.state.termsConditionsModalIsOpen}
                title={this.state.termsContent.description}
                modifiers={['large']}
                close={this.closeTermsConditionsModal}
            >
                <AsyncContent url={this.state.termsContent.source} />
            </Modal>
        ) : null;

        return (
            <footer className={styles('footer', 'container', 'b--gray-1')}   onClick={this.props.onVoid}>
                <div className={styles('content')}>
                    <div className={styles('footer__logo')}>
                        <Logo custom={communityDetailsData && communityDetailsData.applicationCustomization.footerLogoImageReference ? communityDetailsData.applicationCustomization.footerLogoImageReference.source : null} loading={loadingFooter} classes={styles('footer__logo__img')} />
                    </div>
                    <ul className={styles('footer__columns', 'flex', 'flex--wrap', 'no-list-style', 'c--text-light')}>
                        {footerContent.map(footElement => (
                            isObject(footElement) && isFullArray(footElement.items) ? (
                                <li key={footElement.title} className={styles('footer__column', 'col--50', 'col__xxs--33', 'col__xs--25', 'col__s--20')}>
                                    <h6 className={styles('footer__column__title')}>{footElement.title}</h6>
                                    <ul className={styles('no-list-style')}>
                                        {footElement.items.map((item, index) => !item ? null : (
                                            <li key={index}>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            ) : null
                        ))}
                    </ul>
                    <PoweredBy />
                </div>
                {termsAndConditionsModal}
            </footer>
        );
    }
}

Footer.propTypes = {
    // properties
    mainTypesList: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    communityDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    communityCompany: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    communityContact: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,

    onVoid: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        mainTypesList: state.list.mainTypes,

        communityDetails: state.details.community,
        communityCompany: state.details.communityCompany,
        communityContact: state.details.communityContact,

        language: state.global.localization.language
    };
};

export default connect(mapStateToProps, null)(Footer);
