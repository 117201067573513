import { SET_FILTER, RESET_FILTERS } from './actionTypes';

export const setFilter = (identifier = null, value = null) => {
    return {
        type: SET_FILTER,
        identifier: identifier,
        value: value
    };
};

export const resetFilters = (identifiers) => {
    return {
        type: RESET_FILTERS,
        identifiers: identifiers
    };
};