import { SET_GLOBAL_STATE, UPDATE_REDIRECT_OBJECT, REMOVE_NOTIFICATION, ADD_NOTIFICATION, SET_LOCALIZATION, SET_WINDOW_DIMENSIONS, SET_HEADER_HEIGHT, SET_STICKY_NAVIGATION_HEIGHT,  SET_SCROLLBAR_WIDTH } from '../actions/actionTypes';
import { isUndefined, isArray } from '../../shared/utility';

const initialState = {
    // conditional
    isLoginOpen: false,
    isSignupOpen: false,
    isMenuOpen: false,
    isLanguageMenuOpen: false,
    isMobileSearchOpen: false,
    isGuestsInfoOpen: false, 
    isLinkPaymentAccountOpen: false,
    
    isDriversLicenseVerificationModalOpen: false,

    // values
    userRedirect: null,
    activeCartItem: null,
    activeCartProduct: null,
    currentLocationAvailable: 'geolocation' in navigator && document.location.protocol === 'https:',
    // others
    notifications: [],
    localization: {
        available: null,
        locale: null,
        language: null,
        region: null
    },
    newMeeting: null,
    // ui
    windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight
    },
    headerHeight: 0,
    scrollBarWidth: 0
};

/* * * * * * * * * * * * * * * * * * * * * * * * *
* METHODS THAT UPDATE STATES WITH CORRECT VALUES *
* * * * * * * * * * * * * * * * * * * * * * * *  */
// set global state's value
const setGlobalState = (state, action) => {
    const updatedStates = isArray(action.identifier) ? action.identifier.reduce((keys, currentKey) => {
        keys[currentKey] = !isUndefined(action.value) ? action.value : false;
        return keys;
    }, {}) :
        {[action.identifier]: !isUndefined(action.value) ? action.value : !state[action.identifier]};

    return {...state, ...updatedStates};
};

const updateRedirect = (state, action) => {
    // optional object properties
    const updatedRedirect = {
        ...(action.pathname && {pathname: action.pathname}),
        ...(action.search && {search: action.search}),
        ...(action.state && {state: action.state})
    };

    const updatedState = {...state.userRedirect, ...updatedRedirect};
    return {...state, ...{
        userRedirect: updatedState
    }};
};

// NOTIFICATIONS
const addNotification = (state, action) => {
    // create notifications array that has previous notifications
    // create notification object that holds the passed data and add unique id
    let notificationId = false;
    let iterator = 0;
    const isExistingId = (id) => state.notifications.find(notification => notification.id === id);
    while (!notificationId || isExistingId(notificationId)) {
        notificationId = `${action.data.message.replace(/\s/g, '').substr(0, 5).toLowerCase()}_${state.notifications.length}${iterator ? '_' + iterator : ''}`;
        iterator++;
    }
    const notifyData = {
        id: notificationId,
        message: action.data.message,
        type: action.data.type,
        timeout: (action.data.timeout || (action.data.type === 'error' ? 10 : 5))
    };
    const updatedData = state.notifications.concat(notifyData);
    // add notification object to the array with previus notifications
    // updatedData.push(notifyData);
    return {...state, ...{
        notifications: updatedData
    }};
};

const removeNotification = (state, action) => {
    const updatedData = state.notifications.filter(notification => notification.id !== action.id);

    return {...state, ...{
        notifications: updatedData
    }};
};

// LOCALIZATION
const setLocalization = (state, action) => {
    const updatedLocalization = {...state.localization, ...{
        available: action.available,
        locale: action.locale,
        language: action.language,
        region: action.region
    }};

    return {...state, ...{ localization: updatedLocalization }};
};

// UI
const setWindowDimensions = (state, action) => ({...state, ...{ windowDimensions: action.windowDimensions }});

const setHeaderHeight = (state, action) => ({...state, ...{ headerHeight: action.headerHeight }});

const setStickyNavigationHeight = (state, action) => ({...state, ...{ stickyNavigationHeight: action.stickyNavigationHeight }});

const setScrollBarWidth = (state, action) => ({...state, ...{ scrollBarWidth: action.scrollBarWidth }});

/* * * * * * * * * *
 * REDUCER METHOD  *
 * * * * * * * * * */
// Depending on the active action type, execute the correct function to update the correct states with the correct values
// Pass two parameters, first is the old state values, second is the new state values, these are passed from actions
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_GLOBAL_STATE: return setGlobalState(state, action);
        case UPDATE_REDIRECT_OBJECT: return updateRedirect(state, action);
        case ADD_NOTIFICATION: return addNotification(state, action);
        case REMOVE_NOTIFICATION: return removeNotification(state, action);
        case SET_LOCALIZATION: return setLocalization(state, action);
        case SET_WINDOW_DIMENSIONS: return setWindowDimensions(state, action);
        case SET_HEADER_HEIGHT: return setHeaderHeight(state, action);
        case SET_STICKY_NAVIGATION_HEIGHT: return setStickyNavigationHeight(state, action);
        case SET_SCROLLBAR_WIDTH: return setScrollBarWidth(state, action);
        default: return state;
    }
};

export default reducer;
