import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getStyles, isArray } from '../../../shared/utility';
import Button from '../Button/Button';

import placeholder from '../../../assets/images/ic-placeholder.svg';

import localStyles from './Gallery.module.scss';
const styles = getStyles(localStyles);

class Gallery extends PureComponent {
    constructor(props) {
        super(props);
        const images = props.images.filter((image) => image ? true : false)
            .sort((a, b) => a.order < b.order ? -1 : 1)
            .map((image, index) => ({ description: image.description, source: image.source, order: (index + 1) }));

        this.state = {
            images: images,
            count: images.length,   // save the amount of images
            active: 1   // 1-based index of active image
        };
    }

    /* * * * * * * * *
     * REACT METHODS *
     * * * * * * * * */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.images !== this.props.images && isArray(this.props.images)) {
            const images = this.props.images.filter((image) => image ? true : false)
                .sort((a, b) => a.order < b.order ? -1 : 1)
                .map((image, index) => ({ description: image.description, source: image.source, order: (index + 1) }));

            this.setState({ images: images, count: images.length, active: 1 });
        }
    }


    /* * * * * * * * * *
     * EVENT HANDLERS  *
     * * * * * * * * * */
    slidePrev = (event) => this.slideTo(event, -1);
    slideNext = (event) => this.slideTo(event, 1);

    /* * * * * * * * * *
     * CUSTOM METHODS  *
     * * * * * * * * * */
    slideTo = (event, delta) => {
        event.stopPropagation();
        event.preventDefault();
        this.setState((prevState) => ({ active: Math.max(1, Math.min(prevState.count, prevState.active + delta)) }));
    }

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const placeholderImage = (<img src={placeholder} alt='' className={styles('gallery__image', 'gallery__image--placeholder')} />);
        const imgSize = this.props.size ? `&size=${this.props.size}` : '';

        const images = this.state.images.map((image, index) => (
            <img key={index} src={`${image.source}${imgSize}`} alt={(image.description || '')}
                className={styles('gallery__image', (image.order === this.state.active ? 'gallery__image--active' : ''))} />
        ));

        const buttonPrev = this.state.count > 1 && this.state.active > 1 ? (
            <Button
                classes={styles('gallery__button', 'gallery__button--prev')}
                modifiers={['square', 'transparent', 's']}
                events={{ click: this.slidePrev }}
                icon={'arrow-left'}
                iconColor={'white'}
            />
        ) : null;

        const buttonNext = this.state.count > 1 && this.state.active < this.state.count ? (
            <Button
                classes={styles('gallery__button', 'gallery__button--next')}
                modifiers={['square', 'transparent', 's']}
                events={{ click: this.slideNext }}
                icon={'arrow-right'}
                iconColor={'white'}
            />
        ) : null;

        return (
            <div className={styles('gallery', this.props.classes)}>
                {this.state.count ? images : placeholderImage}
                {buttonPrev}
                {buttonNext}
            </div>
        );
    }
}

Gallery.propTypes = {
    // properties
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    size: PropTypes.number,
    classes: PropTypes.string,
};

export default Gallery;
