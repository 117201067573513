import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, translate, isFullArray, isObject } from '../../../../../shared/utility';

import Button from '../../../../UI/Button/Button';
import Input from '../../../../UI/Input/Input';
import Dots from '../../../../UI/Loading/Dots/Dots';
import Icon from '../../../../UI/Icon/Icon';

import localStyles from './SearchToggles.module.scss';
const styles = getStyles(localStyles);

const searchToggles = (props) => {
    const { activeSearchLocation, activeMainType } = props.filters;

    const historyItems = props.historyItems.slice(
        (!isObject(activeSearchLocation) ? -5 : -6),
        (!isObject(activeSearchLocation) ? props.historyItems.length : -1)
    ).map(item => (
        <li key={item.id} className={styles('search-section__item')}>
            <Button
                modifiers={['text', 'stretch']}
                classes={styles('search-section__item__button')}
                events={{ click: () => props.activateHistoryItem(item) }}
            >
                <Icon icon={'time'} modifiers={['inline']} />
                <span className={styles('search-section__item__content')}>
                    {item.id === 'CURRENT_LOCATION' ? translate('toolbar.searchbar.currentLocation.tagValue', props.language) : item.value}
                </span>
            </Button>
        </li>
    )).reverse();

    return (
        <>
            {isFullArray(props.mainTypesList.data) ? (
                <>
                    <h6>{translate('toolbar.searchbar.types.title', props.language)}</h6>
                    <ul className={styles('types', 'no-list-style')}>
                        {props.mainTypesList.data.map(item => (
                            <li key={item.id} className={styles('types__item')}>
                                <Input
                                    attrs={{ type: 'checkbox', name: 'topSearchMainItemType' }}
                                    value={item.id}
                                    checked={isObject(activeMainType) && activeMainType.id === item.id}
                                    modifiers={['button', 'stretch']}
                                    events={{ change: () => props.toggleMainItemType(item.id, item.name) }}
                                    label={item.translations[props.language]}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            ) : null}
            {props.currentLocationAvailable ? (
                <>
                    <h6 className={styles('search-section__title')}>{translate('toolbar.searchbar.currentLocation.title', props.language)}</h6>
                    <ul className={styles('search-section', 'no-list-style')}>
                        <li className={styles('search-section__item')}>
                            <Button
                                modifiers={['text', 'stretch']}
                                classes={styles('search-section__item__button')}
                                events={{ click: props.activateCurrentLocation }}
                                disabled={props.currentLocation.loading}
                            >
                                <Icon icon={'location'} modifiers={['inline']} />
                                <span className={styles('search-section__item__content')}>
                                    <span>{translate('toolbar.searchbar.currentLocation.itemName', props.language)}</span>
                                    {props.currentLocation.loading ? <Dots classes={styles('search-section__item__dots')} /> : null}
                                </span>
                            </Button>
                        </li>
                    </ul>
                </>
            ) : null}
            {isFullArray(historyItems) ? (
                <>
                    <h6 className={styles('search-section__title')}>{translate('toolbar.searchbar.history.title', props.language)}</h6>
                    <ul className={styles('search-section', 'no-list-style')}>{historyItems}</ul>
                </>
            ) : null}
        </>
    );
};

searchToggles.propTypes = {
    // properties
    historyItems: PropTypes.arrayOf(PropTypes.object).isRequired,

    filters: PropTypes.object,
    currentLocationAvailable: PropTypes.bool.isRequired,
    currentLocation: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    mainTypesList: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,

    // functions
    toggleMainItemType: PropTypes.func.isRequired,
    activateHistoryItem: PropTypes.func.isRequired,
    activateCurrentLocation: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        filters: state.filters,
        currentLocationAvailable: state.global.currentLocationAvailable,
        currentLocation: state.details.currentLocation,

        mainTypesList: state.list.mainTypes,

        language: state.global.localization.language
    };
};

export default connect(mapStateToProps, null)(searchToggles);
