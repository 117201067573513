import React from 'react';
import PropTypes from 'prop-types';

import { addModifierClasses, addToArray, getStyles } from '../../../../shared/utility';

import localStyles from './Bar.module.scss';
const styles = getStyles(localStyles);

const bar = (props) => {

    let barClasses = ['loading-bar'];
    barClasses = addModifierClasses(props.modifiers, 'loading-bar', barClasses);
    barClasses = addToArray(props.classes, barClasses);

    return <span className={styles(barClasses)} />;
};

bar.propTypes = {
    // properties
    classes: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string)
};

export default bar;
