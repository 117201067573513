import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { translate, localizePrice, getStyles, isNull } from '../../shared/utility';
import { localizeDateTime } from '../../shared/datetime';

import Logo from '../../components/Logo/Logo';
import Button from '../../components/UI/Button/Button';
import Bar from '../../components/UI/Loading/Bar/Bar';

import localStyles from './Invoice.module.scss';
const styles = getStyles(localStyles);

class Invoice extends PureComponent {
    /* * * * * * * * * * * * * *
     * REACT LIFECYCLE METHODS *
     * * * * * * * * * * * * * */
    constructor(props) {
        super(props);
        const id = parseInt(props.match.params.invoiceId, 10);
        this.state = {
            id: !isNaN(id) ? id : -1
        };
    }

    componentDidMount() {
        if (this.state.id > -1) {
            this.props.onFetchInvoice(this.state.id);
        }
    }

    /* * * * * * * * * *
     * CUSTOM METHODS  *
     * * * * * * * * * */
    getInvoicePageHeader = (hasData, data) => {
        const hasCustomerAddress = !!(
            hasData &&
            data.customer.address &&
            data.customer.address.street &&
            data.customer.address.number &&
            data.customer.address.postalCode &&
            data.customer.address.city &&
            data.customer.address.country &&
            data.customer.address.country.name
        );

        const customer = {
            name: hasData ?
                data.customer.name.trim() : (
                    <Bar modifiers={['darker']} />
                ),
            street: hasCustomerAddress ? (
                `${data.customer.address.street || ''} ${data.customer.address.number || ''}${data.customer.address.numberAddition || ''}`
            ) : (
                <Bar modifiers={['darker']} />
            ),
            city: hasCustomerAddress  ? (
                `${data.customer.address.postalCode || ''}, ${data.customer.address.city || ''}`
            ) : (
                <Bar modifiers={['darker']} />
            ),
            country: hasCustomerAddress  ? (
                data.customer.address.country.name
            ) : (
                <Bar modifiers={['darker']} />
            )
        };

        const merchant = {
            name: hasData ? (
                data.merchant.name
            ) : (
                <Bar modifiers={['darker']} />
            ),
            street: hasData ? (
                `${data.merchant.address.street} ${data.merchant.address.number}${data.merchant.address.numberAddition || ''}`
            ) : (
                <Bar modifiers={['darker']} />
            ),
            city: hasData ? (
                `${data.merchant.address.postalCode}, ${data.merchant.address.city}`
            ) : (
                <Bar modifiers={['darker']} />
            ),
            country: hasData ? (
                data.merchant.address.country.name
            ) : (
                <Bar modifiers={['darker']} />
            )
        };

        const invoiceNumber = hasData ? (
            data.number
        ) : (
            <Bar modifiers={['darker', 'auto']} />
        );
        const invoiceDate = hasData ? (
            localizeDateTime(data.createDate, this.props.locale)
        ) : (
            <Bar modifiers={['darker', 'auto']} />
        );

        return (
            <header className={styles('invoice__section', 'header')}>
                <div className={styles('header__section', 'header__section--top')}>
                    <div className={styles('header__address')}>
                        <h5 className={styles('header__title')}>{translate('invoice.invoiceAddress', this.props.language)}</h5>
                        <p className={styles('header__content')}>
                            {customer.name}<br />
                            {!hasData || hasCustomerAddress ? (
                                <>
                                    {customer.street}<br />
                                    {customer.city}<br />
                                    {customer.country}
                                </>
                            ) : null}
                        </p>
                    </div>
                    <div className={styles('header__skopei-address', 'align--right')}>
                        <Logo logo={'skopei-360-inline'} classes={styles('header__title', 'header__logo')} />
                        <p className={styles('header__content')}>
                            {merchant.name}<br />
                            {merchant.street}<br />
                            {merchant.city}<br />
                            {merchant.country}
                        </p>
                    </div>
                </div>
                <div className={styles('header__section', 'header__section--bottom')}>
                    <h3>{translate('invoice.invoice', this.props.language)}</h3>
                    <p className={styles('header__content', 'align--right')}>
                        {translate('invoice.invoiceNumber', this.props.language)}: {invoiceNumber}<br />
                        {translate('invoice.invoiceDate', this.props.language)}: {invoiceDate}
                    </p>
                </div>
            </header>
        );
    };

    getInvoicePageFooter = (hasData, data, pageNumber = 0, totalPages = 1) => {
        const taxNumber = hasData ? data.merchant.vatNumber : <Bar modifiers={['auto']} />;
        const chamberOfCommerceNumber = hasData ? data.merchant.chamberOfCommerceNumber : <Bar modifiers={['auto']} />;

        return (
            <footer className={styles('invoice__section', 'footer')}>
                <div className={styles('footer__content')}>
                    <div>
                        <Logo logo={'skopei-360-inline'} classes={styles('footer__logo')} />
                        <span className={styles('footer__text')}>
                            {translate('invoice.taxNumber', this.props.language)}: {taxNumber}
                        </span>
                        <span className={styles('footer__text')}>
                            {translate('invoice.chamberOfCommerceNumber', this.props.language)}: {chamberOfCommerceNumber}
                        </span>
                    </div>
                    {pageNumber && totalPages > 1 ? (
                        <span className={styles('footer__pagenumber')}>
                            {pageNumber} / {totalPages}
                        </span>
                    ) : null}
                </div>
            </footer>
        );
    };

    getInvoiceTableRow = item => (
        <tr className={styles('table__row')} key={item.key}>
            <td className={styles('table__cell', 'table__cell--item')}>
                <p className={styles('table__cell__content')}><strong>{item.title}</strong></p>
                <p className={styles('table__cell__content')}>{item.description}&nbsp;</p>
            </td>
            <td className={styles('table__cell', 'table__cell--price')}>
                <strong>{item.price !== false ? localizePrice(item.price, item.currency, this.props.locale) : <Bar />}</strong>
            </td>
        </tr>
    );

    getInvoiceTableFooter = (hasData, priceInfo) => {
        const subTotal = hasData ? localizePrice(priceInfo.price, priceInfo.currencyCode, this.props.locale) : <Bar />;

        const tarifs = hasData ? priceInfo.tarifs.map((tarif, index) => (
            <tr className={styles('table__row')} key={index}>
                <th className={styles('table__cell')}>
                    <strong>{translate('pricing.VAT', this.props.language)} {tarif.vatPercentage}%</strong>
                </th>
                <th className={styles('table__cell')}>
                    <strong>{localizePrice(tarif.priceVat, tarif.currencyCode, this.props.locale)}</strong>
                </th>
            </tr>
        )) : (
            <tr className={styles('table__row')}>
                <th className={styles('table__cell')}>
                    <strong>{translate('pricing.VAT', this.props.language)}</strong>
                </th>
                <th className={styles('table__cell')}>
                    <Bar />
                </th>
            </tr>
        );

        const total = hasData ? localizePrice(priceInfo.priceIncludingVat, priceInfo.currencyCode, this.props.locale) : <Bar />;

        return (
            <tfoot className={styles('table__section', 'table__footer')}>
                <tr className={styles('table__row')}>
                    <th className={styles('table__cell')}>
                        <strong>{translate('pricing.subTotal', this.props.language)}</strong>
                    </th>
                    <th className={styles('table__cell')}>
                        <strong>{subTotal}</strong>
                    </th>
                </tr>
                {tarifs}
                <tr className={styles('table__row', 'table__row--large')}>
                    <th className={styles('table__cell')}><h4>{translate('pricing.total', this.props.language)}</h4></th>
                    <th className={styles('table__cell')}><h4>{total}</h4></th>
                </tr>
            </tfoot>
        );
    };

    getInvoiceBodyBottom = (hasData, data) => {
        const userName = hasData ? `${translate('invoice.user', this.props.language)}: ${data.customer.name}` : <Bar />;
        const userEmail = hasData ? `${translate('formControls.emailShort', this.props.language)}: ${data.customer.emailAddress}` : <Bar />;


        const transactionDetails = hasData && data.paymentTransaction ? (
            translate('invoice.paidBy', this.props.language).replace(/\[date\]/, localizeDateTime(data.paymentTransaction.date, this.props.locale)).replace(/\[method\]/, data.paymentMethodReference.name)
        ) : !hasData ? (
            <Bar />
        ) : null;

        const transactionReference = hasData && data.paymentTransaction ? (
            `${translate('invoice.paymentReference', this.props.language)}: ${data.paymentTransaction.reference}`
        ) : !hasData ? (
            <Bar />
        ) : null;

        return (
            <div className={styles('body__content')}>
                <div className={styles('body__content--user')}>
                    <p>
                        {userName}<br />
                        {userEmail}
                    </p>
                </div>
                <div className={styles('body__content--payment', 'align--right')}>
                    <p>
                        {transactionDetails}<br />
                        {transactionReference}
                    </p>
                </div>
            </div>
        );
    };

    createInvoicePage = (items, pageNumber, totalPages, hasData) => (
        <article key={pageNumber} className={styles('invoice')}>
            {pageNumber === 1 ? this.getInvoicePageHeader(hasData, this.props.invoiceDetails.data) : null}
            <section className={styles('invoice__section', 'body')}>
                <table className={styles('table')}>
                    <thead className={styles('table__section', 'table__head')}>
                        <tr className={styles('table__row')}>
                            <th className={styles('table__cell')}><h5>{translate('invoice.description', this.props.language)}</h5></th>
                            <th className={styles('table__cell')}><h5>{translate('pricing.price', this.props.language)}</h5></th>
                        </tr>
                    </thead>
                    <tbody className={styles('table__section', 'table__body')}>
                        {items.map(item => this.getInvoiceTableRow(item))}
                    </tbody>
                    {pageNumber === totalPages ? this.getInvoiceTableFooter(hasData, hasData ? this.props.invoiceDetails.data.priceInformation : null) : null}
                </table>
                {pageNumber === totalPages ? this.getInvoiceBodyBottom(hasData, this.props.invoiceDetails.data) : null}
            </section>
            {this.getInvoicePageFooter(hasData, this.props.invoiceDetails.data, pageNumber, totalPages)}
        </article>
    );



    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const hasError = !this.props.invoiceDetails.loading && this.props.invoiceDetails.error;

        const topBar = (
            <div className={styles('bar', 'b--gray-6', 'align--center')}>
                <div className={styles('content', 'content--padding')}>
                    <Button
                        modifiers={['primary', 'l', 'wider']}
                        events={{ click: window.print }}
                        disabled={hasError || this.props.invoiceDetails.loading}
                    >{translate('ui.print', this.props.language)}</Button>
                </div>
            </div>
        );

        if (hasError) {
            return (
                <>
                    {topBar}
                    <div className={styles('invoices')}>
                        <article className={styles('invoice', 'invoice--error')}>
                            <header className={styles('invoice__section', 'header')}>
                                <div className={styles('header__section', 'header__section--top')}>
                                    <div className={styles('header__address')}>
                                    </div>
                                    <div className={styles('header__skopei-address', 'align--right')}>
                                        <Logo logo={'skopei-360-inline'} classes={styles('header__title', 'header__logo')} />
                                    </div>
                                </div>
                                <div className={styles('header__section', 'header__section--bottom')}>
                                    <h3>{translate('invoice.invoice', this.props.language)}</h3>
                                </div>
                            </header>

                            <section className={styles('invoice__section', 'body')}>
                                <h4 className="invoice__error">{this.props.invoiceDetails.error}</h4>
                            </section>

                            <footer className={styles('invoice__section', 'footer')}>
                                <div className={styles('footer__content')}>
                                    <div>
                                        <Logo logo={'skopei-360-inline'} classes={styles('footer__logo')} />
                                    </div>
                                </div>
                            </footer>
                        </article>
                    </div>
                </>
            );
        }

        const hasData = (!this.props.invoiceDetails.loading && !isNull(this.props.invoiceDetails.data));

        const items = !hasData ? [{
            key: 'invoic-loading',
            title: <Bar />,
            description: <Bar />,
            price: false
        }] : this.props.invoiceDetails.data.invoiceDetails.map(item => ({
            key: `${item.description}-${item.priceInformation.price}`,
            title: item.description,
            //description: ' ',
            price: item.priceInformation.price,
            currency: item.priceInformation.currencyCode
        }));

        const pages = [];
        if (items.length <= 8) {
            pages.push(items);
        } else {
            pages.push(items.splice(0, Math.min(items.length - 1, 12)));
            while (items.length > 12) {
                pages.push(items.splice(0, Math.min(items.length - 1, 17)));
            }
            pages.push(items);
        }

        return (
            <>
                {topBar}
                <div className={styles('invoices')}>
                    {pages.map((items, index) => this.createInvoicePage(items, (index + 1), pages.length, hasData))}
                </div>
            </>
        );
    }
}

Invoice.propTypes = {
    // properties
    match: PropTypes.object.isRequired,
    invoiceDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,

    // functions
    onFetchInvoice: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        invoiceDetails: state.details.invoice,

        locale: state.global.localization.locale,
        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchInvoice: (invoiceId) => dispatch(actions.fetchInvoiceDetails(invoiceId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoice);
