import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, translate, isDefined, isObject, isFullArray, isArray, isFunction } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';

import Icon from '../Icon/Icon';

import localStyles from './Notifications.module.scss';
const styles = getStyles(localStyles);

const doneLoadingClosure = (prevProps, thisProps) => (key) => (
    isDefined(prevProps[key]) && isDefined(thisProps[key]) ?
        (prevProps[key].loading && !thisProps[key].loading) : false
);
// better checking for success
const isSuccessfullClosure = (doneLoading, thisProps) => (key) => (
    doneLoading(key) && (!!thisProps[key].success || isObject(thisProps[key].data) || isArray(thisProps[key].data))
);
const hasFailedClosure = (doneLoading, thisProps) => (key) => doneLoading(key) && !!thisProps[key].error;

class Notifications extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { timeouts: {} };
    }

    componentDidMount() {
        // this.setTimeouts();
    }

    notify = (notifications = []) => {
        notifications.forEach(notification => {
            this.props.addNotification({
                message: notification.message.apply(this),
                type: notification.type
            });
            if(isFunction(notification.callback)){
                notification.callback.apply(this);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.notificationsData.length !== this.props.notificationsData.length) {
            this.setTimeouts();
        }

        const doneLoading = doneLoadingClosure(prevProps, this.props);
        const isSuccessfull = isSuccessfullClosure(doneLoading, this.props);
        const hasFailed = hasFailedClosure(doneLoading, this.props);

        const notifications = [];
        const addNotification = (key, data = {}) => {
            const { success, failure, successCallback, failureCallback } = data;
            if(isDefined(success) && isSuccessfull(key)){
                notifications.push({ message: success, type: 'success', callback: successCallback });
            }
            if(isDefined(failure) && hasFailed(key)){
                notifications.push({ message: failure, type: 'error', callback: failureCallback });
            }
        };

        // CONDITION PROPS
        addNotification('userLogout', {
            success: () => translate('notifications.condition.userLogout.success', this.props.language),
            successCallback: () => { setTimeout(() => { window.location.reload(); }, 1000); },
            failure: () => `${translate('notifications.condition.userLogout.error', this.props.language)} ${this.props.userLogout.error}`
        });

        addNotification('updateUserPreference', {
            failure: () => `${translate('notifications.condition.updateUserPreference.error', this.props.language)} ${this.props.updateUserPreference.error}`
        });

        addNotification('updateUserDetails', {
            success: () => translate('notifications.condition.updateUserDetails.success', this.props.language),
            failure: () => `${translate('notifications.condition.updateUserDetails.error', this.props.language)} ${this.props.updateUserDetails.error}`
        });

        addNotification('updateUserPassword', {
            success: () => translate('notifications.condition.updateUserPassword.success', this.props.language),
            successCallback: () => { this.setState({ isPasswordModalOpen: false }); }
        });

        addNotification('addCartItem', {
            success: () => translate('notifications.condition.addCartItem.success', this.props.language),
            failure: () => `${translate('notifications.condition.addCartItem.error', this.props.language)} ${this.props.addCartItem.error}`
        });

        addNotification('updateCartItem', {
            success: () => translate('notifications.condition.updateCartItem.success', this.props.language)
        });

        addNotification('removeCartItem', {
            success: () => translate('notifications.condition.removeCartItem.success', this.props.language),
            failure: () => `${translate('notifications.condition.removeCartItem.error', this.props.language)} ${this.props.removeCartItem.error}`
        });

        addNotification('removeCartVoucher', {
            success: () => translate('notifications.condition.removeCartVoucher.success', this.props.language),
            failure: () => `${translate('notifications.condition.removeCartVoucher.error', this.props.language)} ${this.props.removeCartVoucher.error}`
        });

        addNotification('clearCartItems', {
            success: () => translate('notifications.condition.clearCartItems.success', this.props.language),
            failure: () => `${translate('notifications.condition.clearCartItems.error', this.props.language)} ${this.props.clearCartItems.error}`
        });

        addNotification('checkoutCart', {
            failure: () => `${translate('notifications.condition.checkoutCart.error', this.props.language)} ${this.props.checkoutCart.error}`
        });

        addNotification('cancelReservation', {
            success: () => translate('notifications.condition.cancelReservation.success', this.props.language),
            failure: () => `${translate('notifications.condition.cancelReservation.error', this.props.language)} ${this.props.cancelReservation.error}`
        });

        addNotification('linkPaymentAccount', {
            success: () => `${translate('pages.linkPaymentAccount.successMessage', this.props.language)}`
        });

        addNotification('addDirectDebit', {
            failure: () => `${translate('notifications.condition.addDirectDebit.error', this.props.language)} ${this.props.addDirectDebit.error}`
        });

        addNotification('updateDirectDebit', {
            success: () => translate('notifications.condition.updateDirectDebit.success', this.props.language),
            failure: () => `${translate('notifications.condition.updateDirectDebit.error', this.props.language)} ${this.props.updateDirectDebit.error}`
        });

        addNotification('deleteDirectDebit', {
            success: () => translate('notifications.condition.deleteDirectDebit.success', this.props.language),
            failure: () => `${translate('notifications.condition.deleteDirectDebit.error', this.props.language)} ${this.props.deleteDirectDebit.error}`
        });

        addNotification('addCartService', {
            failure: () => `${this.props.addCartService.error}`
        });

        // DETAILS PROPS

        addNotification('cartDetails', {
            failure: () => `${translate('notifications.details.cart.error', this.props.language)} ${this.props.cartDetails.error}`
        });

        addNotification('cartPricingDetails', {
            failure: () => `${translate('notifications.details.cartPricing.error', this.props.language)} ${this.props.cartPricingDetails.error}`
        });

        addNotification('addCartVoucher', {
            success: () => `${this.props.addCartVoucher.data.description} ${translate('notifications.details.addCartVoucher.success', this.props.language)}`
        });

        addNotification('priceDetails', {
            failure: () => `${translate('notifications.details.priceDetails.error', this.props.language)} ${this.props.priceDetails.error}`
        });

        addNotification('searchLocation', {
            failure: () => translate('notifications.details.searchLocation.error', this.props.language)
            //failure: () => `${translate('notifications.details.searchLocation.error', this.props.language)} ${this.props.searchLocation.error}`
        });

        addNotification('periodsPrice', {
            failure: () => `${this.props.periodsPrice.error}`
        });

        // LIST PROPS

        addNotification('searchAutocomplete', {
            failure: () => `${translate('notifications.list.searchAutocomplete.error', this.props.language)} ${this.props.searchAutocomplete.error}`
        });

        addNotification('addressesList', {
            failure: () => `${translate('notifications.list.addresses.error', this.props.language)} ${this.props.addressesList.error}`
        });

        addNotification('countriesList', {
            failure: () => `${translate('notifications.list.countries.error', this.props.language)} ${this.props.countriesList.error}`
        });

        addNotification('paymentAccounts', {
            failure: () => `${translate('notifications.list.paymentAccounts.error', this.props.language)} ${this.props.paymentAccounts.error}`
        });

        addNotification('paymentMethods', {
            failure: () => `${translate('notifications.list.paymentMethods.error', this.props.language)} ${this.props.paymentMethods.error}`
        });

        addNotification('cartPaymentOptions', {
            failure: () => `${translate('notifications.list.cartPaymentOptions.error', this.props.language)} ${this.props.cartDetails.error}`
        });

        addNotification('directDebits', {
            failure: () => `${translate('notifications.list.directDebits.error', this.props.language)} ${this.props.directDebits.error}`
        });

        this.notify(notifications);
    }

    /* * * * * * * * * *
     * CUSTOM METHODS  *
     * * * * * * * * * */
    setTimeouts = () => {
        const newTimeouts = {};
        for (let id in this.state.timeouts) {
            if (this.props.notificationsData.find((notification) => notification.id === id)) {
                newTimeouts[id] = this.state.timeouts[id];
            } else {
                window.clearTimeout(this.state.timeouts[id]);
            }
        }

        this.props.notificationsData.forEach((notification) => {
            if (!newTimeouts[notification.id] && notification.timeout > 0) {
                newTimeouts[notification.id] = window.setTimeout(this.handleTimeout, notification.timeout * 1000, notification.id);
            }
        });

        this.setState({ timeouts: newTimeouts });
    }

    closeNotification = (notification) => {
        while (notification && !notification.classList.contains(styles('notification'))) {
            notification = notification.parentNode;
        }
        if (notification) {
            notification.addEventListener('transitionend', () => this.props.onRemoveNotification(notification.getAttribute('data-notification-id')));

            notification.classList.add(styles('notification--closed'));
        }
    }

    /* * * * * * * * * *
     * EVENT HANDLERS  *
     * * * * * * * * * */
    handleClick = (event) => this.closeNotification(event.target);
    handleTimeout = (id) => this.closeNotification(document.querySelector(`[data-notification-id="${id}"]`));

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const notifications = this.props.notificationsData.map((item, index) => (
            <div key={item.id}
                data-notification-id={item.id}
                className={styles('notification', 'flex', 'flex--align-center', `notification--${(item.type || 'general')}`)}
                onClick={this.handleClick}
            >
                <Icon icon={item.type === 'success' ? 'notification-tick' : item.type === 'error' ? 'notification-error' : false} modifiers={['large']} classes={styles('notification__icon')} />
                <p className={styles('notification__content')}>
                    {item.message.split(/[\n\r]/).map((line, index) => <Fragment key={index}>{line}<br /></Fragment>)}
                </p>
            </div>
        )).reverse();

        return isFullArray(notifications) ? (
            <div className={styles('notifications', 'flex', 'flex--column', 'c--text-white', 'line-height--small')}>
                {notifications}
            </div>
        ) : null;
    }
}

Notifications.propTypes = {
    // properties
    notificationsData: PropTypes.arrayOf(PropTypes.object).isRequired,

    // CONDITION PROPS
    userLogout: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    updateUserPreference: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    updateUserDetails: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    updateUserPassword: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    cancelReservation: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    linkPaymentAccount: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    addDirectDebit: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    updateDirectDebit: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    deleteDirectDebit: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    addCartService: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    // CART PROPS
    cartDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    cartPricingDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    // cart items change
    addCartVoucher: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    removeCartVoucher: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    addCartItem: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    updateCartItem: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    removeCartItem: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    clearCartItems: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    checkoutCart: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    // DETAILS PROPS
    userDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    itemDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    priceDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    searchLocation: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    reservationDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    orderDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    periodsPrice: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    // LIST PROPS
    searchAutocomplete: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    addressesList: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    countriesList: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    paymentAccounts: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    paymentMethods: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    cartPaymentOptions: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    directDebits: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,

    // functions
    onRemoveNotification: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        notificationsData: state.global.notifications,
        // CONDITION PROPS
        userLogout: state.condition.logout,
        updateUserPreference: state.condition.updateUserPreference,
        updateUserDetails: state.condition.updateUserDetails,
        updateUserPassword: state.condition.updateUserPassword,
        removeCartVoucher: state.condition.removeVoucher,
        addCartItem: state.condition.addCartItem,
        updateCartItem: state.condition.updateCartItem,
        removeCartItem: state.condition.removeCartItem,
        clearCartItems: state.condition.clearCart,
        checkoutCart: state.condition.checkoutCart,
        cancelReservation: state.condition.cancelReservation,
        linkPaymentAccount: state.condition.linkPaymentAccount,
        addDirectDebit: state.condition.addDirectDebit,
        updateDirectDebit: state.condition.updateDirectDebit,
        deleteDirectDebit: state.condition.deleteDirectDebit,
        addCartService: state.condition.addCartService,

        // DETAILS PROPS
        userDetails: state.details.user,
        itemDetails: state.details.item,
        cartDetails: state.details.cart,
        cartPricingDetails: state.details.cartPricing,
        addCartVoucher: state.details.addCartVoucher,
        priceDetails: state.details.itemPrice,
        searchLocation: state.details.searchLocation,
        reservationDetails: state.details.reservation,
        orderDetails: state.details.order,
        periodsPrice: state.details.itemCheckoutPeriodsPrice,

        // LIST PROPS
        searchAutocomplete: state.list.searchAutocomplete,
        addressesList: state.list.addresses,
        countriesList: state.list.countries,
        paymentAccounts: state.list.paymentAccounts,
        paymentMethods: state.list.paymentMethods,
        cartPaymentOptions: state.list.cartPaymentOptions,
        directDebits: state.list.directDebits,

        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRemoveNotification: (id) => dispatch(actions.removeNotification(id)),
        addNotification: (data) => dispatch(actions.addNotification(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
