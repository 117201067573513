import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, localizePrice, isNull } from '../../../shared/utility';

import Button from '../../../components/UI/Button/Button';
import Icon from '../../../components/UI/Icon/Icon';
import Dots from '../../../components/UI/Loading/Dots/Dots';

import localStyles from './CartButton.module.scss';
const styles = getStyles(localStyles);

const cartButton = (props) => {

    const loading = (props.cartDetails.loading || props.cartPricingDetails.loading);
    const hasItems = props.cartDetails.data && !loading && !props.cartDetails.error;
    const hasPrice = props.cartPricingDetails.data && !loading && !props.cartPricingDetails.error;

    const items = hasItems ? props.cartDetails.data.cartItems.length : !props.cartDetails.error ? <Dots bullets max={1} timeout={600} /> : null;
    const price = hasPrice ? localizePrice(
        props.cartPricingDetails.data.totalPayment.priceIncludingVat - props.cartPricingDetails.data.totalPayment.discountIncludingVat,
        props.cartPricingDetails.data.totalPayment.currencyCode,
        props.locale
    ) : 0;

    const itemCount = !isNull(items) ? (
        <span className={styles('cartbutton__count')}>
            <Icon icon={'cart-open'} modifiers={['large']}/>
            <span className={styles('cartbutton__count__number', 'small', 'align--center')}>
                {items}
            </span>
        </span>
    ) : null;


    return (
        <>
            {/* mobile button */}
            <Button classes={styles('mobile-only', 'w--500')} modifiers={['square']} href={'/cart/'}>
                {itemCount}
            </Button>
            {/* portrait button */}
            <Button classes={styles('cartbutton', 'w--500', 'portrait')} modifiers={['primary', 'm']} href={'/cart/'}>
                {itemCount}
                {price ? <span className={styles('cartbutton__price')}>{price}</span> : null}
            </Button>
        </>
    );
};

cartButton.propTypes = {
    // properties
    cartDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    cartPricingDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    locale: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
        cartDetails: state.details.cart,
        cartPricingDetails: state.details.cartPricing,

        locale: state.global.localization.locale
    };
};

export default connect(mapStateToProps, null)(cartButton);
