import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../../../shared/utility';

import Icon from '../../../Icon/Icon';

import localStyles from './DropdownOption.module.scss';
const styles = getStyles(localStyles);

const DropdownOption = React.memo((props) => {
    const {
        html, text, icon, customIcon,
        collapsed, multiple, hasIcons,
        disabled, focussed, selected,
        focus, select
    } = props;

    const checkBox = useMemo(() => (
        <span className={styles(
            ['display__checkbox']
                .concat(collapsed ? 'dislpay__checkbox--collapsed' : [])
                .concat(selected ? 'display__checkbox--checked' : [])
        )}/>
    ), [collapsed, selected]);

    return (
        <li
            className={styles(
                ['item']
                    .concat(disabled ? 'item--disabled' : [])
                    .concat(focussed ? 'item--focus' : [])
            )}
            onMouseEnter={focus}
            onClick={select}
        >
            <div
                className={styles(
                    ['item__content', 'flex', 'display', 'flex--align-center']
                        .concat(collapsed ? 'item__content--collapsed' : [])
                        .concat(disabled ? 'item__content--disabled' : [])
                        .concat(focussed ? 'item__content--focus' : [])
                        .concat(selected ? 'item__content--selected' : [])
                )}
            >
                {hasIcons && (icon || customIcon) ? (
                    <Icon icon={icon} customIcon={customIcon} classes={styles('display__icon')} />
                ) : hasIcons ? (
                    <div className={styles('display__icon')} />
                ) : null}
                <div className={styles('display__value')}>
                    {multiple && collapsed ? checkBox : null}
                    {(!collapsed && html) || text}
                </div>
                {multiple && !collapsed ? checkBox : null}
            </div>
        </li>
    );
});

DropdownOption.propTypes = {
    html: PropTypes.node,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    customIcon: PropTypes.string,
    collapsed: PropTypes.bool,
    multiple: PropTypes.bool,
    hasIcons: PropTypes.bool,
    disabled: PropTypes.bool,
    focussed: PropTypes.bool,
    selected: PropTypes.bool,
    focus: PropTypes.func,
    select: PropTypes.func
};

export default DropdownOption;
