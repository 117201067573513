import 'react-app-polyfill/ie11';
import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore, getDefaultMiddleware  } from 'redux-starter-kit';
import logger from 'redux-logger';
import reducer from './store/reducers/index';
import cssVars from 'css-vars-ponyfill';

import App from './containers/App/App';

import './index.scss';

cssVars({});

const enableReduxLogger = false;
const middleware = [...getDefaultMiddleware(), ...(enableReduxLogger ? [logger] : [])];

export const store = configureStore({
    reducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
});

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
