export const conditionStates = [
    'signup',
    'signupVerified',
    'startEmailVerification',
    'confirmEmailVerification',
    'logout',
    'startPasswordReset',
    'confirmPasswordReset',
    'updateEmailAddress',
    'updateUserPassword',
    'updateUserPreference',
    'updateUserDetails',
    'addCartItem',
    'addCartService',
    'updateCartItem',
    'updateCartProduct',
    'removeCartItem',
    'removeCartProduct',
    'removeVoucher',
    'clearCart',
    'checkoutCart',
    'checkoutItem',
    'cancelReservation',
    'extendReservation',
    'extendBooking',
    'cancelCarReservation',
    'cancelProductOrder',
    'linkPaymentAccount',
    'addDirectDebit',
    'updateDirectDebit',
    'deleteDirectDebit',
    'deleteVisitor',
    'deleteMeeting',
    'updateMeetingDetails',
    'updateVisitorDetails',
    'patchUpdateVisitorDetails'
];

export const detailsStates = [
    'user',
    'items',
    'item',
    'itemPrice',
    'itemContact',
    'itemCheckoutPrice',
    'itemCheckoutPeriodsPrice',
    'itemCheckoutTermsAndConditions',
    'caterer',
    'catererPrice',
    'catererContact',
    'cart',
    'cartPricing',
    'addCartVoucher',
    'currentLocation',
    'company',
    'companyContact',
    'searchLocation',
    'booking',
    'reservation',
    'carReservation',
    'productOrder',
    'reservationPricing',
    'bookingExtensionPrice',
    'order',
    'paymentAccount',
    'community',
    'communityContact',
    'communityCompany',
    'invoice',
    'itemAvailabilityBreakdown',
    'monthlyItemAvailabilityBreakdown',
    'ticket',
    'submittedTicket',
    'rentalItem',
    'addedMeeting',
    'meeting',
    'visitor',
    'addedVisitor',
    'driversLicenseVerifications'
];

export const listStates = [
    'mainTypes',
    'itemTypes',
    'mainTypeProperties',
    'mainTypeCharacteristics',
    'mapLocations',
    'itemAvailability',
    'repeatUnits',
    'itemsPriceDetails',
    'itemsAvailabilityBreakdown',
    'itemCheckoutPaymentOptions',
    'itemCheckoutPeriodsPaymentOptions',
    'catererProducts',
    'popularLocations',
    'searchAutocomplete',
    'addresses',
    'countries',
    'reservationPaymentOptions',
    'paymentAccounts',
    'paymentMethods',
    'cartPaymentOptions',
    'bookingExtensionPaymentOptions',
    'directDebits',
    'principals',
    'tickets',
    'rentalItemsByCode',
    'itemTypeDefects',
    'meetingVisitors'
];

export const pagedStates = [
    'items',
    'activeMapLocationItems',
    'nearbyItems',
    'itemsByCompany',
    'caterers',
    'activeMapLocationCaterers',
    'popularLocations',
    'bookings',
    'productOrders',
    'orders',
    'orderBookings',
    'meetings'
];
