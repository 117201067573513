import { SET_GLOBAL_STATE, UPDATE_REDIRECT_OBJECT, REMOVE_NOTIFICATION, ADD_NOTIFICATION, SET_LOCALIZATION, SET_WINDOW_DIMENSIONS, SET_HEADER_HEIGHT, SET_STICKY_NAVIGATION_HEIGHT, SET_SCROLLBAR_WIDTH } from './actionTypes';
import { getElementHeight, isFullString, getLanguageCode, isNull } from '../../shared/utility';
import { availableLocales, getLocale } from '../../shared/translations';

/* * * * * * * * * * * *
 * TOGGLE VIEW STATES  *
 * * * * * * * * * * * */
export const setGlobalState = (identifier = null, value) => {
    return {
        type: SET_GLOBAL_STATE,
        identifier: identifier,
        value: value
    };
};

export const updateRedirect = (pathname = null, search = null, state = null) => {
    return {
        type: UPDATE_REDIRECT_OBJECT,
        pathname: pathname,
        search: search,
        state: state
    };
};

/* * * * * * * * * * * * *
 * NOTIFICATION METHODS  *
 * * * * * * * * * * * * */
export const removeNotification = (id) => {
    // if id is null or undefined, all notifications will be removed, otherwise only notification with that id should be removed
    return {
        type: REMOVE_NOTIFICATION,
        id: id
    };
};

export const addNotification = (data) => {
    return {
        type: ADD_NOTIFICATION,
        data: data
    };
};

/* * * * * * * * * * * * *
 * LOCALIZATION METHODS  *
 * * * * * * * * * * * * */
export const setLocalization = (localeString) => {
    if(isFullString(localeString) && localeString !== getLanguageCode()) {
        localStorage.removeItem('languageCode');
        localStorage.setItem('languageCode', localeString);
    } else if(isNull(getLanguageCode())){
        localStorage.setItem('languageCode', getLocale(localeString).id);
    }
    const locale = getLocale(getLanguageCode());
    return {
        type: SET_LOCALIZATION,
        available: availableLocales,
        locale: locale.id,
        language: locale.language,
        region: locale.region
    };
};

/* * * * * * * *
 * UI METHODS  *
 * * * * * * * */
export const setWindowDimensions = () => ({
    type: SET_WINDOW_DIMENSIONS,
    windowDimensions: {
        width: window.innerWidth,
        height: window.innerHeight
    }
});

export const setHeaderHeight = () => ({
    type: SET_HEADER_HEIGHT,
    headerHeight: getElementHeight(document.getElementById('header'))
});

export const setStickyNavigationHeight = () => ({
    type: SET_STICKY_NAVIGATION_HEIGHT,
    stickyNavigationHeight: getElementHeight(document.getElementById('sticky-nav'))
});

export const setScrollBarWidth = () => {
    const div = document.createElement('div');
    div.setAttribute('style', 'opacity: 0;visibility: hidden;overflow: scroll;');
    div.innerHTML = 'skopei';
    document.body.appendChild(div);
    const scrollBarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return {
        type: SET_SCROLLBAR_WIDTH,
        scrollBarWidth: scrollBarWidth
    };
};
