import { translate, isInteger, isString, isNull, getUserPreferences, getLanguageCode, getCommunityId } from '../../shared/utility';
import { getLocale } from '../../shared/translations';

const API_BASE = '/api/v1/';

const handleResponses = async (response = null) => {
    let errorMessage = '';
    if (response && response.type) {
        if (response.ok) {
            switch (response.status) {
                case 200:
                case 201:
                    return await response.json();
                case 202:
                case 204:
                    return true;
                default:
                    console.log('THIS SHOULD NOT HAPPEN!: ', response);
                    return true;
            }
        } else {
            let errorData = null;
            const language = getLocale(getLanguageCode()).language;
            switch (response.status) {
                case 400:
                    errorData = await response.json();
                    throw errorData.key === 'email_unverified' || errorData.key === 'password_not_set' ? errorData.key : (errorData.additionalData && errorData.additionalData.detailedErrorMessages ? errorData.additionalData.detailedErrorMessages[0].value : errorData.message);

                case 401:
                    if(!isNull(getUserPreferences())) {
                        localStorage.removeItem('paymentAccountId');
                        localStorage.removeItem('userData');
                        window.location.reload();
                    }
                    errorMessage = translate('errors.general.unauthorized', language);
                    throw errorMessage;
                case 403:
                    errorMessage = translate('errors.general.forbidden', language);
                    throw errorMessage;
                case 404:
                    errorMessage = translate('errors.general.notFound', language);
                    throw errorMessage;
                case 429:
                    errorMessage = translate('errors.general.tooManyAttempts', language);
                    throw errorMessage;
                case 500:
                    errorMessage = translate('errors.general.serverError', language);
                    throw errorMessage;
                default:
                    errorMessage = translate('errors.general.default', language);
                    throw errorMessage;
            }
        }
    } else {
        const language = getLocale(getLanguageCode()).language;
        errorMessage = response && response.message ? response.message : response ? response : translate('errors.general.missingResponse', language);
        throw errorMessage;
    }
};

const fetchData = async (methodType, path, bodyData) => {
    const language = isString(getLanguageCode()) ? getLanguageCode() : '';

    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json-patch+json');
    requestHeaders.append('Accept-Language', language);

    const token = document.querySelector('meta[name="csrf-token"]');
    if (token) {
        requestHeaders.append('c-xsrf-token', token.getAttribute('content'));
    }

    const data = {
        method: methodType,
        headers: requestHeaders,
        body: bodyData ? JSON.stringify(bodyData) : null,
        credentials: 'include'
    };

    // create get parameter string for community and payment account
    const defaultParameters = [];
    let defaultParametersString = '';
    if (path.indexOf('logout') === -1) {
        const communityId = getCommunityId();
        if (!isNull(communityId)) {
            defaultParameters.push(`communityId=${communityId}`);
        }

        const paymentAccountId = parseInt(localStorage.getItem('paymentAccountId'), 10);
        if (isInteger(paymentAccountId)) {
            defaultParameters.push(`paymentAccountId=${paymentAccountId}`);
        }

        if (defaultParameters.length) {
            defaultParametersString = (path.indexOf('?') > -1 ? '&' : '?') + defaultParameters.join('&');
        }
    }

    const endpoint = `${API_BASE}${path}${defaultParametersString}`;

    try {
        const request = new Request(endpoint, data);
        const response = await fetch(request);
        return handleResponses(response);
    } catch (error) {
        console.log(error);
        return handleResponses(error);
    }
};

export default {
    get: async (path = '') => (await fetchData('GET', path)),
    post: async (path = '', body = undefined) => (await fetchData('POST', path, body)),
    put: async (path = '', body = undefined) => (await fetchData('PUT', path, body)),
    patch: async (path = '', body = undefined) => (await fetchData('PATCH', path, body)),
    delete: async (path = '') => (await fetchData('DELETE', path))
};

// This class keeps count of the amount calls done
// for a particular action. This is done so we can make
// sure only the result of the latest call done is returned
// to the app. This is so we can prevent unnecessary
// component updates
export class Counter {
    constructor(states = []) {
        // ['stateNameA', 'stateNameB'] =>
        // { stateNameA: 0, stateNameB: 0 }
        this.counters = states.reduce((acc, identifier) => ({
            ...acc,
            [identifier]: 0
        }), {});
    }

    increment(identifier) {
        this.counters[identifier]++;
        return this.counters[identifier];
    }

    reset(identifier) {
        this.counters[identifier] = 0;
    }

    isCurrent(identifier, value) {
        return this.counters[identifier] === value;
    }
}
