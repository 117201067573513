import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, addToArray, isFullArray } from '../../../shared/utility';

import chroma from 'chroma-js';

import Button from '../Button/Button';

import localStyles from './HorizontalScroll.module.scss';
const styles = getStyles(localStyles);

class HorizontalScroll extends PureComponent {

    /* * * * * * * * * *
     * REACHT METHODS  *
     * * * * * * * * * */
    constructor(props) {
        super(props);
        this.state = {
            distance: 0,
            canScroll: false,
            canScrollPrev: false,
            canScrollNext: false,
            activeChildIndex: 0,
            scrollPosition: 0,
            dragStartPosition: 0,
            children: null,
            mouseDown: false,
            mouseMove: false
        };

        this.refItems = React.createRef();
        this.refContainer = React.createRef();
    }

    componentDidMount() {
        this.initialize();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.windowWidth !== this.props.windowWidth || prevProps.windowHeight !== this.props.windowHeight || prevProps.children !== this.props.children) {
            this.initialize();
        }
    }

    /* * * * * * * * * *
     * CUSTOM METHODS  *
     * * * * * * * * * */
    initialize = () => {

        // first fix SubPixel on items
        const parent = this.refContainer.current.parentNode;
        parent.removeAttribute('style');
        parent.style.width = `${Math.floor(parent.getBoundingClientRect().width)}px`;

        this.refItems.current.removeAttribute('style');
        this.refItems.current.style.height = `${Math.floor(this.refItems.current.getBoundingClientRect().height + 1)}px`;

        const containerWidth = this.refContainer.current.clientWidth;
        const itemsWidth = this.refItems.current.scrollWidth;

        const newState = {};
        newState.children = [...(this.props.selectorQuery ?
            this.refItems.current.querySelectorAll(this.props.selectorQuery) :
            this.refItems.current.children)
        ];
        newState.distance = Math.max(0, itemsWidth - containerWidth);
        newState.canScroll = !!newState.distance;
        newState.canScrollPrev = this.refItems.current.scrollLeft > 0;
        newState.canScrollNext = this.refItems.current.scrollLeft < newState.distance;
        newState.activeChildIndex = this.getActiveChildIndex(0, false, newState.children);
        this.toggleDraggableElements(!newState.canScroll);

        const newStateObject = Object.keys(newState).filter(key => newState[key] !== this.state[key]).reduce((stateObj, key) => {
            stateObj[key] = newState[key];
            return stateObj;
        }, {});

        if(isFullArray(Object.keys(newStateObject))){
            this.setState(newStateObject);
        }
    }

    getActiveChildIndex = (delta, scrollLeft, children) => {
        scrollLeft = !isNaN(scrollLeft) ? scrollLeft : this.refItems.current.scrollLeft;
        return (children || this.state.children)
            .reduce((active, element, index) => {
                const diff = Math.abs(scrollLeft - element.offsetLeft);
                if (diff < active.diff || active.diff === -1) {
                    return { index: index, diff: diff };
                }
                return active;
            }, { index: 0, diff: -1 }).index + (delta || 0);
    }

    scrollTo = (index) => {
        index = Math.max(0, Math.min(this.state.children.length - 1, index));
        this.setScrollLeft(this.state.children[index].offsetLeft);
    }

    setScrollLeft = (scrollLeft) => {
        if (scrollLeft !== this.refItems.current.scrollLeft) {
            this.refItems.current.scrollLeft = scrollLeft;
        }
    }

    handleScroll = () => {
        const scrollLeft = this.refItems.current.scrollLeft;
        
        this.setState({
            index: this.getActiveChildIndex(0, scrollLeft),
            canScrollPrev: scrollLeft > 0,
            canScrollNext: Math.floor(Math.abs(scrollLeft - this.state.distance))
        });
    }

    /* * * * * * * * *
     * DRAG METHODS  *
     * * * * * * * * */
    toggleDraggableElements = (draggable) => [...this.refItems.current.querySelectorAll('img, a[href]')]
        .forEach((item) =>
            draggable ?
                item.removeAttribute('draggable') :
                item.setAttribute('draggable', 'false')
        );

    handleMoveDown = (event) => {
        if (event.button !== 0 || !this.state.canScroll) {
            return;
        }
        this.setState({mouseDown: true, scrollPosition: this.refItems.current.scrollLeft, dragStartPosition: event.clientX});
    }

    handleMoveMove = (event) => {
        if(Math.floor(Math.abs(this.state.dragStartPosition - event.clientX))) {
            event.stopPropagation();
            this.refItems.current.scrollLeft = this.state.scrollPosition + this.state.dragStartPosition - event.clientX;
            this.setScrollLeft(this.state.scrollPosition + this.state.dragStartPosition - event.clientX);
            
            this.setState({mouseMove: true});
        }
    }

    handleMoveUp = (event) => {
        this.setState({mouseDown: false, mouseMove: false});
    }

    /* * * * * * * * * *
     * EVENT HANDLERS  *
     * * * * * * * * * */
    buttonOnMouseDown = (event) => event.stopPropagation();
    prevButtonOnClick = () => this.scrollTo(this.getActiveChildIndex(-1));
    nextButtonOnClick = () => this.scrollTo(this.getActiveChildIndex(1));

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {

        /* * * * * * *
         * OVERLAYS  *
         * * * * * * */
        let prevOverlay = null,
            nextOverlay = null;

        if (!this.props.noOverlay) {
            const prevOverlayStyle = {};
            const prevOverlayClasses = ['horizontal-scroll__overlay', 'horizontal-scroll__overlay--prev'];
            if (!this.state.canScrollPrev) {
                prevOverlayClasses.push('horizontal-scroll__overlay--hidden', 'horizontal-scroll__overlay--prev--hidden');
            }

            const nextOverlayStyle = {};
            const nextOverlayClasses = ['horizontal-scroll__overlay', 'horizontal-scroll__overlay--next'];
            if (!this.state.canScrollNext) {
                nextOverlayClasses.push('horizontal-scroll__overlay--hidden', 'horizontal-scroll__overlay--next--hidden');
            }

            if (this.props.backgroundColor) {
                const color = chroma(this.props.backgroundColor);
                prevOverlayStyle.backgroundImage = `linear-gradient(to right, ${this.props.backgroundColor} 50%, ${color.alpha(0).css()})`;
                nextOverlayStyle.backgroundImage = `linear-gradient(to left, ${this.props.backgroundColor} 50%, ${color.alpha(0).css()})`;
            }

            prevOverlay = <div className={styles(prevOverlayClasses)} style={prevOverlayStyle}/>;
            nextOverlay = <div className={styles(nextOverlayClasses)} style={nextOverlayStyle}/>;
        }

        /* * * * * *
         * BUTTONS *
         * * * * * */
        let prevButton = null,
            nextButton = null;

        if (!this.props.hideButtons) {
            const prevButtonClasses = [
                'horizontal-scroll__button',
                'horizontal-scroll__button--prev',
                this.state.canScrollPrev ? null : 'horizontal-scroll__button--hidden'
            ];

            prevButton = (
                <Button
                    modifiers={['circle', (this.props.buttonSize || 'm'), 'white']}
                    classes={styles(prevButtonClasses)}
                    events={{
                        mouseDown: this.buttonOnMouseDown,
                        click: this.prevButtonOnClick
                    }}
                    icon={'arrow-left'}
                    iconColor={'green'}
                />
            );

            const nextButtonClasses = [
                'horizontal-scroll__button',
                'horizontal-scroll__button--next',
                this.state.canScrollNext ? null : 'horizontal-scroll__button--hidden'
            ];

            nextButton = (
                <Button
                    modifiers={['circle', (this.props.buttonSize || 'm'), 'white']}
                    classes={styles(nextButtonClasses)}
                    events={{
                        mouseDown: this.buttonOnMouseDown,
                        click: this.nextButtonOnClick
                    }}
                    icon={'arrow-right'}
                    iconColor={'green'}
                />
            );
        }

        /* * * * * * *
         * CONTAINER *
         * * * * * * */
        let containerClasses = [
            'horizontal-scroll__container', 
            this.state.canScroll ? 'horizontal-scroll__container--can-scroll' : null,
            this.state.mouseMove ? 'horizontal-scroll__container--dragging' : null
        ];
        containerClasses = addToArray(this.props.classes, containerClasses);

        return (
            <div className={styles('horizontal-scroll')}>
                {prevButton}
                <div
                    className={styles(containerClasses)}
                    ref={this.refContainer}
                    onMouseDown={this.handleMoveDown}
                    onMouseMove={this.state.mouseDown ? this.handleMoveMove : undefined}
                    onMouseUp={this.handleMoveUp}
                    onMouseLeave={this.handleMoveUp}
                    onScroll={this.handleScroll}
                >
                    <div className={styles('horizontal-scroll__items')} ref={this.refItems} style={{marginBottom: `-${this.props.scrollBarWidth}px`}}>
                        {this.props.children}
                    </div>
                    {prevOverlay}
                    {nextOverlay}
                </div>
                {nextButton}
            </div>
        );
    }
}

HorizontalScroll.propTypes = {
    // properties
    children: PropTypes.node.isRequired,
    selectorQuery: PropTypes.string.isRequired,
    classes: PropTypes.string,
    backgroundColor: PropTypes.string,
    buttonSize: PropTypes.string,
    hideButtons: PropTypes.bool,
    noOverlay: PropTypes.bool,

    scrollBarWidth: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
    windowHeight: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
    return {
        scrollBarWidth: state.global.scrollBarWidth,
        windowWidth: state.global.windowDimensions.width,
        windowHeight: state.global.windowDimensions.height
    };
};

export default connect(mapStateToProps, null)(HorizontalScroll);
