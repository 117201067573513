import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { getStyles, translate, createArray, isObject, isArray, isFullArray, pushEvent } from '../../shared/utility';

import Gallery from '../../components/UI/Gallery/Gallery';
import FilterButtons from '../../components/UI/FilterButtons/FilterButtons';
import HorizontalScroll from '../../components/UI/HorizontalScroll/HorizontalScroll';
import Card from '../../components/UI/Card/Card';
import OfficeService from '../../components/UI/OfficeService/OfficeService';

// import Test from '../Test/Test';

import NearbyItems from './NearbyItems/NearbyItems';

import localStyles from './Home.module.scss';
const styles = getStyles(localStyles);

class Home extends Component {
    componentDidMount() {
        if (!isArray(this.props.popularLocations.data)) {
            // this.props.onFetchPopularLocations();
        }
    }

    onMainTypeChange = (id, name, event) => {
        const { activeSearchLocation, selectedItemTypeIds } = this.props.filters;
        const location = isObject(activeSearchLocation) ? `?location=${activeSearchLocation.id}` : null;
        const itemTypes = isFullArray(selectedItemTypeIds) ? `${location ? '&' : '?'}itemTypes=${selectedItemTypeIds.toString()}` : null;
        const urlSearch = `${location ? location : ''}${itemTypes ? itemTypes : ''}`;

        pushEvent('searchEvent', {eventCategory: 'Filter Buttons' , eventAction: 'Searching Main Item Types', eventLabel: 'Via homepage'});
        this.props.history.push({ pathname: `/items/${id}`, search: urlSearch });
    }

    handleLocationClick = (location = null) => {
        const activeLocation = location ? {
            value: location.name,
            id: location.id,
            lat: location.gpsLatitude,
            lng: location.gpsLongitude
        } : null;

        this.props.onSetFilter('activeSearchLocation', activeLocation);
    }

    render() {
        // Sub filters data
        const mainTypesArray = createArray(isFullArray(this.props.mainTypesList.data) ? this.props.mainTypesList.data : []);

        const filterSectionContent = isFullArray(mainTypesArray) || this.props.mainTypesList.loading ? (
            <>
                <h4>{translate('pages.home.filtersTitle', this.props.language)}</h4>
                <div className={styles('content', 'content--padding-vertical')}>
                    <FilterButtons
                        buttons={mainTypesArray}
                        identifier={'home-main-filters'}
                        changeEvent={this.onMainTypeChange}
                        classes={styles('home__filters')}
                        backgroundColor={'#f2f4f9'}
                        loading={this.props.mainTypesList.loading}
                        language={this.props.language}
                    />
                </div>
            </>
        ) : null;

        const locationsSectionContent = this.props.popularLocations.loading || isObject(this.props.popularLocations.data) ? (
            <ul className={styles('no-list-style', 'home__locations', 'flex')}>
                {this.props.popularLocations.loading ? [0, 1, 2].map(item => (
                    <li key={item} className={styles('home__locations__card')}>
                        <Card item={item} viewMode={'card'} />
                    </li>
                )) : isFullArray(this.props.popularLocations.data.results) ? this.props.popularLocations.data.results.map(location => isFullArray(location.imageReferences) ? (
                    <li key={location.id} className={styles('home__locations__card')} onClick={() => this.handleLocationClick(location)}>
                        <Gallery images={location.imageReferences} />
                        <h6 className={styles('home__locations__title')}>{location.name}</h6>
                    </li>
                ) : null) : null}
            </ul>
        ) : null;

        return isArray(this.props.mainTypesList.data) && this.props.mainTypesList.data.length === 1 ? <Redirect to={`items/${this.props.mainTypesList.data[0].id}`} /> : (
            <>
                <div className={styles('container', 'container--padding-evenly', 'b--gray-1')}>
                    <div className={styles('content')}>
                        {filterSectionContent}
                    </div>
                </div>

                {!isObject(this.props.popularLocations.data) ? null : (
                    <div className={styles('container', 'container--padding-evenly')}>
                        <div className={styles('content', 'content--margin-vertical')}>
                            <h4>{translate('pages.home.locations', this.props.language)}</h4>
                            <div className={styles('content', 'content--padding')}>
                                <HorizontalScroll noOverlay selectorQuery={'li'} buttonSize={'xl'}>{locationsSectionContent}</HorizontalScroll>
                            </div>
                        </div>
                    </div>
                )}

                <NearbyItems
                    data={isObject(this.props.nearbyItemsPaged.data) ? this.props.nearbyItemsPaged.data.results : []}
                    loading={this.props.nearbyItemsPaged.loading || (isObject(this.props.nearbyItemsPaged.data) && (this.props.paymentAccountDetails.loading || this.props.updateUserPreference.loading))}
                    error={this.props.nearbyItemsPaged.error}
                    language={this.props.language}
                />
                <OfficeService container />
            </>
        );
    }
}

Home.propTypes = {
    // properties
    history: PropTypes.object.isRequired,
    filters: PropTypes.object,

    mainTypesList: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    currentLocation: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    paymentAccountDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    updateUserPreference: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    nearbyItemsPaged: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    popularLocations: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,

    // functions
    onFetchPopularLocations: PropTypes.func.isRequired,
    onSetFilter: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        filters: state.filters,

        mainTypesList: state.list.mainTypes,
        paymentAccountDetails: state.details.paymentAccount,
        updateUserPreference: state.condition.updateUserPreference,
        currentLocation: state.details.currentLocation,

        popularLocations: state.paged.popularLocations,
        nearbyItemsPaged: state.paged.nearbyItems,

        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchPopularLocations: (listRange) => dispatch(actions.fetchPopularLocations(listRange)),
        onSetFilter: (identifier, value) => dispatch(actions.setFilter(identifier, value))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
