import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate, isBoolean, getStyles, priceFormat, localizePrice, isObject, isArray, showPricesIncludingVat } from '../../../../shared/utility';
import { isDate, getDateObject } from '../../../../shared/datetime';

import Tag from '../../Tag/Tag';
import TextClamp from '../../TextClamp/TextClamp';
import Icon from '../../Icon/Icon';
import Button from '../../Button/Button';
import Bar from '../../Loading/Bar/Bar';

import localStyles from './CardContent.module.scss';
const styles = getStyles(localStyles);

const cardContent = (props) => {
    const {
        item,
        loading,
        viewMode, productMode, catererMode,
        itemTypesList, dateFiltersSet, timeFilterSet,
        language, locale
    } = props;

    /* * * * * * * * * *
     * ITEM TYPE TAGS  *
    ** * * * * * * * * */
    const itemTypesArray = loading || !isArray(itemTypesList.data) || !isObject(item) || !isObject(item.itemTypeReference) ? [] : (
        itemTypesList.data.map(itemType => itemType.id === item.itemTypeReference.id ? (
            <Tag
                key={itemType.id}
                classes={styles('footer__tag')}
                modifiers={['small']}
                color={itemType.color}
            >
                {itemType.translations[language]}
            </Tag>
        ) : null)
    );
    const tags = (
        <div className={styles('tags', `tags--${viewMode}`, 'line-height--collapsed')}>
            {loading ? (
                <Bar classes={styles('tags__loader', 'small')} modifiers={['darker', 'no-animation']} />
            ) : itemTypesArray}
        </div>
    );

    /* * * * * * * * * *
     * ITEM PROPERTIES *
    ** * * * * * * * * */
    const itemPropsArray = loading || !isArray(item.itemProperties) ? [] : (
        item.itemProperties.slice(0, 3).map(itemProp => (
            <abbr key={itemProp.id} title={itemProp.name} className={styles('footer__prop', 'align--center')}>
                <img src={itemProp.imageReference.source} alt="" className={styles('footer__prop__icon')} />
                <span className={styles('footer__prop__name', `footer__prop__name--${viewMode}`, 'c--text-light', 'tiny')}>
                    <TextClamp lines={2} forceHeight={true}>{itemProp.name}</TextClamp>
                </span>
            </abbr>
        )));

    /* * * * * * *
     * LOCATION  *
    ** * * * * * */
    const location = loading ? (
        <Bar classes={styles('header__title__location__loader')} modifiers={['no-animation']} />
    ) : item.locationReference && item.locationReference.address ? (
        `${item.locationReference.address.street} ${item.locationReference.address.number || ''}${item.locationReference.numberAddition || ''}, ${item.locationReference.address.city}`
    ) : null;

    /* * * * * * * * * * *
     * AVAILABILITY TAG  *
    ** * * * * * * * * * */
    const availabilityTag = !catererMode && !loading && isBoolean(item.isAvailable) ? (
        <p className={styles('availability-tag', `availability-tag--${viewMode}`, 'line-height--collapsed', 'w--500', 'small')}>
            <span className={styles('availability-tag__tag', `availability-tag__tag--${item.isAvailable ? 'available' : 'unavailable'}`)}>
                {translate(`pages.results.items.${item.isAvailable ? dateFiltersSet ? 'available' : 'availableNow' : 'unavailable'}`, language)}
            </span>
        </p>
    ) : null;

    /* * * * * *
     * RETURN  *
    ** * * * * */
    const itemTitle = loading ? (
        <h5 className={styles('header__title__title', `header__title__title--${viewMode}`)}>
            <Bar modifiers={['darker']} classes={styles('header__title__title__loader')} />
        </h5>
    ) : (
        <TextClamp
            lines={2}
            classes={styles('header__title__title', `header__title__title--${viewMode}`)}
            tagName={'h5'}
            forceHeight={viewMode === 'map'}
        >{item.name}</TextClamp>
    );

    return (
        <>
            {viewMode === 'card' ? tags : null}
            <div className={styles('header', `header--${viewMode}`, 'flex', 'flex--justify-between', 'flex--align-start')}>
                <div className={styles('header__title', `header__title--${viewMode}`)}>
                    {itemTitle}
                    <p className={styles('header__title__location', 'c--text-light', 'line-height--tiny')}>
                        <small>
                            {location && !loading ? (
                                <Icon icon={'location'} modifiers={['inline']} />
                            ) : null}
                            {location}
                        </small>
                    </p>
                    {viewMode === 'list' ? tags : null}
                    {availabilityTag}
                    <p>
                        {!loading && isObject(item) && item.description && productMode ? item.description : null}
                    </p>
                </div>
                {viewMode === 'map' || viewMode === 'compact' ? null : (
                    <div className={styles('header__price', 'align--right')}>
                        <h5 className={styles('header__price__fee')}>
                            {loading ? (
                                <Bar modifiers={['darker', 'no-animation']} />
                            ) : item.calculatedPrice && timeFilterSet ? (
                                <>
                                    {localizePrice(priceFormat(item.calculatedPrice), item.calculatedPrice.currencyCode, locale)}
                                    <span className={styles('header__price__unit', 'tiny', 'c--text-light')}>
                                        {translate(showPricesIncludingVat() ? 'pricing.VATIncludedShort' : 'pricing.VATExcludedShort', language)}
                                    </span>
                                </>
                            ) : item.displayPrice ? (
                                <>
                                    {localizePrice(priceFormat(item.displayPrice), item.displayPrice.currencyCode, locale)}
                                    <span className={styles('header__price__unit', 'tiny', 'c--text-lighter')}>
                                        {`Per ${item.displayPrice.unit}`}
                                    </span>
                                    <span className={styles('header__price__unit', 'tiny', 'c--text-light')}>
                                        {translate(showPricesIncludingVat() ? 'pricing.VATIncludedShort' : 'pricing.VATExcludedShort', language)}
                                    </span>
                                </>
                            ) : item.priceInformation ? (
                                <>
                                    {localizePrice(priceFormat(item.priceInformation), item.priceInformation.currencyCode, locale)}
                                    <span className={styles('header__price__unit', 'tiny', 'c--text-light')}>
                                        {translate(showPricesIncludingVat() ? 'pricing.VATIncludedShort' : 'pricing.VATExcludedShort', language)}
                                    </span>
                                </>
                            ) : null}
                        </h5>
                    </div>
                )}
                {!loading && catererMode && isObject(item) && item.companyReference && item.companyReference.imageReference ? (
                    <img
                        className={styles('header__company__logo', 'align--right')}
                        src={item.companyReference.imageReference.source}
                        alt={item.companyReference.name}
                    />
                ) : null}
            </div>
            {viewMode !== 'card' && viewMode !== 'compact' && itemPropsArray.length ? (
                <div className={styles('footer')}>
                    <div className={styles('footer__props', `footer__props--${viewMode}`)}>
                        {itemPropsArray}
                    </div>
                </div>
            ) : null}
            {viewMode === 'map' && !catererMode && isObject(item) ? (
                <Button
                    href={`/item/${item.id}`}
                    modifiers={['primary', 'stretch', 'm', 'spaced']}
                >{translate('pricing.bookNow', language)}</Button>
            ) : null}
        </>
    );
};

cardContent.propTypes = {
    // properties
    item: PropTypes.object,
    loading: PropTypes.bool,
    viewMode: PropTypes.oneOf(['card', 'list', 'map', 'compact']).isRequired,
    catererMode: PropTypes.bool,
    productMode: PropTypes.bool,
    itemTypesList: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    isAuthorized: PropTypes.bool.isRequired,
    userDetails: PropTypes.shape({
        data: PropTypes.shape({
            preferences: PropTypes.object
        }),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    dateFiltersSet: PropTypes.bool,
    timeFilterSet: PropTypes.bool,

    language: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthorized: isObject(state.details.user.data),
        userDetails: state.details.user,
        itemTypesList: state.list.itemTypes,
        dateFiltersSet: isDate([getDateObject(state.filters.availableInPeriodStart), getDateObject(state.filters.availableInPeriodEnd)]),
        timeFilterSet: state.filters.availableInPeriodTimeIsSet,

        language: state.global.localization.language,
        locale: state.global.localization.locale
    };
};

export default connect(mapStateToProps, null)(cardContent);
