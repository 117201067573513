import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../shared/utility';

import Bar from '../Loading/Bar/Bar';

const styles = getStyles();

class AsyncContent extends PureComponent {
    /* * * * * * * * * * * * * *
     * REACT LIFECYCLE METHODS *
     * * * * * * * * * * * * * */
    constructor(props) {
        super(props);
        this.state = {
            content: null,
            loading: true,
            error: null
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        await this.fetchContent();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    /* * * * * * * * *
     * FETCH METHODS *
     * * * * * * * * */
    fetchContent = async () => {
        try {
            const request = new Request(this.props.url, { method: 'GET' });
            const response = await fetch(request);
            if (response.ok && response.status === 200) {
                const newContent = await response.text();
                if (this._isMounted) {
                    this.setState({ loading: false, content: newContent, error: null });
                }
            } else {
                throw response;
            }
        } catch (error) {
            const errorMessage = error.message ? error.message : error.statusText ? error.statusText : error;
            if (this._isMounted) {
                this.setState({ loading: false, content: null, error: errorMessage });
            }
        }
    }

    filterHtmlTags = (content) => {
        // remove injected styles or scripts when terms and conditions are fetched
        const div = document.createElement('div');
        div.innerHTML = content;

        const scriptTags = div.querySelectorAll('script');
        const styleTags = div.querySelectorAll('style');

        for (let i = 0; i < scriptTags.length; ++i) {
            scriptTags[i].parentNode.removeChild(scriptTags[i]);
        }

        for (let i = 0; i < styleTags.length; ++i) {
            styleTags[i].parentNode.removeChild(styleTags[i]);
        }

        return div.innerHTML;
    }

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const loadingContent = this.state.loading ? (
            <>
                <div className={styles('align--center')}><Bar modifiers={['darker', 'no-animation']} /></div>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map(item => (
                    <Fragment key={item}>
                        <Bar classes={styles('small')} />
                        {item === 6 || item === 13 ? <><br /><br /></> : null}
                    </Fragment>
                ))}
            </>
        ) : null;

        return this.state.loading ? loadingContent : this.state.error ? (
            <p className={styles('c--error')}><strong>{this.state.error}</strong></p>
        ) : this.state.content ? (
            <div dangerouslySetInnerHTML={{ __html: this.filterHtmlTags(this.state.content) }} />
        ): null;
    }
}

AsyncContent.propTypes = {
    // properties
    url: PropTypes.string.isRequired
};

export default AsyncContent;
