import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, translate, isObject, isInteger, isFullArray } from '../../../shared/utility';
import * as actions from '../../../store/actions/index';
import { fetchDetailsSuccess } from '../../../store/actions/details';

import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/UI/Modal/Modal';

import userPlaceholder from '../../../assets/images/img-contact-placeholder.svg';

import localStyles from './SelectPaymentAccount.module.scss';
const styles = getStyles(localStyles);

class SelectPaymentAccount extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            openModal: false,
            selected: null
        };
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.isAuthorized && !isInteger(this.props.paymentAccountId) && !this.props.paymentAccountDetails.loading && !this.props.paymentAccounts.loading && !this.props.updateUserPreference.loading) {
            if(isFullArray(this.props.paymentAccounts.data)) {
                // if there are payment accounts but there isn't any preferred account selected
                if(this.props.paymentAccounts.data.length === 1) {
                    // if there is only one account, then it is the personal account, select it automatically for the user
                    this.props.onFetchDetailsSuccess('paymentAccount', this.props.paymentAccounts.data.find(acc => acc.id === 0));
                } else if(this.props.paymentAccounts.data.length === 2) {
                    // if there are two accounts, then these are the personal and a business account, select the business account automatically for the user
                    this.props.onFetchDetailsSuccess('paymentAccount', this.props.paymentAccounts.data.find(acc => acc.id !== 0));
                } else if(this.props.paymentAccounts.data.length > 2 && !this.state.openModal) {
                    // if there are more than two accounts, then open the modal for the user to select a preferred account
                    this.setState({openModal: true});
                }
            } else if(!this.props.paymentAccounts.error) {
                this.props.onFetchPaymentAccounts();
            }
        }
        if(prevProps.paymentAccountId !== this.props.paymentAccountId && isInteger(this.props.paymentAccountId) && this.state.openModal) {
            this.setState({openModal: false});
        }
    }

    savePreferredPaymentAccount = (event) => {
        event.preventDefault();
        this.props.onFetchDetailsSuccess('paymentAccount', this.props.paymentAccounts.data.find(acc => acc.id === this.state.selected));
    }

    updatePreferredPaymentAccount = (id) => {
        this.setState({ selected: parseInt(id, 10) });
    }

    render(){
        return this.state.openModal ? (
            <Modal isOpen={this.state.openModal} noClosing={true}title={translate('pages.paymentAccountModal.title', this.props.language)}>
                <p className={styles('c--text-light', 'line-height--small')}>
                    {translate('pages.paymentAccountModal.subtitle', this.props.language)}
                </p>
                <form onSubmit={this.savePreferredPaymentAccount}>
                    <ul className={styles('payment-accounts__list', 'no-list-style')}>
                        {this.props.paymentAccounts.data.map(account => (
                            <li key={account.id} className={styles('payment-accounts__item')}>
                                <Input
                                    attrs={{ type: 'radio' }}
                                    checked={this.state.selected === account.id}
                                    events={{ change: () => this.updatePreferredPaymentAccount(account.id) }}
                                    modifiers={['right']}
                                    labelClasses={styles('payment-accounts__label')}
                                    labelContentClasses={styles('flex', 'flex--align-center')}
                                    loading={this.props.paymentAccountDetails.loading || this.props.updateUserPreference.loading}
                                >
                                    {account.accountType === 'personal' && !isObject(account.imageReference) ? (
                                        <img src={userPlaceholder} alt="" className={styles('payment-accounts__image', 'circle')}/>
                                    ) : account.imageReference ? (
                                        <img src={account.imageReference.source} alt="" className={styles('payment-accounts__image', 'circle')}/>
                                    ) : (
                                        <div className={styles('payment-accounts__image')}/>
                                    )}
                                    <p className={styles('payment-accounts__name', 'line-height--small')}>
                                        <strong>{account.companyReference ? account.companyReference.name : this.props.userDetails.data.name}</strong>
                                        <br/>
                                        <span className={styles('c--text-light')}>{translate(`paymentAccountTypes.${account.accountType}`, this.props.language, account.accountType)}</span>
                                    </p>
                                </Input>
                            </li>
                        ))}
                    </ul>
                    <Button
                        attrs={{type:'submit'}}
                        modifiers={['primary', 'stretch', 'm', 'spaced']}
                        disabled={!isInteger(this.state.selected)}
                        loading={this.props.paymentAccountDetails.loading || this.props.updateUserPreference.loading}
                    >{translate('ui.save', this.props.language)}</Button>
                </form>
            </Modal>
        ) : null;
    }
}

SelectPaymentAccount.propTypes = {
    isAuthorized: PropTypes.bool.isRequired,

    userDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }).isRequired,
    updateUserPreference: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }).isRequired,

    paymentAccounts: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }).isRequired,

    paymentAccountDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }).isRequired,
    paymentAccountId: PropTypes.number,

    language: PropTypes.string.isRequired,

    // functions
    onFetchDetailsSuccess: PropTypes.func.isRequired,
    onFetchPaymentAccounts: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthorized: isObject(state.details.user.data),

        userDetails: state.details.user,
        updateUserPreference: state.condition.updateUserPreference,

        paymentAccounts: state.list.paymentAccounts,
        paymentAccountDetails: state.details.paymentAccount,
        paymentAccountId: state.filters.paymentAccountId,

        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchDetailsSuccess: (identifier, data) => dispatch(fetchDetailsSuccess(identifier, data)),
        onFetchPaymentAccounts: () => dispatch(actions.fetchPaymentAccounts())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectPaymentAccount);
