import React from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../../shared/utility';

import localStyles from './CharacterCounter.module.scss';
const styles = getStyles(localStyles);

const CharacterCounter = (props) => {
    const content = `${props.current} ${props.max > -1 ? '/ ' + props.max : ''}${props.suffix ? ' ' + props.suffix : ''}`;
    const invalid = (props.max > -1 && props.current > props.max) || (props.min > -1 && props.current < props.min);

    return (
        <span className={styles('align--right', 'tiny', (invalid ? 'c--error' : ''))}>
            {content}
        </span>
    );
};

CharacterCounter.propTypes = {
    // properties
    current: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    suffix: PropTypes.string
};

export default CharacterCounter;
