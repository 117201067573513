import { listStates } from '../states';
import { FETCH_LIST_START, FETCH_LIST_SUCCESS, FETCH_LIST_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { translate, isObject, isNull, isArray, isFullArray, isEmptyArray, isFunction } from '../../shared/utility';
import { isDate } from '../../shared/datetime';
import { getLocale, availableLanguages } from '../../shared/translations';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
const ListStatesCounter = new Counter(listStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchListStart = (identifier) => {
    return {
        type: FETCH_LIST_START,
        identifier: identifier
    };
};

export const fetchListSuccess = (identifier, data = [], concat = false) => {
    ListStatesCounter.reset(identifier);
    return {
        type: FETCH_LIST_SUCCESS,
        identifier: identifier,
        data: data,
        concat: concat
    };
};

export const fetchListFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
    ListStatesCounter.reset(identifier);
    return {
        type: FETCH_LIST_FAIL,
        identifier: identifier,
        error: error
    };
};

/* * * * * * * * * * * * * * * *
 * GENERALIZED FETCH FUNCTION  *
** * * * * * * * * * * * * * * */
const fetchList = (identifier, path, settings = {}) => {
    const current = ListStatesCounter.increment(identifier);
    const { method = 'get', transformer, concat = false, bodyData = null, errorCallback } = settings;
    return async (dispatch) => {
        dispatch(fetchListStart(identifier));
        try {
            let data = await (
                isArray(path) ?
                    Promise.all(path.map(p => events[method](p, bodyData))) :
                    events[method](path, bodyData)
            );

            if (ListStatesCounter.isCurrent(identifier, current)) {
                if (isFunction(transformer)) {
                    data = transformer(data);
                }
                dispatch(fetchListSuccess(identifier, data, !!concat));
            }
        } catch (error) {
            if (ListStatesCounter.isCurrent(identifier, current)) {
                if (isFunction(errorCallback)) {
                    errorCallback(dispatch, error);
                } else {
                    dispatch(fetchListFail(identifier, error));
                }
            }
        }
    };
};

/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * *  *
* LIST OF TYPES *
* * * * * * * * */
const translateTypes = (data) => data.map(type => ({
    ...type,
    translations: availableLanguages.reduce((obj, lang) => ({
        ...obj,
        [lang]: translate(`types.${type.name.trim().toLowerCase().replace(/\s+/g, '_')}`, lang, type.name)
    }), {})
}));

export const fetchMainTypes = () => {
    return fetchList(
        'mainTypes',
        'mainItemTypes',
        { transformer: translateTypes }
    );
};

export const fetchItemTypes = () => {
    return fetchList(
        'itemTypes',
        'itemTypes',
        { transformer: translateTypes }
    );
};

export const fetchMainTypeProperties = (mainTypeId, itemTypeIds = []) => {
    const query = isFullArray(itemTypeIds) ?
        ('?' + itemTypeIds.map(id => `itemTypeIds=${id}`).join('&')) : '';

    return fetchList(
        'mainTypeProperties',
        `mainItemTypes/${mainTypeId}/properties${query}`
    );
};

export const fetchMainTypeCharacteristics = (mainTypeId, itemTypeIds = []) => {
    const query = isFullArray(itemTypeIds) ?
        ('?' + itemTypeIds.map(id => `itemTypeIds=${id}`).join('&')) : '';

    return fetchList(
        'mainTypeCharacteristics',
        `mainItemTypes/${mainTypeId}/characteristics${query}`
    );
};

/* * * * * * * * * * *  *
* MAP LOCATIONS METHODS *
* * * * * * * * * * * * */
export const fetchMapLocations = (mainTypeIds, itemTypeIds, location = { lat: null, lng: null }, filters = {}, bounds = null) => {
    const locationStr = location ?
        `latitude=${location.lat}&longitude=${location.lng}&range=${10000}` : '';

    const mainTypeIdStr = mainTypeIds && !itemTypeIds.length ?
        `mainItemTypeIds=${mainTypeIds}` : '';

    const itemTypeIdsStr = itemTypeIds.length ?
        itemTypeIds.map(typeId => `itemTypeIds=${typeId}`).join('&') : '';

    const extraFiltersStr = isFullArray(Object.keys(filters)) ? (
        Object.entries(filters).reduce((arr, map) => (
            // flatten filterproperties is passed in array
            isEmptyArray(map[1]) ? (
                arr
            ) : isFullArray(map[1]) ? (
                [...arr, ...map[1].map(value => [map[0], value])]
            ) : [...arr, ...[map]]
        ), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
    ) : '';

    const boundsStr = isObject(bounds) ?
        `northEast=${bounds.north},${bounds.east}&southWest=${bounds.south},${bounds.west}` : '';

    const filterString = '?' + [locationStr, mainTypeIdStr, itemTypeIdsStr, extraFiltersStr, boundsStr].filter(str => str.length).join('&');

    return fetchList(
        'mapLocations',
        `mapLocations${filterString}`
    );
};

/* * * * * * * *
* ITEM METHODS *
* * * * * * *  */
export const fetchItemAvailability = (itemId = null, periods = null, endDate = {}) => {
    const queryParams = isObject(periods) && isDate([periods.start, periods.end, endDate]) ? (
        `?availableInPeriodStart=${periods.start.toISOString()}&availableInPeriodEnd=${periods.end.toISOString()}&periodsEndDate=${endDate.toISOString()}`
    ) : '';

    return fetchList(
        'itemAvailability',
        `items/${itemId}/availabilityPeriods${queryParams}`
    );
};

export const fetchRepeatUnits = () => {
    return fetchList(
        'repeatUnits',
        'repeatUnits'
    );
};

export const fetchItemsPriceDetails = (itemId = null, periods = []) => {
    return fetchList(
        'itemsPriceDetails',
        periods.map((period) =>
            `items/${itemId}/price?periodStart=${period.start.toISOString()}&periodEnd=${period.end.toISOString()}`
        )
    );
};

export const fetchItemsAvailabilityBreakdown = (itemId = null, periods = []) => {
    return fetchList(
        'itemsAvailabilityBreakdown',
        periods.map((period) =>
            `items/${itemId}/availabilityBreakdown?periodStart=${period.start.toISOString()}&periodEnd=${period.end.toISOString()}`
        )
    );
};

/* * * * * * * * * * * * *
 * ITEM CHECKOUT METHODS *
 * * * * * * * * * * * * */
export const fetchItemCheckoutPaymentOptions = (itemId, paymentAccountId, numberOfOccurences = 1) => {
    fetch(
        'itemCheckoutPaymentOptions',
        `items/${itemId}/checkout/paymentOptions?paymentAccountId=${paymentAccountId}&numberOfOccurences=${numberOfOccurences}`
    );
};

export const fetchItemCheckoutPeriodsPaymentOptions = (itemId, paymentAccountId, periods = [], numberOfOccupants = null) => {
    const bodyData = {
        paymentAccountId: parseInt(paymentAccountId, 10),
        periods: periods.map(({ periodStart, periodEnd }) => ({ periodStart, periodEnd })),
        ...(!isNull(numberOfOccupants) && { numberOfOccupants })
    };

    return fetchList(
        'itemCheckoutPeriodsPaymentOptions',
        `items/${itemId}/checkout/periodsPaymentOptions`,
        { method: 'post', bodyData }
    );
};

/* * * * * * * * * *
 * CATERER METHODS *
 * * * * * * * * * */
export const fetchCatererProducts = (catererId = null) => {
    return fetchList(
        'catererProducts',
        `caterers/${catererId}/products`
    );
};

/* * * * * * * * * * * * * * * * * * * *
* LIST OF SEARCH AUTOCOMPLETE LOCATION *
* * * * * * * * * * * * * * * * * * *  */
export const fetchSearchAutocomplete = (search = null, locale = 'nl-NL', global = false) => {
    return fetchList(
        'searchAutocomplete',
        `geolocations/autocomplete?search=${search}&language=${locale}&global_search=${global}`,
        {
            transformer: (data) => {
                if (!data.length) {
                    const lang = getLocale(locale).language;
                    return [{
                        place_id: 'ZERO_RESULTS',
                        description: translate('errors.search.noresultsAutocomplete', lang)
                    }];
                }
                return data;
            },
            errorCallback: (dispatch, error) => {
                // error should be displayed as an autocomplete result item
                // dispatch(fetchListFail('searchAutocomplete', error));
                const lang = getLocale(locale).language;
                dispatch(fetchListSuccess('searchAutocomplete', [{
                    place_id: 'ERROR_RESULTS',
                    description: translate('errors.search.errorAutocomplete', lang)
                }]));
            }
        }
    );
};

/* * * * * * * * * *  *
* RESERVATION METHODS *
* * * * * * * * * * * */
export const fetchReservationPaymentOptions = (reservationId = null, newEndDate = {}) => {
    return fetchList(
        'reservationPaymentOptions',
        `reservations/${reservationId}/extensionPaymentOptions?newEndDate=${newEndDate.toISOString()}`
    );
};

/* * * * * *
 * TICKETS *
** * * * * */
export const fetchTickets = (page = { number: 1, size: 20 }) => {
    return fetchList(
        'tickets',
        `tickets?pageNumber=${page.number}&pageSize=${page.size}`,
        { concat: page.number > 1 }
    );
};

export const fetchItemTypeDefects = (itemTypeId) => {
    return fetchList(
        'itemTypeDefects',
        `tickets/itemTypeDefects/${itemTypeId}`
    );
};

/* * * * * * * * *
 * RENTAL ITEMS  *
 * * * * * * * * */
export const fetchRentalItemsByCode = (itemCode) => {
    return fetchList(
        'rentalItemsByCode',
        `rentalItems/code/${itemCode}`
    );
};

/* * * * * * * * *  *
* LIST OF ADDRESSES *
* * * * * * * * * * */
export const fetchAddresses = (postalCode = null, number = null) => {
    if (!postalCode || !number) {
        return null;
    }

    return fetchList(
        'addresses',
        `addresses?postalCode=${postalCode}&number=${number}`
    );
};

/* * * * * * * * *  *
* LIST OF COUNTRIES *
* * * * * * * * * * */
export const fetchCountries = () => {
    return fetchList(
        'countries',
        'countries'
    );
};

/* * * * * * * * * * * * *
* FETCH PAYMENT ACCOUNTS *
* * * * * * * * * * * *  */
export const fetchPaymentAccounts = () => {
    return fetchList(
        'paymentAccounts',
        'paymentAccounts'
    );
};

/* * * * * * * * * * *
 * FETCH PRINCIPALS  *
 * * * * * * * * * * */
export const fetchPrincipals = (paymentAccountId = null) => {
    return fetchList(
        'principals',
        `paymentAccounts/${paymentAccountId}/principals`
    );
};

export const fetchPrincipalsWithoutId = () => {
    return fetchList(
        'principals',
        `principals`
    );
};

/* * * * * * * *  *
* PAYMENT METHODS *
* * * * * * * * * */
export const fetchPaymentMethods = () => {
    return fetchList(
        'paymentMethods',
        'paymentMethods'
    );
};

/* * * * * * * * * * * *
* CART PAYMENT OPTIONS *
* * * * * * * * * * *  */
export const fetchCartPaymentOptions = (paymentAccountId = null) => {
    return fetchList(
        'cartPaymentOptions',
        `paymentAccounts/${paymentAccountId}/cart/paymentOptions`
    );
};

/* * * * * * * * * * * * * * * * * * *
 * BOOKING EXTENSION PAYMENT OPTIONS *
** * * * * * * * * * * * * * * * * * */
export const fetchBookingExtensionPaymentOptions = (bookingId = null, newEndDate = null, bookingType = null) => {
    return fetchList(
        'bookingExtensionPaymentOptions',
        `bookings/${bookingId}/extensionPaymentOptions?newEndDate=${newEndDate.toISOString()}&bookingType=${bookingType}`
    );
};

/* * * * * * * * * * * * * * * *
* DIRECT DEBIT PAYMENT METHODS *
* * * * * * * * * * * * * * *  */
export const fetchDirectDebits = () => {
    return fetchList(
        'directDebits',
        'directDebitPaymentMethods'
    );
};

/* * * * * * * * * * *
 * MEETINGS METHODS  *
 * * * * * * * * * * */
export const fetchMeetingVisitors = (meetingId = null) => {
    return fetchList(
        'meetingVisitors',
        `meetings/${meetingId}/visitors`
    );
};
