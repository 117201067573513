import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReduxPropTypes } from '../../../../shared/propTypes';
import { getStyles, translate, isObject } from '../../../../shared/utility';

import Modal from '../../../../components/UI/Modal/Modal';
import Button from '../../../../components/UI/Button/Button';

import backgroundImage from './backgroundImage.svg';

import localStyles from './Modal.module.scss';
const styles = getStyles(localStyles);

const SHOW_MODAL_STATUSSES = ['notStarted'];
const SESSION_STORAGE_KEY = 'remindUserOfDriversLicenseVerification';
const SESSION_STORAGE_VALUE = 'TRUE';

function hasUserBeenReminded() {
    return window.sessionStorage.getItem(SESSION_STORAGE_KEY) === SESSION_STORAGE_VALUE;
}

function remindUser() {
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, SESSION_STORAGE_VALUE);
}

const DriversLicenseVerificationModal = ({ modalIsOpen, openModal, closeModal, user, community, history, language }) => {

    const { data: uData, loading: uLoading, error: uError } = user;
    const uReady = isObject(uData) && !uLoading && !uError;
    const { driversLicenseVerificationState: verificationState } = (uReady && uData) || {};

    const { data: cData, loading: cLoading, error: cError } = community;
    const cReady = isObject(cData) && !cLoading && !cError;
    const { requiresDriversLicenseVerification: requiresVerification } = (cReady && cData) || {};

    const showModal = !hasUserBeenReminded() && !modalIsOpen && requiresVerification && SHOW_MODAL_STATUSSES.includes(verificationState);

    useEffect(() => {
        if (uReady && cReady && showModal) {
            remindUser();
            openModal();
        }
    }, [showModal, uReady, cReady, openModal]);

    return requiresVerification ? (
        <Modal close={closeModal} isOpen={!!modalIsOpen}>
            <img
                className={styles('verification-modal__image')}
                src={backgroundImage}
                alt={''}
            />
            <div className={styles('verification-modal__content', 'align--center')}>
                <h4>{translate('driversLicenseVerification.modal.title', language)}</h4>
                <p className={styles('line-height--small', 'c--text-light')}>
                    {translate('driversLicenseVerification.modal.description', language)}
                </p>
                <Button
                    modifiers={['stretch', 'primary', 'm']}
                    classes={styles('verification-modal__button')}
                    events={{click: () => {
                        closeModal();
                        history.push('/profile/drivers-license-verification-status/');
                    }}}
                >
                    {translate('driversLicenseVerification.modal.button', language)}
                </Button>
            </div>
        </Modal>
    ) : null;
};

DriversLicenseVerificationModal.propTypes = {
    modalIsOpen: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    user: ReduxPropTypes.details.isRequired,
    community: ReduxPropTypes.details.isRequired,
    history: PropTypes.object.isRequired,
    language: PropTypes.string
};

const mapStateToProps = state => {
    return {
        modalIsOpen: state.global.isDriversLicenseVerificationModalOpen,
        user: state.details.user,
        community: state.details.community,
        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeModal: () => dispatch(actions.setGlobalState('isDriversLicenseVerificationModalOpen', false)),
        openModal: () => dispatch(actions.setGlobalState('isDriversLicenseVerificationModalOpen', true)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DriversLicenseVerificationModal));
