import React, { Component } from 'react';
import { isFunction } from '../../shared/utility';

export default importComponent => (
    class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                component: null
            };
        }

        async componentDidMount() {
            const cmp = await importComponent();
        
            this.setState({ component: cmp.default });
        }

        render() {
            const ImportedComponent = this.state.component;
        
            return isFunction(ImportedComponent) ? <ImportedComponent {...this.props} /> : null;
        }
    }
);