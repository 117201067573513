import React from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../../../shared/utility';

import Input from '../../../../UI/Input/Input';

import menuStyles from '../../Menu.module.scss';
const styles = getStyles(menuStyles);

const languageToggle = (props) => props.items.map(item => (
    <li key={item.id} className={styles('menu__item', 'dropdown__item')}>
        <Input
            attrs={{ type: 'radio' }}
            value={item.id}
            checked={props.selected === item.id}
            events={{ change: () => props.change(item.id) }}
            modifiers={['right', 'stretch']}
            labelClasses={styles('menu__content')}
            labelContentClasses={styles('flex', 'flex--align-center')}
        >
            <p className={styles('menu__grow', 'dropdown__grow', 'line-height--small')}>
                <strong>{item.name}</strong>
            </p>
        </Input>
    </li>
));

languageToggle.propTypes = {
    // properties
    items: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.any,

    // functions
    change: PropTypes.func.isRequired
};

export default languageToggle;
