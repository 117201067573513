import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, translate } from '../../../shared/utility';

import Logo from '../../../components/Logo/Logo';

import localStyles from './PoweredBy.module.scss';
const styles = getStyles(localStyles);

const poweredBy = (props) => (
    <div className={styles('poweredBy', 'align--center', 'col--100')}>
        <p className={styles('small', 'c--text-light')}>
            {translate('footer.poweredBy', props.language)}&nbsp;<Logo classes={styles('footer__poweredBy__img')} modifiers={['inline']} />
        </p>
    </div>
);

poweredBy.propTypes = {
    // properties
    language: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
        language: state.global.localization.language
    };
};

export default connect(mapStateToProps, null)(poweredBy);