import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { translate, addModifierClasses, addToArray, getStyles } from '../../../../shared/utility';

import localStyles from './Loader.module.scss';
const styles = getStyles(localStyles);

const Loader = (props) => {

    let loaderClasses = ['loader', 'flex', 'flex--column', 'flex--justify-center', 'flex--align-center'];
    loaderClasses = addModifierClasses(props.modifiers, 'loader', loaderClasses);
    loaderClasses = addToArray(props.classes, loaderClasses);

    return (
        <div className={styles(['loader-container', 'flex', 'flex--justify-center', 'flex--align-center'])}>
            <div className={styles(loaderClasses)}>
                <div className={styles(['loader__dots', 'flex', 'flex--justify-between'])}>
                    <span className={styles('loader__dots__dot')}></span>
                    <span className={styles('loader__dots__dot')}></span>
                    <span className={styles('loader__dots__dot')}></span>
                </div>
                <p className={styles(['extra-strong'])}>{translate('ui.loading', props.language)}...</p>
            </div>
        </div>
    );
};

Loader.propTypes = {
    // properties
    classes: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    language: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
        language: state.global.localization.language
    };
};

export default connect(mapStateToProps, null)(Loader);
