import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, translate, addModifierClasses, isArray } from '../../../shared/utility';

import Backdrop from './Backdrop/Backdrop';
import Button from '../Button/Button';

import localStyles from './Modal.module.scss';
const styles = getStyles(localStyles);

const supportsHtmlDialog = !!(window.HTMLDialogElement && window.HTMLDialogElement.prototype);

class Modal extends PureComponent {
    /* * * * * * * * *
     * REACT METHODS *
     * * * * * * * * */
    constructor(props) {
        super(props);
        this.state = {
            isMultiPage: isArray(props.pages) && props.pages.length > 1,
            activePage: props.noNavigation ? 0 : -1
        };
    }

    componentDidMount() {
        this.bindKeyboardEvent();
    }

    componentWillUnmount() {
        this.bindKeyboardEvent(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.bindKeyboardEvent();
        }

        if (this.state.isMultiPage && !prevProps.isOpen && this.props.isOpen) {
            this.setState({ activePage: this.props.noNavigation ? 0 : -1 });
        }
    }

    /* * * * * * * * * *
     * EVENT HANDLERS  *
     * * * * * * * * * */
    handlePageSwitch = (page) => {
        this.setState({ activePage: page });
    }

    handleKeyboardClosing = (event) => {
        if (event.key === 'Escape') {
            if (this.props.noNavigation || !this.state.isMultiPage || this.state.activePage === -1) {
                this.props.close();
            } else if (this.state.isMultiPage) {
                this.handlePageSwitch(-1);
            }
        }
    }

    bindKeyboardEvent = (forceUnbind) => {
        if (this.props.noClosing) {
            return;
        }

        if (forceUnbind || !this.props.isOpen) {
            window.removeEventListener('keydown', this.handleKeyboardClosing);
            return;
        }

        window.addEventListener('keydown', this.handleKeyboardClosing);
    }



    /* * * * * * * * * *
     * CUSTOM METHODS  *
     * * * * * * * * * */
    getPageSwitcher = () => (
        <ul className={styles('modal__content', 'pageswitcher', 'no-list-style')}>
            {this.props.pages.map((page, index) => (
                (page.content || page.url) && page.title ? (
                    <li key={page.title} className={styles('pageswitcher__item')}>
                        <Button
                            classes={styles('pageswitcher__button', 'flex', 'flex--justify-between', 'flex--align-center')}
                            modifiers={['text']}
                            events={{ click: () => this.handlePageSwitch(index) }}
                            iconEnd={'arrow-right'}
                            iconColor={'green'}
                        >
                            <span className={styles('w--400')}>{page.title}</span>
                        </Button>
                    </li>
                ) : null
            ))}
        </ul>
    );

    wrapMultiPageContent = (page) => (
        <div className={styles('modal__content', 'multipage', 'flex', 'flex--column', 'flex--justify-between')}>
            <div className={styles('multipage__content')}>
                {page.content}
            </div>
            {this.props.noNavigation ? null : (
                <div className={styles('multipage__footer')}>
                    <Button
                        modifiers={['text']}
                        events={{ click: () => this.handlePageSwitch(-1) }}
                        icon={'arrow-left'}
                    >
                        {translate('ui.back', this.props.language)}
                    </Button>
                </div>
            )}
        </div>
    );

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const modalTitle = this.state.activePage === -1 ? this.props.title : (this.props.pages[this.state.activePage].title || this.props.title);

        const modalContent = this.state.isMultiPage && this.state.activePage === -1 ? this.getPageSwitcher() : (
            this.state.isMultiPage ? this.wrapMultiPageContent(this.props.pages[this.state.activePage]) : (
                isArray(this.props.pages) && (this.props.pages[0].content) ? (
                    <div className={styles('modal__content')}>{this.props.pages[0].content}</div>
                ) : this.props.children ? (
                    <div className={styles('modal__content')}>{this.props.children}</div>
                ) : null));

        const mods = [this.props.isOpen && 'open', !supportsHtmlDialog && 'legacy'].concat(this.props.modifiers);

        const modalClasses = addModifierClasses(mods, 'modal', ['modal']);

        return (modalContent ? (
            <>
                <Backdrop isOpen={this.props.isOpen} click={this.props.noClosing ? null : this.props.close} />
                <dialog className={styles(modalClasses)} open={this.props.isOpen}>
                    {this.props.noButton || this.props.noClosing ? null : (
                        <Button
                            events={{ click: this.props.close }}
                            modifiers={['square', 's', 'border', 'white']}
                            classes={styles('modal__close-button')}
                        >&#10005;</Button>
                    )}
                    {modalTitle ? (
                        <h3 className={styles('modal__title')}>{modalTitle}</h3>
                    ) : null}
                    {modalContent}
                </dialog>
            </>
        ) : null);
    }
}

Modal.propTypes = {
    // properties
    children: PropTypes.node,

    title: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    noButton: PropTypes.bool,
    noNavigation: PropTypes.bool,
    noClosing: PropTypes.bool,
    pages: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string.isRequired,
        content: PropTypes.element.isRequired
    })),
    activePage: PropTypes.number,
    modifiers: PropTypes.arrayOf(PropTypes.string),

    language: PropTypes.string.isRequired,

    // functions
    close: PropTypes.func
};

const mapStateToProps = state => {
    return {
        language: state.global.localization.language
    };
};

export default connect(mapStateToProps, null)(Modal);
