import React from 'react';
import PropTypes from 'prop-types';
import { getStyles, translate, pushEvent } from '../../../../../shared/utility';

import TextClamp from '../../../../UI/TextClamp/TextClamp';
import Input from '../../../../UI/Input/Input';
import Bar from '../../../../UI/Loading/Bar/Bar';

import userPlaceholder from '../../../../../assets/images/img-contact-placeholder.svg';

import menuStyles from '../../Menu.module.scss';
const styles = getStyles(menuStyles);

const PaymentAccountToggle = (props) => {
    const { loading, items, selected, userName, change, language } = props;

    const paymentAccounts = loading ? new Array(4).fill() : items.sort((a, b) => (b.accountType === 'personal' || a.id < b.id) && a.accountType !== 'personal' ? -1 : 1);

    return paymentAccounts.map((item, index) => (
        <li key={loading ? index : item.id} className={styles('menu__item', 'dropdown__item')}>
            {loading ? (
                <div className={styles('menu__content')}>
                    <span className={styles('flex', 'flex--align-center', 'menu__content__padded-content')}>
                        <div className={styles('menu__image', 'menu__image--empty')}/>
                        <p className={styles('menu__text', 'line-height--small')}>
                            <Bar modifiers={['no-animation', 'darker']}/><br />
                            <Bar modifiers={['no-animation']}/>
                        </p>
                    </span>
                </div>
            ) : (
                <Input
                    attrs={{ type: 'radio' }}
                    value={item.id}
                    checked={selected === item.id}
                    events={{ change: () => {
                        pushEvent(
                            'paymentAccountEvent',
                            {
                                eventCategory: 'Payment Account',
                                eventAction: 'Switch payment account',
                                eventLabel: `Switched to: ${item.companyReference ? item.companyReference.name : 'Private account'}`
                            }
                        );
                        change(item);
                    }}}
                    modifiers={['right', 'stretch']}
                    labelClasses={styles('menu__content')}
                    labelContentClasses={styles('flex', 'flex--align-center', 'flex--justify-between')}
                >
                    {item.imageReference || item.accountType === 'personal' ?
                        <img src={item.imageReference ? item.imageReference.source : userPlaceholder} alt="" className={styles('menu__image', 'menu__image--contain', 'menu__image--border', 'circle')} /> :
                        <div className={styles('menu__image', 'menu__image--empty')}/>
                    }
                    <p className={styles('menu__text', 'line-height--small')}>
                        <strong className={styles('menu__text__block')}>
                            <TextClamp lines={2}>{item.companyReference ? item.companyReference.name : userName}</TextClamp>
                        </strong>
                        <span className={styles('c--text-light')}>{translate(`paymentAccountTypes.${item.accountType}`, language, item.accountType)}</span>
                    </p>
                </Input>
            )}
        </li>
    ));
};

PaymentAccountToggle.propTypes = {
    // properties
    items: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.any,
    loading: PropTypes.bool,
    userName: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,

    // functions
    change: PropTypes.func.isRequired
};

export default PaymentAccountToggle;
