import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { fetchDetailsSuccess } from '../../../../store/actions/details';
import { getStyles, isArray, translate, isFunction, isObject, isInteger } from '../../../../shared/utility';

import Button from '../../../UI/Button/Button';

import PaymentAccountToggle from './PaymentAccountToggle/PaymentAccountToggle';
import LanguageToggle from './LanguageToggle/LanguageToggle';

import menuStyles from '../Menu.module.scss';
const styles = getStyles(menuStyles);

const subMenu = (props) => {
    if (!['preferences', 'payment', 'language'].includes(props.menu)) {
        return null;
    }

    const backButton = isFunction(props.goBack) ? (
        <Button
            events={{ click: props.goBack }}
            modifiers={['text']}
            classes={styles('submenu__back-button')}
            icon={'arrow-left'}
        >
            <span>{translate('ui.back', props.language)}</span>
        </Button>
    ) : null;

    const items = props.menu === 'payment' && props.isAuthorized ? (
        <PaymentAccountToggle
            items={isArray(props.paymentAccounts.data) ? props.paymentAccounts.data : []}
            selected={isInteger(props.paymentAccountId) ? props.paymentAccountId : null}
            loading={props.paymentAccounts.loading}
            userName={props.userDetails.data.name}
            change={(value) => {
                props.onFetchDetailsSuccess('paymentAccount', value);
            }}
            language={props.language}
        />
    ) : props.menu === 'language' ? (
        <LanguageToggle
            items={isArray(props.languageData) ? props.languageData : []}
            selected={props.languageSelected}
            change={(value) => {
                props.onSetLocalization(value);
            }}
        />
    ) : null;

    const footer = props.menu === 'payment' && props.isAuthorized ? (
        <Button
            modifiers={['primary', 'stretch', 'm']}
            events={{ click: () => { props.onOpenLinkPaymentAccountModal(); } }}
        >{translate('menu.payment.linkCompany', props.language)}</Button>
    ) : null;

    return (
        <>
            <div className={styles('submenu__content')}>
                <p className={styles('submenu__title', 'align--center')}>
                    {backButton}
                    <strong>{translate(`menu.${props.menu}.title`, props.language)}</strong>
                </p>
                <p className={styles('submenu__description', 'c--text-light', 'line-height--small')}>
                    {translate(`menu.${props.menu}.description`, props.language)}
                </p>
            </div>
            {items ? (<ul className={styles('menu', 'no-list-style')}>{items}</ul>) : null}
            {footer ? (<div className={styles('submenu__footer')}>{footer}</div>) : null}
        </>
    );
};

subMenu.propTypes = {
    // properties
    menu: PropTypes.string,

    isAuthorized: PropTypes.bool.isRequired,
    userDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    paymentAccounts: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    paymentAccountId: PropTypes.number,

    languageData: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.object
    ]),
    languageSelected: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),

    language: PropTypes.string.isRequired,

    // functions
    onFetchDetailsSuccess: PropTypes.func.isRequired,
    onSetLocalization: PropTypes.func.isRequired,
    onOpenLinkPaymentAccountModal: PropTypes.func.isRequired,
    goBack: PropTypes.func
};

const mapStateToProps = state => {
    return {
        isAuthorized: isObject(state.details.user.data),
        userDetails: state.details.user,

        paymentAccountId: state.filters.paymentAccountId,
        paymentAccounts: state.list.paymentAccounts,
        languageData: state.global.localization.available,
        languageSelected: state.global.localization.locale,

        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchDetailsSuccess: (identifier, data) => dispatch(fetchDetailsSuccess(identifier, data)),
        onSetLocalization: (locale) => dispatch(actions.setLocalization(locale)),
        onOpenLinkPaymentAccountModal: () => dispatch(actions.setGlobalState('isLinkPaymentAccountOpen', true))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(subMenu);
