import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getStyles, addToArray } from '../../../../shared/utility';

import localStyles from './Dots.module.scss';
const styles = getStyles(localStyles);

class Dots extends PureComponent {
    /* * * * * * * * *
     * REACT METHODS *
     * * * * * * * * */
    constructor(props) {
        super(props);

        this.state = {
            character: (props.character || props.bullets ? '•' : '.'),
            min: Math.abs(props.min) || 0,
            max: Math.abs(props.max) || 3,
            timeout: Math.abs(props.timeout) || 340
        };

        this.refDots = React.createRef();
        this.interval = null;
    }

    componentDidMount() {
        this.loop();
        this.interval = window.setInterval(this.loop, this.state.timeout);
    }

    componentWillUnmount() {
        window.clearInterval(this.interval);
    }

    /* * * * * * * * * *
     * CUSTOM METHODS  *
     * * * * * * * * * */
    loop = () => {
        const cLen = this.refDots.current.innerHTML.length / this.state.character.length + 1;
        this.refDots.current.innerHTML = this.state.character.repeat(cLen > this.state.max ? this.state.min : cLen);
    }

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const dotClasses = addToArray(this.props.classes, ['dots', this.props.bullets ? 'dots--bullets' : null]);

        return (
            <span className={styles(dotClasses)} ref={this.refDots}>{this.state.character.repeat(this.state.min)}</span>
        );
    }
}

Dots.propTypes = {
    // properties
    classes: PropTypes.string,
    character: PropTypes.string,
    bullets: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    timeout: PropTypes.number,
};

export default Dots;
