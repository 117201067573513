import { pagedStates } from '../states';
import { FETCH_PAGED_START, FETCH_PAGED_SUCCESS, FETCH_PAGED_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { isNull, isObject, isEmptyArray, isArray, isFullArray, isFunction } from '../../shared/utility';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
const PagedStatesCounter = new Counter(pagedStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchPagedStart = (identifier) => {
    return {
        type: FETCH_PAGED_START,
        identifier: identifier
    };
};

export const fetchPagedSuccess = (identifier, data = {}, concat = false) => {
    PagedStatesCounter.reset(identifier);
    return {
        type: FETCH_PAGED_SUCCESS,
        identifier: identifier,
        data: data,
        concat: concat
    };
};

export const fetchPagedFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
    PagedStatesCounter.reset(identifier);
    return {
        type: FETCH_PAGED_FAIL,
        identifier: identifier,
        error: error
    };
};

/* * * * * * * * * * * * * * * *
 * GENERALIZED FETCH FUNCTION  *
** * * * * * * * * * * * * * * */
const fetchPaged = (identifier, path, settings = {}) => {
    const current = PagedStatesCounter.increment(identifier);
    const { method = 'get', transformer, concat = false, bodyData = null, errorCallback } = settings;
    return async (dispatch) => {
        dispatch(fetchPagedStart(identifier));
        try {
            let data = await (
                isArray(path) ?
                    Promise.all(path.map(p => events[method](p, bodyData))) :
                    events[method](path, bodyData)
            );

            if (PagedStatesCounter.isCurrent(identifier, current)) {
                if (isFunction(transformer)) {
                    data = transformer(data);
                }
                dispatch(fetchPagedSuccess(identifier, data, !!concat));
            }
        } catch (error) {
            if (PagedStatesCounter.isCurrent(identifier, current)) {
                if (isFunction(errorCallback)) {
                    errorCallback(dispatch, error);
                } else {
                    dispatch(fetchPagedFail(identifier, error));
                }
            }
        }
    };
};

/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * * *
* ITEM METHODS *
* * * * * * *  */
export const fetchItems = (mainTypeIds, itemTypeIds, location = { lat: null, lng: null }, page = { number: 1, size: 20 }, filters = {}, bounds = null) => {
    const locationStr = location ? `latitude=${location.lat}&longitude=${location.lng}&range=${10000}` : '';
    const mainTypeIdStr = mainTypeIds && !itemTypeIds.length ? `mainItemTypeIds=${mainTypeIds}` : '';
    const itemTypeIdsStr = itemTypeIds.length ? itemTypeIds.map(typeId => `itemTypeIds=${typeId}`).join('&') : '';
    const extraFiltersStr = isFullArray(Object.keys(filters)) ? (
        Object.entries(filters).reduce((arr, map) => (
            // flatten filterproperties is passed in array
            isEmptyArray(map[1]) ? (
                arr
            ) : isFullArray(map[1]) ? (
                [...arr, ...map[1].map(value => [map[0], value])]
            ) : [...arr, ...[map]]
        ), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
    ) : '';

    const filterString = '&' + [locationStr, mainTypeIdStr, itemTypeIdsStr, extraFiltersStr].filter(str => str.length).join('&');
    const boundsString = isObject(bounds) ? `&northEast=${bounds.north},${bounds.east}&southWest=${bounds.south},${bounds.west}` : '';

    return fetchPaged(
        'items',
        `items?pageNumber=${page.number}&pageSize=${page.size}${filterString}${boundsString}`,
        { concat: (page.number > 1) }
    );
};

export const fetchActiveMapLocationItems = (mainTypeIds, itemTypeIds, location = null, page = { number: 1, size: 20 }, filters = {}) => {
    const mainTypeIdStr = mainTypeIds && !itemTypeIds.length ? `mainItemTypeIds=${mainTypeIds}` : '';
    const itemTypeIdsStr = itemTypeIds.length ? itemTypeIds.map(typeId => `itemTypeIds=${typeId}`).join('&') : '';
    const extraFiltersStr = isFullArray(Object.keys(filters)) ? (
        Object.entries(filters).reduce((arr, map) => (
            // flatten filterproperties is passed in array
            isEmptyArray(map[1]) ? (
                arr
            ) : isFullArray(map[1]) ? (
                [...arr, ...map[1].map(value => [map[0], value])]
            ) : [...arr, ...[map]]
        ), []).filter(map => isFullArray(map)).map(map => `${map[0]}=${map[1]}`).join('&')
    ) : '';

    const filterString = '&' + [mainTypeIdStr, itemTypeIdsStr, extraFiltersStr].filter(str => str.length).join('&');

    if (isNull(location)) {
        return (dispatch) => {
            dispatch(fetchPagedSuccess('activeMapLocationItems', null));
        };
    }

    return fetchPaged(
        'activeMapLocationItems',
        `items?pageNumber=${page.number}&pageSize=${page.size}&locationId=${location.id}${filterString}`
    );
};

export const fetchNearbyItems = (location = { lat: null, lng: null }, page = { number: 1, size: 20 }) => {
    return fetchPaged(
        'nearbyItems',
        `items?pageNumber=${page.number}&pageSize=${page.size}&latitude=${location.lat}&longitude=${location.lng}&range=20000`
    );
};

export const fetchItemsByCompany = (companyId, page = { number: 1, size: 20}) => {
    return fetchPaged(
        'itemsByCompany',
        `items?pageNumber=${page.number}&pageSize=${page.size}&companyId=${companyId}`
    );
};

/* * * * * * * * * *
 * CATERER METHODS *
 * * * * * * * * * */
export const fetchCaterers = (location = { lat: null, lng: null }, page = { number: 1, size: 20 }, bounds = null) => {
    const locationStr = location ? `&latitude=${location.lat}&longitude=${location.lng}&range=${10000}` : '';
    const boundsString = isObject(bounds) ? `&northEast=${bounds.north},${bounds.east}&southWest=${bounds.south},${bounds.west}` : '';

    return fetchPaged(
        'caterers',
        `caterers?pageNumber=${page.number}&pageSize=${page.size}${locationStr}${boundsString}`,
        { concat: (page.number > 1) }
    );
};

export const fetchActiveMapLocationCaterers = (location = null, page = { number: 1, size: 20 }) => {
    if (isNull(location)) {
        return (dispatch) => {
            dispatch(fetchPagedSuccess('activeMapLocationCaterers', null));
        };
    }

    return fetchPaged(
        'activeMapLocationCaterers',
        `caterers?pageNumber=${page.number}&pageSize=${page.size}&locationId=${location.id}`
    );
};

/* * * * * * * * *  *
* LIST OF LOCATIONS *
* * * * * * * * * * */
export const fetchPopularLocations = (page = { number: 1, size: 20 }) => {
    return fetchPaged(
        'popularLocations',
        `locations?pageNumber=${page.number}&pageSize=${page.size}`
    );
};

/* * * * * * * * * *  *
* BOOKINGS METHODS *
* * * * * * * * * * * */
export const fetchBookings = (params = {}, page = {}) => {
    const { number = 1, size = 20 } = page;
    const { expired = false, orderDescending = false } = params;
    return fetchPaged(
        'bookings',
        `bookings?pageNumber=${number}&pageSize=${size}&expired=${expired}&orderDescending=${orderDescending}`,
        { concat: (page.number > 1) }
    );
};

export const fetchProductOrders = (date = { after: null, before: null }, page = { number: 1, size: 20 }, orderDescending = false, includeCancelled = false) => {
    const endDateAfterApi = date.after ? `&endDateAfter=${date.after}` : '';
    const endDateBeforeApi = date.before ? `&endDateBefore=${date.before}` : '';

    return fetchPaged(
        'productOrders',
        `productOrders?pageNumber=${page.number}&pageSize=${page.size}${endDateAfterApi}${endDateBeforeApi}&orderDescending=${orderDescending}`
    );
};

/* * * * * * *  *
* ORDER METHODS *
* * * * * * * * */
export const fetchOrders = (page = { number: 1, size: 20 }, reservationId = null, productOrderId = null) => {
    const reservationIdQuery = isNull(reservationId) ? '' : `&reservationId=${reservationId}`;
    const productOrderIdQuery = isNull(productOrderId) ? '' : `&productOrderId=${productOrderId}`;
    return fetchPaged(
        'orders',
        `orders?pageNumber=${page.number}&pageSize=${page.size}${reservationIdQuery}${productOrderIdQuery}`,
        { concat: (page.number > 1) }
    );
};

export const fetchOrderBookings = (orderId = null) => {
    return fetchPaged(
        'orderBookings',
        `orders/${orderId}/bookings`
    );
};


/* * * * * * * * * * *
 * MEETINGS METHODS  *
 * * * * * * * * * * */
export const fetchMeetings = (page = { number: 1, size: 20 }, date = { after: null, before: null }, orderDescending = false, includeCancelled = false, concat = false) => {
    const endDateAfterApi = date.after ? `&endDateAfter=${date.after}` : '';
    const endDateBeforeApi = date.before ? `&endDateBefore=${date.before}` : '';
    const concatValue = concat ? (page.number > 1) : concat;

    return fetchPaged(
        'meetings',
        `meetings?pageNumber=${page.number}&pageSize=${page.size}${endDateAfterApi}${endDateBeforeApi}&orderDescending=${orderDescending}&includeCancelled=${includeCancelled}`,
        { concat: concatValue }
    );
};