import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getStyles, addModifierClasses, addToArray, getEventAttributes, isFullArray, isString } from '../../../shared/utility';

import Icon from '../Icon/Icon';
import Dots from '../Loading/Dots/Dots';

import localStyles from './Button.module.scss';
const styles = getStyles(localStyles);

const button = (props) => {
    const modifiers = (isFullArray(props.modifiers) ? props.modifiers : []);

    const colorModifiers = ['primary', 'error', 'warning', 'white', 'transparent', 'text'];
    if (!modifiers.some(mod => colorModifiers.includes(mod))) {
        modifiers.push('transparent');
    }

    let buttonClasses = ['button'];
    const mods = (props.modifiers || []).concat(props.disabled ? 'disabled' : []);
    buttonClasses = addModifierClasses(mods, 'button', buttonClasses);
    buttonClasses = addToArray(props.classes, buttonClasses);

    const eventAttributes = getEventAttributes(props.events || {});
    const buttonAttributes = props.attrs && props.attrs.type ? props.attrs : {...props.attrs, type: 'button'};

    const iconStart = !props.icon && !props.iconStart ? null : (
        <Icon
            icon={(props.iconStart || props.icon)}
            attrs={{ ...props.attrs }}
            color={(props.iconStartColor || props.iconColor)}
            classes={styles('button__icon', !props.children ? 'button__icon--only' : null)}
        />
    );

    const iconEnd = !props.iconEnd ? null : (
        <Icon
            icon={(props.iconEnd)}
            attrs={{ ...props.attrs }}
            color={(props.iconEndColor || props.iconColor)}
            classes={styles('button__icon', 'button__icon--end', !props.children ? 'button__icon--only' : '')}
        />
    );

    const innerContent = (
        <>
            {iconStart}
            {props.children ? props.loading ? <Dots bullets /> : props.children : null}
            {iconEnd}
        </>
    );

    const buttonContent = !isString(props.href) || props.external ? (
        <button
            disabled={props.disabled || props.loading}
            className={styles(buttonClasses)}
            {...eventAttributes}
            {...buttonAttributes}
        >
            {innerContent}
        </button>
    ) : null;

    const linkContent = isString(props.href) && !props.external ? (
        <NavLink
            to={props.href}
            target={props.target}
            rel={'noopener noreferrer'}
            className={styles(buttonClasses)}
        >
            {innerContent}
        </NavLink>
    ) : isString(props.href) && props.external ? (
        <a href={props.href} target={props.target} rel={'noopener noreferrer'}>
            {buttonContent}
        </a>
    ) : null;

    return isString(props.href) ? linkContent : buttonContent;
};

button.propTypes = {
    icon: PropTypes.string,
    iconColor: PropTypes.string,
    iconStart: PropTypes.string,
    iconStartColor: PropTypes.string,
    iconEnd: PropTypes.string,
    iconEndColor: PropTypes.string,

    children: PropTypes.node,

    /** href attribute. Use this property to output a `<a>` */
    href: PropTypes.string,
    /** if `href` has been set, this will be used as `target` attribute */
    target: PropTypes.string,
    /** if link should redirect to external page */
    external: PropTypes.bool,

    /** disable button and show loading state */
    loading: PropTypes.bool,
    /** disable button */
    disabled: PropTypes.bool,

    /** events to bind to the button */
    events: PropTypes.objectOf(PropTypes.func),
    /** button modifiers */
    modifiers: PropTypes.arrayOf(PropTypes.string),
    /** extra css classes */
    classes: PropTypes.string,
    /** Object with attributes add to outputted `<button/>`*/
    attrs: PropTypes.object
};

button.defaultProps = {
    icon: undefined,
    iconColor: undefined,
    iconStart: undefined,
    iconStartColor: undefined,
    iconEnd: undefined,
    iconEndColor: undefined,

    children: undefined,

    href: undefined,
    target: undefined,

    loading: false,
    disabled: false,

    events: {},
    modifiers: undefined,
    classes: undefined,
    attrs: undefined
};

export default button;
