import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { getStyles, translate, isInteger, isArray, isFullArray, isFunction, isFullString } from '../../../shared/utility';

import TextClamp from '../../UI/TextClamp/TextClamp';
import Button from '../../UI/Button/Button';
import Icon from '../../UI/Icon/Icon';
import SubMenu from './SubMenu/SubMenu';

import userPlaceholder from '../../../assets/images/img-contact-placeholder.svg';

import localStyles from './Menu.module.scss';
const styles = getStyles(localStyles);

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state = { activeMenu: props.menu || 'user' };
    }

    componentDidUpdate(prevProps, prevState) {
        // fetch paymentAccounts
        if (prevState.activeMenu !== this.state.activeMenu && this.state.activeMenu === 'payment' && !isArray(this.props.paymentAccounts.data) && !this.props.paymentAccounts.loading && !this.props.paymentAccounts.error) {
            this.props.onFetchPaymentAccounts();
        }

        // close menu after updating either payment account or changing language
        if (isFunction(this.props.close) && ((isInteger(prevProps.paymentAccountId) && isInteger(this.props.paymentAccountId) && prevProps.paymentAccountId !== this.props.paymentAccountId) || (isFullString(prevProps.language) && isFullString(this.props.language) && prevProps.language !== this.props.language))) {
            this.props.close();
        }
    }

    setActiveMenu = (newMenu) => {
        if (this.state.activeMenu !== newMenu) {
            this.setState({ activeMenu: newMenu });
        }
    }

    /* * * * * * *
     * NAV ITEMS *
     * * * * * * */
    getNavItems = (type, items = [], addPaymentAccountDisplay = false) => {
        const navItems = items.map((item, index) => {
            item.index = isInteger(item.index) ? item.index : index;
            return item;
        }).sort((a, b) => a.index >= b.index ? 1 : -1);

        const navItemContentClasses = ['menu__content', 'flex', 'flex--align-center', 'flex--justify-between'];
        const { data: paymentAccountData } = this.props.paymentAccountDetails;
        const paymentAccountsDisplay = addPaymentAccountDisplay ? (
            <li className={styles('menu__item', `${type}__item`)}>
                <div className={styles(navItemContentClasses)}>
                    <img
                        className={styles('menu__image', 'menu__image--contain', 'menu__image--border', 'circle')}
                        src={paymentAccountData && paymentAccountData.imageReference ? paymentAccountData.imageReference.source : userPlaceholder}
                        alt="" />
                    <p className={styles('menu__text', 'line-height--small')}>
                        <strong className={styles('menu__text__block')}>
                            <TextClamp lines={2}>{this.props.userDetails.data.name}</TextClamp>
                        </strong>
                        <span className={styles('c--text-light')}>
                            {paymentAccountData ? translate(`paymentAccountTypes.${paymentAccountData.accountType}`, this.props.language, paymentAccountData.accountType) : 'No default'}</span>
                    </p>
                    <Button
                        classes={styles('menu__button')}
                        events={{ click: () => this.setActiveMenu('payment') }}
                        modifiers={['xs', 'square']}
                        icon={'arrow-right'}
                    />
                </div>
            </li>
        ) : null;

        return (
            <>
                {paymentAccountsDisplay}
                {navItems.map(item => {
                    const innerContent = (
                        <>
                            {item.icon ? (
                                <div className={styles('menu__image')}>
                                    <Icon classes={styles('menu__icon')} icon={item.icon} modifiers={[this.props.iconSize || 'medium']}/>
                                </div>
                            ) : null}
                            <span className={styles('w--400', 'menu__text')}>
                                {translate(item.title, this.props.language)}
                            </span>
                        </>
                    );

                    const itemContent = item.path ? (
                        <NavLink
                            to={item.path}
                            onClick={isFunction(this.props.close) ? this.props.close : null}
                            className={styles(navItemContentClasses, 'menu__link', `${type}__link`, 'no-text-decoration')}
                            isActive={() => this.props.history.location.pathname.substr(0, item.path.length).toLowerCase() === item.path.toLowerCase()}
                            activeClassName={styles('menu__link--active', `${type}__link--active`)}
                        >
                            {innerContent}
                        </NavLink>
                    ) : item.click || item.submenu ? (
                        <Button
                            classes={styles(navItemContentClasses, 'menu__link', `${type}__link`)}
                            modifiers={['text']}
                            events={{ click: () => {
                                if (item.submenu) {
                                    this.setActiveMenu(item.submenu);
                                    return;
                                }
                                item.click();
                                if (isFunction(this.props.close)) {
                                    this.props.close();
                                }
                            }}}
                            iconEnd={item.submenu ? 'arrow-right' : null}
                        >{innerContent}</Button>
                    ) : <div className={styles(navItemContentClasses)}>{innerContent}</div>;

                    return <li className={styles('menu__item', `${type}__item`)} key={item.title}>{itemContent}</li>;
                })}
            </>
        );
    }

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const type = ['dropdown', 'sidemenu'].includes(this.props.type) ? this.props.type : 'sidemenu';
        const parentMenus = {
            'preferences': 'user',
            'payment': 'user',
            'language': 'preferences'
        };

        const subMenuOpen = this.state.activeMenu !== 'user' && this.state.activeMenu !== 'custom';

        const subMenuContent = subMenuOpen ? (
            <SubMenu
                menu={this.state.activeMenu}
                goBack={() => this.setActiveMenu(parentMenus[this.state.activeMenu])}
            />
        ) : null;

        const defaultItems = {
            'user': [
                {
                    title: 'menu.user.bookings',
                    icon: 'my-reservations',
                    path: '/bookings/'
                },
                {
                    title: 'menu.user.orders',
                    icon: 'package',
                    path: '/orders/'
                },
                {
                    title: 'menu.user.tickets',
                    icon: 'warning',
                    path: '/tickets/'
                },
                // {
                //     title: 'menu.user.visitors',
                //     icon: 'persons',
                //     path: '/visits/'
                // }
            ],
            'preferences': [
                {
                    title: 'menu.preferences.changeLanguage',
                    icon: 'chat',
                    submenu: 'language'
                },
                {
                    title: 'menu.user.profile',
                    icon: 'user-thin',
                    path: '/profile/'
                },
                {
                    title: 'menu.user.paymentPreferences',
                    icon: 'payment',
                    path: '/payment-preferences/'
                }
            ]
        };

        const menuItems = (defaultItems[this.state.activeMenu] || []).concat(!subMenuOpen ? (this.props.items || []) : []);

        const menuContent = isFullArray(menuItems) ? (
            <ul className={styles('menu', type, 'no-list-style')}>
                {this.getNavItems(type, menuItems, this.state.activeMenu === 'user')}
            </ul>
        ) : null;

        return (
            <>
                {subMenuContent}
                {menuContent}
            </>
        );
    }
}

Menu.propTypes = {
    // properties
    history: PropTypes.object.isRequired,
    menu: PropTypes.string,
    type: PropTypes.string,
    iconSize: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),

    userDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    paymentAccountDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    paymentAccountId: PropTypes.number,
    isMenuOpen: PropTypes.bool,
    paymentAccounts: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,

    // functions
    onFetchPaymentAccounts: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        userDetails: state.details.user,
        paymentAccountDetails: state.details.paymentAccount,
        paymentAccountId: state.filters.paymentAccountId,
        isMenuOpen: state.global.isMenuOpen,
        paymentAccounts: state.list.paymentAccounts,

        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchPaymentAccounts: () => dispatch(actions.fetchPaymentAccounts()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
