import React from 'react';
import PropTypes from 'prop-types';

import { getStyles, addToArray } from '../../../shared/utility';

import HorizontalScroll from '../../../components/UI/HorizontalScroll/HorizontalScroll';
import Input from '../../../components/UI/Input/Input';
import Bar from '../Loading/Bar/Bar';

import localStyles from './FilterButtons.module.scss';
const styles = getStyles(localStyles);

const filterButtons = (props) => (
    <div className={styles(addToArray(props.classes, styles('filter-buttons')))}>
        <HorizontalScroll selectorQuery={'li'} backgroundColor={props.backgroundColor || null}>
            <ul className={styles('no-list-style', 'filter-buttons__list')}>
                {props.loading ? new Array(5).fill().map((item, index) => (
                    <li key={index} className={styles('filter-buttons__item')}>
                        <Bar classes={styles('filter-buttons__item__loader')} modifiers={['darker', 'auto-height']} />
                    </li>
                )) : props.buttons.map(button => (
                    <li className={styles('filter-buttons__item')} key={button.id}>
                        <Input
                            attrs={{
                                type: props.multiple ? 'checkbox' : 'radio',
                                name: props.identifier
                            }}
                            value={button.id}
                            checked={button.isActive}
                            events={{
                                change: (event) => props.changeEvent(button.config.id, button.config.name, event)
                            }}
                            labelClasses={styles('filter-buttons__label')}
                            labelContentClasses={styles('filter-buttons__label__content')}
                            modifiers={['button', 'auto-height']}
                        >
                            <abbr className={styles('filter-buttons__button', 'line-height--collapsed', 'align--center')} title={button.config.translations[props.language]}>
                                <img src={button.config.imageReference.source} className={styles('filter-buttons__button__icon')} alt="" />
                                <span className={styles('filter-buttons__button__content', 'line-height--tiny', 'ellipsis')}>
                                    {button.config.translations[props.language]}
                                </span>
                            </abbr>
                        </Input>
                    </li>
                ))}
            </ul>
        </HorizontalScroll>
    </div>
);

filterButtons.propTypes = {
    // properties
    buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
    identifier: PropTypes.string.isRequired,
    classes: PropTypes.string,
    backgroundColor: PropTypes.string,
    multiple: PropTypes.bool,
    loading: PropTypes.bool.isRequired,

    // functions
    changeEvent: PropTypes.func.isRequired
};

export default filterButtons;
