// different action types for the different functions

/* * * * * * * * *
 * ITEM SPECIFIC *
 * * * * * * * * */
// Fetch Conditional requests that only indicate if it was success or fail
export const FETCH_CONDITION_START = 'FETCH_CONDITION_START';
export const FETCH_CONDITION_SUCCESS = 'FETCH_CONDITION_SUCCESS';
export const FETCH_CONDITION_FAIL = 'FETCH_CONDITION_FAIL';
// Fetch Details for Item, Reservation, Order, Price, Contact ...
export const FETCH_DETAILS_START = 'FETCH_DETAILS_START';
export const FETCH_DETAILS_SUCCESS = 'FETCH_DETAILS_SUCCESS';
export const FETCH_DETAILS_FAIL = 'FETCH_DETAILS_FAIL';
// Fetch List/Group of mainTypes, itemTypes, order reservations, locations,...
export const FETCH_LIST_START = 'FETCH_LIST_START';
export const FETCH_LIST_SUCCESS = 'FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAIL = 'FETCH_LIST_FAIL';
// Fetch paged list of items, nearbyItems, orders, reservations,...
export const FETCH_PAGED_START = 'FETCH_PAGED_START';
export const FETCH_PAGED_SUCCESS = 'FETCH_PAGED_SUCCESS';
export const FETCH_PAGED_FAIL = 'FETCH_PAGED_FAIL';

// Filter related resources to be reused in different components
export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';

/* * * * * * * * *
 * VIEW SPECIFIC *
 * * * * * * * * */
export const SET_GLOBAL_STATE = 'SET_GLOBAL_STATE';
export const UPDATE_REDIRECT_OBJECT = 'UPDATE_REDIRECT_OBJECT';

/* * * * * * * * *
 * NOTIFICATIONS *
 * * * * * * * * */
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

/* * * * * * * * *
 * LOCALIZATION  *
 * * * * * * * * */
export const SET_LOCALIZATION = 'SET_LOCALIZATION';

/* * * *
 * UI  *
 * * * */
export const SET_WINDOW_DIMENSIONS = 'SET_WINDOW_DIMENSIONS';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_STICKY_NAVIGATION_HEIGHT = 'SET_STICKY_NAVIGATION_HEIGHT';
export const SET_SCROLLBAR_WIDTH = 'SET_SCROLLBAR_WIDTH';
