import { detailsStates } from '../states';
import { FETCH_DETAILS_START, FETCH_DETAILS_SUCCESS, FETCH_DETAILS_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { checkTokenTimeout } from './condition';
import { setGlobalState, updateRedirect } from './global';
import { isBoolean, isObject, isNull, isFullArray, isFullString, isInteger } from '../../shared/utility';
import { isDate } from '../../shared/datetime';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
const DetailsStatesCounter = new Counter(detailsStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchDetailsStart = (identifier) => {
    return {
        type: FETCH_DETAILS_START,
        identifier: identifier
    };
};

export const fetchDetailsSuccess = (identifier, data = {}) => {
    DetailsStatesCounter.reset(identifier);
    return {
        type: FETCH_DETAILS_SUCCESS,
        identifier: identifier,
        data: data
    };
};

export const fetchDetailsFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
    DetailsStatesCounter.reset(identifier);
    return {
        type: FETCH_DETAILS_FAIL,
        identifier: identifier,
        error: error
    };
};

/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * * *
* USER DETAILS *
* * * * * * *  */
export const fetchUserDetails = (userName = null, password = null, remember = false) => {
    const identifier = 'user';

    const tokenBodyData =  {
        ...(userName && password && { username: userName, password: password, remember: remember, grant_type : 'password' })
    };

    return async dispatch => {
        dispatch(fetchDetailsStart(identifier));
        try {
            // get token and user data
            await events.post('token', tokenBodyData);
            const userData = await events.get('users/me');
            localStorage.setItem('userData', JSON.stringify(userData));

            dispatch(checkTokenTimeout());
            dispatch(fetchDetailsSuccess(identifier, userData));
        } catch (error) {
            if(error === 'email_unverified') {
                dispatch(setGlobalState('isLoginOpen', false));
                dispatch(fetchDetailsFail(identifier, null));
                dispatch(updateRedirect('/verification/start', `?emailUnverified=${userName}`));
            } else if (error === 'password_not_set') {
                dispatch(setGlobalState('isLoginOpen', false));
                dispatch(fetchDetailsFail(identifier, null));
                dispatch(updateRedirect('/verification/start', `?passwordNotSet=${userName}`));
            } else {
                dispatch(fetchDetailsFail(identifier, error));
            }
        }
    };
};

export const fetchGoogleUserDetails = (idToken = null, email = null) => {
    const tokenBodyData = {
        ...(idToken && { code : idToken, username: email, grant_type : 'custom' })
    };

    return async dispatch => {
        dispatch(fetchDetailsStart('user'));
        try {
            // get token and user data
            await events.post('token', tokenBodyData);
            const userData = await events.get('users/me');
            localStorage.setItem('userData', JSON.stringify(userData));

            dispatch(checkTokenTimeout());
            dispatch(fetchDetailsSuccess('user', userData));
        } catch (error) {
            dispatch(fetchDetailsFail('user', error));
        }
    };
};

/* * * * * * *
* FETCH CART *
* * * * * *  */
export const fetchCartDetails = (paymentAccountId = null, paymentOptionId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('cart'));
        try {
            const data = await events.get(`paymentAccounts/${paymentAccountId}/cart`);
            if (isFullArray(data.cartItems)) {
                dispatch(fetchCartPricing(paymentAccountId, paymentOptionId));
            } else {
                dispatch(fetchDetailsSuccess('cartPricing', null));
            }
            dispatch(fetchDetailsSuccess('cart', data));
        } catch (error) {
            dispatch(fetchDetailsFail('cart', error));
        }
    };
};

export const fetchCartPricing = (paymentAccountId = null, paymentOptionId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('cartPricing'));
        try {
            const data = await events.get(`paymentAccounts/${paymentAccountId}/cart/price${paymentOptionId ? `?paymentOptionId=${paymentOptionId}` : ''}`);
            dispatch(fetchDetailsSuccess('cartPricing', data));
        } catch (error) {
            dispatch(fetchDetailsFail('cartPricing', error));
        }
    };
};

export const applyVoucher = (paymentAccountId = null, voucherCode = {}, paymentOptionId = null, cartData) => {
    const voucherBody = { value: voucherCode };
    return async dispatch => {
        dispatch(fetchDetailsStart('addCartVoucher'));
        try {
            const data = await events.post(`paymentAccounts/${paymentAccountId}/cart/applyVoucher`, voucherBody);
            const updatedCartItems = cartData.cartItems.map(item => item.id === data.id ? data : item);
            const updatedCartData = { ...cartData, ...{ cartItems: updatedCartItems } };
            dispatch(fetchDetailsSuccess('cart', updatedCartData));
            dispatch(fetchCartPricing(paymentAccountId, paymentOptionId));
            dispatch(fetchDetailsSuccess('addCartVoucher', data));
        } catch (error) {
            dispatch(fetchDetailsFail('addCartVoucher', error));
        }
    };
};

/* * * * * * * * * * * *  *
* PAYMENT ACCOUNT DETAILS *
* * * * * * * * * * * * * */
export const fetchPaymentAccountDetails = (accountId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('paymentAccount'));
        try {
            const data = await events.get(`paymentAccounts/${accountId}`);
            dispatch(fetchDetailsSuccess('paymentAccount', data));
        } catch (error) {
            dispatch(fetchDetailsFail('paymentAccount', error));
        }

    };
};

/* * * * * * *  *
* ITEMS DETAILS *
* * * * * * * * */
export const fetchItemDetails = (id = null, includeNonBookable = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('item'));
        try {
            const data = await events.get(`items/${id}${isBoolean(includeNonBookable) ? `?includeNonBookable=${includeNonBookable}` : ''}`);
            dispatch(fetchDetailsSuccess('item', data));
        } catch (error) {
            dispatch(fetchDetailsFail('item', error));
        }
    };
};

export const fetchItemPriceDetails = (itemId = null, date = null) => {
    const periodQuery = isInteger(itemId) && isObject(date) && isDate(date.start) && isDate(date.end) ? `?periodStart=${date.start.toISOString()}&periodEnd=${date.end.toISOString()}` : '';
    return async dispatch => {
        dispatch(fetchDetailsStart('itemPrice'));
        try {
            const data = await events.get(`items/${itemId}/price${periodQuery}`);
            dispatch(fetchDetailsSuccess('itemPrice', data));
        } catch (error) {
            dispatch(fetchDetailsFail('itemPrice', error));
        }
    };
};

export const fetchItemContactDetails = (itemId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('itemContact'));
        try {
            const data = await events.get(`items/${itemId}/contact`);
            dispatch(fetchDetailsSuccess('itemContact', data));
        } catch (error) {
            dispatch(fetchDetailsFail('itemContact', error));
        }
    };
};

/* * * * * * * * *
 * ITEM CHECKOUT *
 * * * * * * * * */
export const fetchItemCheckoutPrice = (itemId, periodStart, periodEnd, { repeatEndDate, repeatType, numberOfOccupants }) => {
    periodStart = !isDate(periodStart) ? new Date() : periodStart;
    periodEnd = !isDate(periodEnd) ? new Date() : periodEnd;

    let queryString = `?periodStart=${periodStart.toISOString()}&periodEnd=${periodEnd.toISOString()}`;

    if(isDate(repeatEndDate) && isFullString(repeatType)){
        queryString += `&repeatEndDate=${repeatEndDate.toISOString()}&repeatType=${repeatType}`;
    }

    if (isInteger(numberOfOccupants) && numberOfOccupants > 1) {
        queryString += `&numberOfOccupants=${numberOfOccupants}`;
    }

    return async dispatch => {
        dispatch(fetchDetailsStart('itemCheckoutPrice'));
        try {
            const data = await events.get(`items/${itemId}/checkout/price${queryString}`);
            dispatch(fetchDetailsSuccess('itemCheckoutPrice', data));
        } catch (error) {
            dispatch(fetchDetailsFail('itemCheckoutPrice', error));
        }
    };
};

export const fetchItemCheckoutPeriodsPrice = (itemId, paymentAccountId, periods = [], paymentOptionId = null, numberOfOccupants = null) => {
    const identifier = 'itemCheckoutPeriodsPrice';
    const current = DetailsStatesCounter.increment(identifier);

    const bodyData = {
        paymentAccountId: parseInt(paymentAccountId, 10),
        periods: periods.map(({ periodStart, periodEnd }) => ({ periodStart, periodEnd })),
        ...(!isNull(paymentOptionId) && { paymentOptionId }),
        ...(!isNull(numberOfOccupants) && { numberOfOccupants })
    };

    return async dispatch => {
        dispatch(fetchDetailsStart(identifier));
        try {
            const data = await events.post(`items/${itemId}/checkout/periodsPrice`, bodyData);
            if (DetailsStatesCounter.isCurrent(identifier, current)) {
                dispatch(fetchDetailsSuccess(identifier, data));
            }
        } catch(error) {
            if (DetailsStatesCounter.isCurrent(identifier, current)) {
                dispatch(fetchDetailsFail(identifier, error));
            }
        }
    };
};

export const fetchItemCheckoutTermsAndConditions = (itemId) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('itemCheckoutTermsAndConditions'));
        try {
            const data = await events.get(`items/${itemId}/checkout/termsAndConditions`);
            dispatch(fetchDetailsSuccess('itemCheckoutTermsAndConditions', data));
        } catch (error) {
            dispatch(fetchDetailsFail('itemCheckoutTermsAndConditions', error));
        }
    };
};

/* * * * * * * *  *
* CATERER DETAILS *
* * * * * * * * * */
export const fetchCatererDetails = (catererId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('caterer'));
        try {
            const data = await events.get(`caterers/${catererId}`);
            dispatch(fetchDetailsSuccess('caterer', data));
        } catch (error) {
            dispatch(fetchDetailsFail('caterer', error));
        }
    };
};

export const fetchCatererPriceDetails = (catererId = null, products = []) => {
    const bodyData = products.filter(product => (
        isInteger(product.productId) && isInteger(product.quantity)
    )).map(product => ({productId: product.productId, quantity: product.quantity}));

    return async dispatch => {
        dispatch(fetchDetailsStart('catererPrice'));
        try {
            const data = await events.post(`caterers/${catererId}/price`, bodyData);
            dispatch(fetchDetailsSuccess('catererPrice', data));
        } catch (error) {
            dispatch(fetchDetailsFail('catererPrice', error));
        }
    };
};

export const fetchCatererContactDetails = (catererId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('catererContact'));
        try {
            const data = await events.get(`caterers/${catererId}/contact`);
            dispatch(fetchDetailsSuccess('catererContact', data));
        } catch (error) {
            dispatch(fetchDetailsFail('catererContact', error));
        }
    };
};

/* * * * * * * * * * *
 * ITEM AVAILABILITY *
 * * * * * * * * * * */
export const fetchItemAvailabilityBreakdown = (id = null, date = null) => {
    const periodQuery = isInteger(parseInt(id, 10)) && isObject(date) && isDate([date.start, date.end]) ?
        `?periodStart=${date.start.toISOString()}&periodEnd=${date.end.toISOString()}` : '';

    return async dispatch => {
        dispatch(fetchDetailsStart('itemAvailabilityBreakdown'));
        try {
            const data = await events.get(`items/${id}/availabilityBreakdown${periodQuery}`);
            dispatch(fetchDetailsSuccess('itemAvailabilityBreakdown', data));
        } catch (error) {
            dispatch(fetchDetailsFail('itemAvailabilityBreakdown', error));
        }
    };
};

export const fetchMonthlyItemAvailabilityBreakdown = (id = null, date = null) => {
    const periodQuery = isInteger(parseInt(id,10)) && isObject(date) && isDate([date.start, date.end]) ?
        `?periodStart=${date.start.toISOString()}&periodEnd=${date.end.toISOString()}` : '';

    return async dispatch => {
        dispatch(fetchDetailsStart('monthlyItemAvailabilityBreakdown'));
        try {
            const data = await events.get(`items/${id}/availabilityBreakdown${periodQuery}`);
            dispatch(fetchDetailsSuccess('monthlyItemAvailabilityBreakdown', data));
        } catch (error) {
            dispatch(fetchDetailsFail('monthlyItemAvailabilityBreakdown', error));
        }
    };
};

/* * * * * * * * * * * * * *
* CURRENT LOCATION DETAILS *
* * * * * * * * * * * * *  */
export const fetchCurrentLocation = () => {
    return dispatch => {
        dispatch(fetchDetailsStart('currentLocation'));
        navigator.geolocation.getCurrentPosition(
            success => {
                dispatch(fetchDetailsSuccess('currentLocation', { id: 'CURRENT_LOCATION', value: 'CURRENT_LOCATION', lat: success.coords.latitude, lng: success.coords.longitude }));
            },
            error => {
                if(error.code === 3) {
                    // if error type is timeout, try again
                    dispatch(fetchCurrentLocation());
                } else {
                    dispatch(fetchDetailsFail('currentLocation', error.message));
                }
            },
            {
                enableHighAccuracy: true,
                timeout: 15000,     // 15 seconds
                maximumAge: 60000   // 1 minute
            }
        );
    };
};

/* * * * * * * *  *
* COMPANY DETAILS *
* * * * * * * * * */
export const fetchCompanyDetails = (companyId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('company'));
        try {
            const data = await events.get(`companies/${companyId}`);
            dispatch(fetchDetailsSuccess('company', data));
        } catch (error) {
            dispatch(fetchDetailsFail('company', error));
        }
    };
};
export const fetchCompanyContactDetails = (companyId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('companyContact'));
        try {
            const data = await events.get(`companies/${companyId}/contact`);
            dispatch(fetchDetailsSuccess('companyContact', data));
        } catch (error) {
            dispatch(fetchDetailsFail('companyContact', error));
        }
    };
};

/* * * * * * * * * * * *  *
* SEARCH LOCATION DETAILS *
* * * * * * * * * * * * * */
export const fetchSearchLocationDetails = (locationId) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('searchLocation'));
        try {
            const data = await events.get(`geolocations/details?placeid=${locationId}`);
            dispatch(fetchDetailsSuccess('searchLocation', { id: locationId, value: data.formatted_address, ...data.geometry.location }));
        } catch (error) {
            dispatch(fetchDetailsFail('searchLocation', error));
        }
    };
};

/* * * * * * * * * *  *
* RESERVATION DETAILS *
* * * * * * * * * * * */
export const fetchBookingDetails = (bookingId = null, typeBooking  = 'reservation') => {
    return async dispatch => {
        dispatch(fetchDetailsStart('booking'));
        try {
            const data = await events.get(`bookings/${bookingId}?${typeBooking}`);
            dispatch(fetchDetailsSuccess('booking', data));
        } catch (error) {
            dispatch(fetchDetailsFail('booking', error));
        }
    };
};

export const fetchReservationDetails = (reservationId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('reservation'));
        try {
            const data = await events.get(`reservations/${reservationId}`);
            dispatch(fetchDetailsSuccess('reservation', data));
        } catch (error) {
            dispatch(fetchDetailsFail('reservation', error));
        }
    };
};

export const fetchCarReservationDetails = (carReservationId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('carReservation'));
        try {
            const data = await events.get(`carReservations/${carReservationId}`);
            dispatch(fetchDetailsSuccess('carReservation', data));
        } catch (error) {
            dispatch(fetchDetailsFail('carReservation', error));
        }
    };
};

export const fetchProductOrderDetails = (productOrderId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('productOrder'));
        try {
            const data = await events.get(`productOrders/${productOrderId}`);
            dispatch(fetchDetailsSuccess('productOrder', data));
        } catch (error) {
            dispatch(fetchDetailsFail('productOrder', error));
        }
    };
};

/* * * * * * * * * * * * * * * *
 * RESERVATION EXTENSION PRICE *
** * * * * * * * * * * * * * * */
export const fetchReservationPricing = (reservationId = null, newEndDate = {}, paymentOptionId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('reservationPricing'));
        try {
            const data = await events.get(`reservations/${reservationId}/extensionPrice?newEndDate=${newEndDate.toISOString()}${paymentOptionId ? `&paymentOptionId=${paymentOptionId}` : ''}`);
            dispatch(fetchDetailsSuccess('reservationPricing', data));
        } catch (error) {
            dispatch(fetchDetailsFail('reservationPricing', error));
        }
    };
};

export const fetchBookingExtensionPrice = (bookingId = null, newEndDate = null, bookingType = null, paymentOptionId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('bookingExtensionPrice'));
        try {
            const params = [
                `newEndDate=${newEndDate.toISOString()}`,
                `bookingType=${bookingType}`,
            ];

            if (!isNull(paymentOptionId)) {
                params.push(`paymentOptionId=${paymentOptionId}`);
            }

            const data = await events.get(`bookings/${bookingId}/extensionPrice?${params.join('&')}`);
            dispatch(fetchDetailsSuccess('bookingExtensionPrice', data));
        } catch (error) {
            dispatch(fetchDetailsFail('bookingExtensionPrice', error));
        }
    };
};

/* * * * * * *  *
* ORDER DETAILS *
* * * * * * * * */
export const fetchOrderDetails = (orderId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('order'));
        try {
            const data = await events.get(`orders/${orderId}`);
            dispatch(fetchDetailsSuccess('order', data));
        } catch (error) {
            dispatch(fetchDetailsFail('order', error));
        }
    };
};

/* * * * * * * *  *
* TICKET DETAILS  *
* * * * * * * * * */
export const fetchTicketDetails = (ticketId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('ticket'));
        try {
            const data = await events.get(`tickets/${ticketId}`);
            dispatch(fetchDetailsSuccess('ticket', data));
        } catch (error) {
            dispatch(fetchDetailsFail('ticket', error));
        }
    };
};

export const submitTicket = (rentalItemId, ticketType, defectId = null, reservationId = null, historyItems = []) => {
    const ticketBody = {
        itemId: rentalItemId,
        ticketType,
        historyItems,
        ...(ticketType === 'defect' ? { defectId } : {}),
        ...(!isNull(reservationId) && { reservationId })
    };
    return async dispatch => {
        dispatch(fetchDetailsStart('submittedTicket'));
        try {
            const data = await events.post('tickets', ticketBody);
            dispatch(fetchDetailsSuccess('submittedTicket', data));
        } catch (error) {
            dispatch(fetchDetailsFail('submittedTicket', error));
        }
    };
};

/* * * * * * * *
 * RENTAL ITEM *
 * * * * * * * */
export const fetchRentalItem = (serviceId) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('rentalItem'));
        try {
            const data = await events.get(`rentalItems/${serviceId}`);
            dispatch(fetchDetailsSuccess('rentalItem', data));
        } catch (error) {
            dispatch(fetchDetailsFail('rentalItem', error));
        }
    };
};

/* * * * * * * * *  *
* COMMUNITY DETAILS *
* * * * * * * * * * */
export const fetchCommunityDetails = () => {
    return async dispatch => {
        dispatch(fetchDetailsStart('community'));
        try {
            const data = await events.get('community');
            localStorage.removeItem('communityId');
            localStorage.setItem('communityId', data.id);
            if(data.name !== '360') {
                document.title = data.name.slice(0,1).toUpperCase()+data.name.slice(1);
            }
            dispatch(fetchCommunityContactDetails(data.id));
            if (data.companyReference) {
                dispatch(fetchCommunityCompanyDetails(data.companyReference.id));
            }
            dispatch(fetchDetailsSuccess('community', data));
        } catch (error) {
            dispatch(fetchDetailsFail('community', error));
        }

    };
};

export const fetchCommunityContactDetails = (communityId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('communityContact'));
        try {
            const data = await events.get(`communities/${communityId}/contact`);
            dispatch(fetchDetailsSuccess('communityContact', data));
        } catch (error) {
            dispatch(fetchDetailsFail('communityContact', error));
        }

    };
};

export const fetchCommunityCompanyDetails = (companyId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('communityCompany'));
        try {
            const data = await events.get(`companies/${companyId}`);
            dispatch(fetchDetailsSuccess('communityCompany', data));
        } catch (error) {
            dispatch(fetchDetailsFail('communityCompany', error));
        }

    };
};

/* * * * * * *
 * INVOICES  *
 * * * * * * */
export const fetchInvoiceDetails = (invoiceId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('invoice'));
        try {
            const data = await events.get(`invoices/${invoiceId}`);
            dispatch(fetchDetailsSuccess('invoice', data));
        } catch (error) {
            dispatch(fetchDetailsFail('invoice', error));
        }

    };
};

/* * * * * * * *  *
* MEETING METHODS *
* * * * * * * * * */
export const addMeeting = (companyId = null, onBehalfOfUserId = null, title = null, description = null, periodStart = null, periodEnd = null) => {
    const bodyData = {
        ...(companyId && title && periodStart && periodEnd && { companyId, title, periodStart, periodEnd }),
        ...(!isNull(onBehalfOfUserId) && { onBehalfOfUserId }),
        ...(!isNull(description) && { description })
    };
    console.log(bodyData);

    return async dispatch => {
        dispatch(fetchDetailsStart('addedMeeting'));
        try {
            const data = await events.post('meetings', bodyData);
            dispatch(fetchDetailsSuccess('addedMeeting', data));
        } catch (error) {
            dispatch(fetchDetailsFail('addedMeeting', error));
        }
    };
};

export const fetchMeetingDetails = (meetingId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('meeting'));
        try {
            const data = await events.get(`meetings/${meetingId}`);
            dispatch(fetchDetailsSuccess('meeting', data));
        } catch (error) {
            dispatch(fetchDetailsFail('meeting', error));
        }
    };
};

/* * * * * * * * * * *
 * VISITORS METHODS  *
 * * * * * * * * * * */
export const addVisitor = (meetingId = null, name = null, emailAddress = null, mobileNumber = null) => {
    const bodyData = {
        ...( name && emailAddress && {
            name,
            emailAddress,
            ...(!isNull(mobileNumber) && { mobileNumber })
        })
    };

    return async dispatch => {
        dispatch(fetchDetailsStart('addedVisitor'));
        try {
            const data = await events.post(`meetings/${meetingId}/visitors`, bodyData);
            dispatch(fetchDetailsSuccess('addedVisitor', data));
        } catch (error) {
            dispatch(fetchDetailsFail('addedVisitor', error));
        }
    };
};

export const fetchVisitorDetails = (meetingId = null, visitorId = null) => {
    return async dispatch => {
        dispatch(fetchDetailsStart('visitor'));
        try {
            const data = await events.get(`meetings/${meetingId}/visitors/${visitorId}`);
            dispatch(fetchDetailsSuccess('visitor', data));
        } catch (error) {
            dispatch(fetchDetailsFail('visitor', error));
        }
    };
};

/* * * * * * * * * * * * *
 * LICENSE VERIFICATION  *
** * * * * * * * * * * * */
export const fetchDriversLicenseVerifications = () => {
    const identifier = 'driversLicenseVerifications';
    const current = DetailsStatesCounter.increment(identifier);
    return async dispatch => {
        dispatch(fetchDetailsStart(identifier));
        try {
            const data = await events.get('driversLicenseVerifications');
            if (DetailsStatesCounter.isCurrent(identifier, current)) {
                dispatch(fetchDetailsSuccess(identifier, data));
            }
        } catch (error) {
            if (DetailsStatesCounter.isCurrent(identifier, current)) {
                dispatch(fetchDetailsFail(identifier, error));
            }
        }
    };
};
