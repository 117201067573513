import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, isInteger } from '../../../shared/utility';
import Shiitake from 'shiitake';

import Button from '../Button/Button';

import localStyles from './TextClamp.module.scss';
const styles = getStyles(localStyles);

class TextClamp extends Component {
    /* * * * * * * * *
     * REACT METHODS *
     * * * * * * * * */
    constructor(props) {
        super(props);
        this.state = {
            clamped: props.expanded ? false : true,
            maxLines: props.lines && isInteger(props.lines) ? Math.abs(props.lines) : 3,
            showButton: true
        };

        this.refClamp = React.createRef();
        this.refOriginalContent = React.createRef();
    }

    componentDidMount(){
        if(this.props.forceHeight){
            this.forceHeight();
        }
        if(this.props.button){
            this.checkClamping();
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.windowWidth !== this.props.windowWidth || prevProps.windowHeight !== this.props.windowHeight){
            if(this.props.forceHeight){
                this.forceHeight();
            }
            if(this.props.button){
                this.checkClamping();
            }
        }
    }

    getMaxHeight = () => {
        const lineHeight = parseFloat(getComputedStyle(this.refClamp.current).lineHeight);
        return Math.ceil(lineHeight * this.state.maxLines);
    }

    forceHeight = () => {
        this.refClamp.current.style.height = `${this.getMaxHeight()}px`;
    }

    checkClamping = () => {
        if(!this.refOriginalContent.current){
            return;
        }

        const originalContentHeight = this.refOriginalContent.current.getBoundingClientRect().height;

        if(originalContentHeight <= this.getMaxHeight()){
            this.setState({ clamped: false, showButton: false });
        }
    }


    /* * * * * * * * * *
     * EVENT HANDLERS  *
     * * * * * * * * * */
    toggleClamp = () => this.setState({ clamped: !this.state.clamped });

    /* * * * * * * * * *
     * CONTENT METHODS *
     * * * * * * * * * */
    addLineBreaks = (string) => /\n/.test(string) ? (
        <>{string.split('\n').map((line, index) => (<Fragment key={index}>{index?<br/>:null}{line}</Fragment>))}</>
    ) : string;

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const shiitakeAttributes = {
            lines: this.state.maxLines,
            throttleRate: 300,
            className: styles('clamp__shiitake'),
            tagName: (this.props.tagName || (this.props.button ? 'p' : 'span'))
        };

        if(this.props.button){
            shiitakeAttributes.overflowNode = <span className={styles('clamp__gradient')}></span>;
        }

        const content = this.props.button && !this.state.clamped ?
            <p>{this.addLineBreaks(this.props.children)}</p> :
            <Shiitake {...shiitakeAttributes}>{this.props.children}</Shiitake>;

        const originalContent = this.props.button ?
            <p className={styles('clamp__original-content')} ref={this.refOriginalContent}>{this.addLineBreaks(this.props.children)}</p> : null;

        const HtmlTag = this.props.button ? 'div' : 'span';
        const classes = ['clamp']
            .concat(this.props.button && this.state.clamped ? 'clamp--clamped' : null)
            .concat(this.props.classes);

        const button = this.props.button && this.state.showButton ? (
            <Button
                modifiers={['collapsed', 'transparent']}
                classes={styles('c--brand', 'clamp-button')}
                events={{ click: this.toggleClamp }}
            >
                {this.state.clamped ? (this.props.showMoreText || '+ Show More') : (this.props.showLessText || '- Show Less')}
            </Button>
        ) : null;

        return (
            <>
                <HtmlTag
                    ref={this.refClamp}
                    className={styles(classes)}
                >
                    {content}
                    {button}
                    {originalContent}
                </HtmlTag>
            </>
        );
    }
}

TextClamp.propTypes = {
    // properties
    children: PropTypes.node.isRequired,

    classes: PropTypes.string,
    forceHeight: PropTypes.bool,
    lines: PropTypes.number,
    tagName: PropTypes.string,
    button: PropTypes.bool,
    expanded: PropTypes.bool,
    showMoreText: PropTypes.string,
    showLessText: PropTypes.string,

    windowWidth: PropTypes.number.isRequired,
    windowHeight: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        windowWidth: state.global.windowDimensions.width,
        windowHeight: state.global.windowDimensions.height
    };
};

export default connect(mapStateToProps, null)(TextClamp);
