import { FETCH_CONDITION_START, FETCH_CONDITION_SUCCESS, FETCH_CONDITION_FAIL } from './actionTypes';
import events from './eventServices';
import { fetchDetailsSuccess, fetchUserDetails, fetchCartDetails, fetchCartPricing } from './details';
import { fetchListSuccess, fetchCartPaymentOptions } from './list';
import { setGlobalState, updateRedirect } from './global';
import { isNull, isInteger, isObject, isFullArray, isFullString } from '../../shared/utility';
import { isDate } from '../../shared/datetime';

/* * * * * * * * * * * * * * *
* ACTIVE ACTION TYPE METHODS *
* * * * * * * * * * * * * *  */
// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchConditionStart = (identifier) => {
    return {
        type: FETCH_CONDITION_START,
        identifier: identifier
    };
};

export const fetchConditionSuccess = (identifier, value = true) => {
    return {
        type: FETCH_CONDITION_SUCCESS,
        identifier: identifier,
        value: value
    };
};

export const fetchConditionFail = (identifier, error = 'Error message missing. Please contact site administrator.') => {
    return {
        type: FETCH_CONDITION_FAIL,
        identifier: identifier,
        error: error
    };
};

/* * * * * * * * * * * *  *
* USER ACCESSIBLE METHODS *
* * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request


/* * * * * * * *
* USER METHODS *
* * * * * * *  */
// Create new user
export const signup = (name = null, emailAddress = null, communityId = null, url = null) => {
    const bodyData = {
        ...(name && emailAddress && url && { name: name, emailAddress: emailAddress, communityId: communityId, redirectUri: `${url}/verification/start`})
    };

    return async dispatch => {
        dispatch(fetchConditionStart('signup'));
        try {
            await events.post('users', bodyData);
            dispatch(fetchConditionSuccess('signup', true));
        } catch (error) {
            dispatch(fetchConditionFail('signup', error));
        }
    };
};

// Create new user
export const signupVerified = (name = null, emailAddress = null, password = null, communityId = null, phoneNumber = null, address = null) => {
    const bodyData = {
        ...(name && emailAddress && isObject(address) && {
            name,
            emailAddress,
            password,
            communityId,
            ...(!isNull(phoneNumber) && { mobileNumber: phoneNumber }),
            address
        })
    };

    return async dispatch => {
        dispatch(fetchConditionStart('signupVerified'));
        try {
            await events.post('users/verified', bodyData);

            dispatch(fetchUserDetails(emailAddress, password));
            dispatch(fetchConditionSuccess('signupVerified', true));
        } catch (error) {
            dispatch(fetchConditionFail('signupVerified', error));
        }
    };
};

// User email methods
export const startEmailVerification = (emailAddress = null, communityId = null, redirectUrl = null) => {
    const bodyData = {
        ...(emailAddress && communityId && redirectUrl && { emailAddress: emailAddress, communityId: communityId, redirectUri: `${redirectUrl}/verification/confirm`})
    };

    return async dispatch => {
        dispatch(fetchConditionStart('startEmailVerification'));
        try {
            await events.post('emailVerification/start', bodyData);
            dispatch(fetchConditionSuccess('startEmailVerification', true));
        } catch (error) {
            dispatch(fetchConditionFail('startEmailVerification', error));
        }

    };
};

export const confirmEmailVerification = (guidId = null, emailAddress = null, code = null) => {
    const bodyData = {
        ...(!isNull(guidId) && emailAddress && code && { userId: guidId, emailAddress: emailAddress, code: code})
    };

    return async dispatch => {
        dispatch(fetchConditionStart('confirmEmailVerification'));
        try {
            await events.post('emailVerification/confirm', bodyData);
            dispatch(fetchConditionSuccess('confirmEmailVerification', true));
        } catch (error) {
            dispatch(fetchConditionFail('confirmEmailVerification', error));
        }
    };
};

export const updateEmailAddress = (userId = null, emailAddress = null, communityId	 = null, redirectUrl = null) => {
    const bodyData = {
        ...(emailAddress && communityId && redirectUrl && { emailAddress: emailAddress, communityId	: communityId, redirectUri: redirectUrl})
    };

    return async dispatch => {
        dispatch(fetchConditionStart('updateEmailAddress'));
        try {
            await events.post(`users/${userId}/emailAddress`, bodyData);
            dispatch(fetchConditionSuccess('updateEmailAddress', true));
        } catch (error) {
            dispatch(fetchConditionFail('updateEmailAddress', error));
        }
    };
};

// User password methods
export const startPasswordReset = (emailAddress = null, communityId = null, redirectUrl = null) => {
    const bodyData = {
        ...(emailAddress && redirectUrl && { emailAddress: emailAddress, communityId: communityId, redirectUri: `${redirectUrl}/confirm-reset`})
    };

    return async dispatch => {
        dispatch(fetchConditionStart('startPasswordReset'));
        try {
            await events.post('passwordReset/start', bodyData);
            dispatch(fetchConditionSuccess('startPasswordReset', true));
        } catch (error) {
            dispatch(fetchConditionFail('startPasswordReset', error));
        }
    };
};

export const confirmPasswordReset = (guidId = null, userName = null, code = null, password = null) => {
    const bodyData = {
        ...(!isNull(guidId) && code && password && { userId: guidId, code: code, password: password})
    };

    return async dispatch => {
        dispatch(fetchConditionStart('confirmPasswordReset'));
        try {
            await events.post('passwordReset/confirm', bodyData);
            dispatch(fetchConditionSuccess('confirmPasswordReset', true));
            if(userName) {
                dispatch(fetchUserDetails(userName, password));
            } else {
                dispatch(updateRedirect('/verification/confirm', `?passwordUpdated=${true}`));
            }
        } catch (error) {
            dispatch(fetchConditionFail('confirmPasswordReset', error));
        }
    };
};

export const updateUserPassword = (userId = null, oldPassword = null, newPassword = null) => {
    const bodyData = {
        ...(oldPassword && newPassword && { oldPassword: oldPassword, newPassword: newPassword})
    };

    return async dispatch => {
        dispatch(fetchConditionStart('updateUserPassword'));
        try {
            await events.put(`users/${userId}/password`, bodyData);
            dispatch(fetchConditionSuccess('updateUserPassword', true));
        } catch (error) {
            dispatch(fetchConditionFail('updateUserPassword', error));
        }
    };
};

// User logout
export const logout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('paymentAccountId');
    clearTimeout(window.tokenTimeoutId);

    if(window.gapi) {
        const auth2 = window.gapi.auth2.getAuthInstance();
        auth2.signOut();
    }
    return async dispatch => {
        dispatch(fetchConditionStart('logout'));
        try {
            await events.post('logout');
            dispatch(fetchConditionSuccess('logout', true));
        } catch (error) {
            dispatch(fetchConditionFail('logout', error));
        }
    };
};

// User validation
export const checkTokenTimeout = (delaySeconds = 10) => {
    return dispatch => {
        clearTimeout(window.tokenTimeoutId);
        window.tokenTimeoutId = setTimeout(() => {
            dispatch(checkTokenValidation());
        }, delaySeconds * 1000);
    };
};

export const checkTokenValidation = () => {
    return async dispatch => {
        try {
            const user = await events.get('heartbeat');

            user && user.is_logged_in ? dispatch(checkTokenTimeout()) : dispatch(logout());
        } catch (error) {
            // dispatch(logout());
        }
    };
};

const getUserData = () => localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null;

export const checkUserDataValidation = () => {
    return dispatch => {
        const userData = getUserData();

        dispatch(fetchDetailsSuccess('user', userData));
        if (userData) {
            dispatch(checkTokenValidation());
        }
    };
};

// Update user data methods
export const updateUserPreferences = (userId = null, properties = null) => {
    const bodyData = isInteger(userId) && isObject(properties) ? Object.entries(properties).map((prop) => ({ value: prop[1], path: `preferences/${prop[0]}`, op: 'replace' })) : {};

    return async dispatch => {
        dispatch(fetchConditionStart('updateUserPreference'));
        try {
            const userData = await events.patch(`users/${userId}`, bodyData);
            localStorage.setItem('userData', JSON.stringify(userData));
            dispatch(fetchDetailsSuccess('user', userData));
            dispatch(fetchConditionSuccess('updateUserPreference', true));
        } catch (error) {
            dispatch(fetchConditionFail('updateUserPreference', error));
        }
    };
};

export const updateUserDetails = (userId = null, bodyData = {}) => {
    return async dispatch => {
        dispatch(fetchConditionStart('updateUserDetails'));
        try {
            const userData = await events.put(`users/${userId}`, bodyData);
            localStorage.setItem('userData', JSON.stringify(userData));
            dispatch(fetchDetailsSuccess('user', userData));
            dispatch(fetchConditionSuccess('updateUserDetails', true));
        } catch (error) {
            dispatch(fetchConditionFail('updateUserDetails', error));
        }
    };
};

/* * * * * * * *
* CART METHODS *
* * * * * * *  */
export const addCartItem = (itemId = null, paymentAccountId = null, period = null, properties = {}) => {
    // comment, reference and operating company are optional
    const bodyData = {
        ...(isInteger(itemId) && isObject(period) && isDate(period.start) && isDate(period.end) && {
            itemId: itemId,
            ...{ periodStart: period.start.toISOString(), periodEnd: period.end.toISOString() },
            ...{
                numberOfPeople: isInteger(properties.numberOfPeople) ? properties.numberOfPeople : 1,
                quantity: isInteger(properties.quantity) ? properties.quantity : 1,
                ...(!!properties.reference && { reference: properties.reference }),
                ...(!!properties.comment && { comment: properties.comment }),
                ...(!!properties.operatingCompany && { operatingCompany: properties.operatingCompany })
            }
        })};

    return async dispatch => {
        dispatch(fetchConditionStart('addCartItem'));
        try {
            await events.post(`paymentAccounts/${paymentAccountId}/cart/items`, bodyData);
            dispatch(fetchCartDetails(paymentAccountId, null));
            dispatch(fetchConditionSuccess('addCartItem', true));
        } catch (error) {
            dispatch(fetchConditionFail('addCartItem', error));
        }
    };
};

export const addCartService = (paymentAccountId = null, selectedDate = null, selectedProducts = [], deliveryLocation) => {
    // comment, reference and operating company are not supported yet
    const bodyData = {
        ...(isDate(selectedDate) && isFullArray(selectedProducts) && {
            serviceDate: selectedDate,
            products: selectedProducts,
            ...(isFullString(deliveryLocation) && { deliveryLocation })
        })
    };

    return async dispatch => {
        dispatch(fetchConditionStart('addCartService'));
        try {
            await events.post(`paymentAccounts/${paymentAccountId}/cart/services`, bodyData);
            dispatch(fetchCartDetails(paymentAccountId, null));
            dispatch(fetchConditionSuccess('addCartService', true));
        } catch (error) {
            dispatch(fetchConditionFail('addCartService', error));
        }
    };
};

export const updateCartItem = (paymentAccountId = null, cartItemId = null, properties = null, cartData = {}) => {
    const bodyData = isInteger(cartItemId) && isObject(properties) ? Object.entries(properties).map((prop) => ({ value: prop[1], path: prop[0], op: 'replace' })) : {};

    return async dispatch => {
        dispatch(setGlobalState('activeCartItem', cartItemId));
        dispatch(fetchConditionStart('updateCartItem'));
        try {
            const data = await events.patch(`paymentAccounts/${paymentAccountId}/cart/items/${cartItemId}`, bodyData);
            const updatedCartItems = cartData.cartItems.map(item => item.id === cartItemId ? data : item);
            const updatedCartData = { ...cartData, ...{ cartItems: updatedCartItems } };
            dispatch(fetchDetailsSuccess('cart', updatedCartData));
            dispatch(fetchConditionSuccess('updateCartItem', true));
            dispatch(setGlobalState('activeCartItem', null));
        } catch (error) {
            dispatch(fetchConditionFail('updateCartItem', error));
            dispatch(setGlobalState('activeCartItem', null));
        }
    };
};

export const updateCartProduct = (paymentAccountId = null, cartItemId = null, cartProductId = null, cartProductQuantity = null, cartData = {}) => {
    const bodyData = isInteger(cartProductQuantity) ? {
        value: cartProductQuantity
    } : {};

    return async dispatch => {
        dispatch(setGlobalState('activeCartItem', cartItemId));
        dispatch(setGlobalState('activeCartProduct', cartProductId));
        dispatch(fetchConditionStart('updateCartProduct'));
        try {
            const data = await events.put(`paymentAccounts/${paymentAccountId}/cart/items/${cartItemId}/products/${cartProductId}`, bodyData);
            dispatch(fetchCartPaymentOptions(paymentAccountId));
            const updatedCartItems = cartData.cartItems.map(item => item.id === cartItemId ? data : item);
            const updatedCartData = { ...cartData, ...{ cartItems: updatedCartItems } };
            dispatch(fetchDetailsSuccess('cart', updatedCartData));
            dispatch(fetchConditionSuccess('updateCartProduct', true));
            dispatch(setGlobalState('activeCartItem', null));
            dispatch(setGlobalState('activeCartProduct', null));
        } catch (error) {
            dispatch(fetchConditionFail('updateCartProduct', error));
            dispatch(setGlobalState('activeCartItem', null));
            dispatch(setGlobalState('activeCartProduct', null));
        }
    };
};

export const removeCartItem = (paymentAccountId = null, cartItemId = null, cartData) => {
    return async dispatch => {
        dispatch(setGlobalState('activeCartItem', cartItemId));
        dispatch(fetchConditionStart('removeCartItem'));
        try {
            await events.delete(`paymentAccounts/${paymentAccountId}/cart/items/${cartItemId}`);
            const updatedCartItems = cartData.cartItems.filter(item => item.id !== cartItemId);
            const updatedCartData = { ...cartData, ...{ cartItems: updatedCartItems } };
            dispatch(fetchDetailsSuccess('cart', updatedCartData));
            if (isFullArray(updatedCartItems)) {
                dispatch(fetchCartPaymentOptions(paymentAccountId));
            } else {
                dispatch(fetchListSuccess('paymentOptions', null));
                dispatch(fetchDetailsSuccess('cartPricing', null));
            }

            dispatch(fetchConditionSuccess('removeCartItem', true));
            dispatch(setGlobalState('activeCartItem', null));
        } catch (error) {
            dispatch(fetchConditionFail('removeCartItem', error));
            dispatch(setGlobalState('activeCartItem', null));
        }
    };
};

export const removeCartProduct = (paymentAccountId = null, cartItemId = null, cartProductId = null, cartData) => {
    return async dispatch => {
        dispatch(setGlobalState('activeCartItem', cartItemId));
        dispatch(fetchConditionStart('removeCartProduct'));
        try {
            const data = await events.delete(`paymentAccounts/${paymentAccountId}/cart/items/${cartItemId}/products/${cartProductId}`);
            const updatedCartItems = cartData.cartItems.map(item => (
                item.id === cartItemId ? ({
                    ...item, ...{
                        products: item.products.filter(product => product.productReference.id !== cartProductId),
                        priceInformation: { ...data.priceInformation }
                    }
                }) : item)
            );
            const updatedCartData = { ...cartData, ...{ cartItems: updatedCartItems } };
            dispatch(fetchDetailsSuccess('cart', updatedCartData));
            dispatch(fetchCartPaymentOptions(paymentAccountId));

            dispatch(fetchConditionSuccess('removeCartProduct', true));
            dispatch(setGlobalState('activeCartItem', null));
        } catch (error) {
            dispatch(fetchConditionFail('removeCartProduct', error));
            dispatch(setGlobalState('activeCartItem', null));
        }
    };
};

export const removeVoucher = (paymentAccountId = null, cartItemId = null, paymentOptionId = null, cartData) => {
    return async dispatch => {
        dispatch(setGlobalState('activeCartItem', cartItemId));
        dispatch(fetchConditionStart('removeVoucher'));
        try {
            await events.delete(`paymentAccounts/${paymentAccountId}/cart/items/${cartItemId}/voucher`);
            const updatedCartItems = cartData.cartItems.map(item => {
                if(item.id === cartItemId) {
                    const { voucher, ...newItem } = item;
                    return newItem;
                } else {
                    return item;
                }
            });
            const updatedCartData = { ...cartData, ...{ cartItems: updatedCartItems } };
            dispatch(fetchDetailsSuccess('cart', updatedCartData));
            dispatch(fetchCartPricing(paymentAccountId, paymentOptionId));
            dispatch(fetchConditionSuccess('removeVoucher', true));
            dispatch(setGlobalState('activeCartItem', null));
        } catch (error) {
            dispatch(fetchConditionFail('removeVoucher', error));
            dispatch(setGlobalState('activeCartItem', null));
        }
    };
};

export const clearCart = (paymentAccountId = null, paymentOptionId = null) => {
    return async dispatch => {
        dispatch(fetchConditionStart('clearCart'));
        try {
            await events.delete(`paymentAccounts/${paymentAccountId}/cart`);
            dispatch(fetchCartDetails(paymentAccountId, paymentOptionId));
            dispatch(fetchConditionSuccess('clearCart', true));
        } catch (error) {
            dispatch(fetchConditionFail('clearCart', error));
        }
    };
};

export const checkoutCart = (paymentAccountId = null, paymentOptionId = null, principalId = null, redirectUrl = null) => {
    // optional object properties
    const bodyData = {
        ...(!isNull(paymentOptionId) && !isNull(redirectUrl) && {paymentOptionId: paymentOptionId, paymentRedirectUri: `${redirectUrl}/cart/checkout`}),
        ...(!isNull(principalId) && {onBehalfOfUserId: principalId})
    };

    return async dispatch => {
        dispatch(fetchConditionStart('checkoutCart'));
        try {
            const data = await events.post(`paymentAccounts/${paymentAccountId}/cart/checkout`, bodyData);
            if(data.needsPayment) {
                window.location = data.paymentLink;
            } else {
                dispatch(updateRedirect('/cart/checkout', `?resultKey=Confirmed&resultOrderId=${data.orders[0].id}`));
            }
            dispatch(fetchConditionSuccess('checkoutCart', true));
        } catch (error) {
            dispatch(fetchConditionFail('checkoutCart', error));
        }
    };
};

/* * * * * * * * * * *
 * CHECKOUT METHODS  *
 * * * * * * * * * * */
export const checkoutItem = (itemId, paymentAccountId, periods, paymentRedirectUri, paymentOptionId = null, onBehalfOfUserId = null, title, reference, comment, deliveryLocation, operatingCompany, numberOfPeople = null, predefinedSetupOptionId = null) => {
    const bodyData = {
        paymentAccountId,
        periods: periods
            .filter(({periodStart, periodEnd}) => isDate([new Date(periodStart), new Date(periodEnd)]))
            .map(({periodStart, periodEnd}) => ({ periodStart, periodEnd })),
        ...(!isNull(paymentRedirectUri) && !isNull(paymentOptionId) && { paymentRedirectUri: `${paymentRedirectUri}/checkout/${itemId}/confirmation` }),
        ...(!isNull(paymentOptionId) && { paymentOptionId }),
        ...(!isNull(onBehalfOfUserId) && { onBehalfOfUserId }),
        ...(isFullString(title) && { title }),
        ...(isFullString(reference) && { reference }),
        ...(isFullString(comment) && { comment }),
        ...(isFullString(deliveryLocation) && { deliveryLocation }),
        ...(isFullString(operatingCompany) && { operatingCompany }),
        ...(isInteger(numberOfPeople) && { numberOfPeople }),
        ...(isInteger(predefinedSetupOptionId) && { predefinedSetupOptionId })
    };
    return async dispatch => {
        dispatch(fetchConditionStart('checkoutItem'));
        try {
            const data = await events.post(`items/${itemId}/checkout`, bodyData);
            if (data.checkoutResult.needsPayment) {
                window.location = data.checkoutResult.paymentLink;
            } else {
                dispatch(updateRedirect(`/checkout/${itemId}/confirmation`, `?resultKey=Confirmed&resultOrderId=${data.checkoutResult.orders[0].id}`));
            }
            dispatch(fetchConditionSuccess('checkoutItem', true));
        } catch (error) {
            dispatch(fetchConditionFail('checkoutItem', error));
        }
    };
};

/* * * * * * * * * * * *
* RESERVATIONS METHODS *
* * * * * * * * * * *  */
export const cancelReservation = (reservationId = null) => {
    return async dispatch => {
        dispatch(fetchConditionStart('cancelReservation'));
        try {
            const data = await events.post(`reservations/${reservationId}/cancel`);
            dispatch(fetchDetailsSuccess('reservation', data));
            dispatch(fetchConditionSuccess('cancelReservation', true));
        } catch (error) {
            dispatch(fetchConditionFail('cancelReservation', error));
        }
    };
};

export const extendReservation = (reservationId = null, paymentOptionId = null, redirectUrl = null, newEndDate = null) => {
    const bodyData = {
        ...(newEndDate && { newEndDate: newEndDate }),
        ...(paymentOptionId && redirectUrl && { paymentOptionId: paymentOptionId, paymentRedirectUri: `${redirectUrl}/extendReservation` })
    };

    return async dispatch => {
        dispatch(fetchConditionStart('extendReservation'));
        try {
            const data = await events.post(`reservations/${reservationId}/extend`, bodyData);
            if (data.needsPayment) {
                window.location = data.paymentLink;
            } else {
                dispatch(updateRedirect('/extendReservation', `?resultKey=${'Confirmed'}&resultOrderId=${data.orders[0].id}`));
            }
            dispatch(fetchConditionSuccess('extendReservation', true));
        } catch (error) {
            dispatch(fetchConditionFail('extendReservation', error));
        }
    };
};

export const extendBooking = (bookingId = null, bookingType = null, newEndDate = null, paymentOptionId = null, paymentRedirectUri = null) => {
    const bodyData = {
        newEndDate: newEndDate.toISOString(),
        bookingType,
        ...(!isNull(paymentOptionId) && !isNull(paymentRedirectUri) && { paymentOptionId, paymentRedirectUri })
    };

    return async dispatch => {
        dispatch(fetchConditionStart('extendBooking'));
        try {
            const data = await events.post(`bookings/${bookingId}/extend`, bodyData);
            if (data.needsPayment) {
                window.location = data.paymentLink; // redirect to psp
            } else {
                dispatch(updateRedirect(window.location.pathname.replace(/(\/restore)?(\/)?$/, '') + '/confirmation', `?resultKey=Confirmed&resultOrderId=${data.orders[0].id}`));
            }
            dispatch(fetchConditionSuccess('extendBooking', true));
        } catch (error) {
            dispatch(fetchConditionFail('extendBooking', error));
        }
    };
};

export const cancelCarReservation = (reservationId = null) => {
    return async dispatch => {
        dispatch(fetchConditionStart('cancelCarReservation'));
        try {
            const data = await events.post(`carReservations/${reservationId}/cancel`);
            dispatch(fetchDetailsSuccess('carReservation', data));
            dispatch(fetchConditionSuccess('cancelCarReservation', true));
        } catch (error) {
            dispatch(fetchConditionFail('cancelCarReservation', error));
        }
    };
};

export const cancelProductOrder = (productOrderId = null) => {
    return async dispatch => {
        dispatch(fetchConditionStart('cancelProductOrder'));
        try {
            const data = await events.post(`productOrders/${productOrderId}/cancel`);
            dispatch(fetchDetailsSuccess('productOrder', data));
            dispatch(fetchConditionSuccess('cancelProductOrder', true));
        } catch (error) {
            dispatch(fetchConditionFail('cancelProductOrder', error));
        }
    };
};

/* * * * * * * * * * *
 * PAYMENT ACCOUNTS  *
 * * * * * * * * * * */
export const linkPaymentAccount = (companyCode = {}, paymentAccounts = []) => {
    const codeBody = { value: companyCode };
    return async dispatch => {
        dispatch(fetchConditionStart('linkPaymentAccount'));
        try {
            const result = await events.post('paymentAccounts/linkCode', codeBody);
            dispatch(fetchListSuccess('paymentAccounts', [...paymentAccounts, result]));
            dispatch(fetchConditionSuccess('linkPaymentAccount', true));
        }catch(error){
            dispatch(fetchConditionFail('linkPaymentAccount', error));
        }
    };
};

/* * * * * * * * * * * * * * * *
* DIRECT DEBIT PAYMENT METHODS *
* * * * * * * * * * * * * * *  */
export const addDirectDebit = (paymentMethodId = null, redirectUrl = null, description = null) => {
    const bodyData = {
        ...(paymentMethodId && redirectUrl && description && { paymentMethodId: paymentMethodId, description: description, redirectUri: redirectUrl })
    };

    return async dispatch => {
        dispatch(fetchConditionStart('addDirectDebit'));
        try {
            const data = await events.post('directDebitPaymentMethods', bodyData);
            window.location = data.paymentLink;
            dispatch(fetchConditionSuccess('addDirectDebit', true));
        } catch (error) {
            dispatch(fetchConditionFail('addDirectDebit', error));
        }
    };
};
export const updateDirectDebit = (paymentMethodId = null, description = null, directDebits = []) => {
    const bodyData = {
        ...(description && { description: description })
    };

    return async dispatch => {
        dispatch(fetchConditionStart('updateDirectDebit'));
        try {
            const data = await events.put(`directDebitPaymentMethods/${paymentMethodId}`, bodyData);
            const updatedDirectDebits = directDebits.map(debit => debit.id === data.id ? data : debit);
            dispatch(fetchListSuccess('directDebits', updatedDirectDebits));
            dispatch(fetchConditionSuccess('updateDirectDebit', true));
        } catch (error) {
            dispatch(fetchConditionFail('updateDirectDebit', error));
        }
    };
};

export const deleteDirectDebit = (paymentMethodId = null) => {
    return async dispatch => {
        dispatch(fetchConditionStart('deleteDirectDebit'));
        try {
            await events.delete(`directDebitPaymentMethods/${paymentMethodId}`);
            dispatch(fetchConditionSuccess('deleteDirectDebit', true));
        } catch (error) {
            dispatch(fetchConditionFail('deleteDirectDebit', error));
        }
    };
};

/* * * * * * * * * * * * *
 * MEETINGS AND VISITORS *
 * * * * * * * * * * * * */
export const deleteMeeting = (meetingId = null) => {
    return async dispatch => {
        dispatch(fetchConditionStart('deleteMeeting'));
        try {
            await events.delete(`meetings/${meetingId}`);
            dispatch(fetchConditionSuccess('deleteMeeting', true));
        } catch (error) {
            dispatch(fetchConditionFail('deleteMeeting', error));
        }
    };
};

export const deleteVisitor = (meetingId = null, visitorId = null) => {
    return async dispatch => {
        dispatch(fetchConditionStart('deleteVisitor'));
        try {
            await events.delete(`meetings/${meetingId}/visitors/${visitorId}`);
            dispatch(fetchConditionSuccess('deleteVisitor', true));
        } catch (error) {
            dispatch(fetchConditionFail('deleteVisitor', error));
        }
    };
};

export const updateMeetingDetails = (meetingId = null, bodyData = {}) => {
    return async dispatch => {
        dispatch(fetchConditionStart('updateMeetingDetails'));
        try {
            const meetingData = await events.put(`meetings/${meetingId}`, bodyData);
            dispatch(fetchDetailsSuccess('meeting', meetingData));
            dispatch(fetchConditionSuccess('updateMeetingDetails', true));
            dispatch(updateRedirect(`/visits/`));
        } catch (error) {
            dispatch(fetchConditionFail('updateMeetingDetails', error));
        }
    };
};

export const updateVisitorDetails = (meetingId = null, visitorId = null, bodyData = {}) => {
    return async dispatch => {
        dispatch(fetchConditionStart('updateVisitorDetails'));
        try {
            const visitorData = await events.put(`meetings/${meetingId}/visitors/${visitorId}`, bodyData);
            dispatch(fetchDetailsSuccess('visitor', visitorData));
            dispatch(fetchConditionSuccess('updateVisitorDetails', true));
            dispatch(updateRedirect(`/visits/${meetingId}`));
        } catch (error) {
            dispatch(fetchConditionFail('updateVisitorDetails', error));
        }
    };
};

export const patchUpdateVisitorDetails = (meetingId = null, visitorId = null, properties = null) => {
    const bodyData = isObject(properties) ? Object.entries(properties).map((prop) => ({ value: prop[1], path: prop[0], op: 'replace' })) : {};

    return async dispatch => {
        dispatch(fetchConditionStart('patchUpdateVisitorDetails'));
        try {
            const visitorData = await events.patch(`meetings/${meetingId}/visitors/${visitorId}`, bodyData);
            dispatch(fetchDetailsSuccess('visitor', visitorData));
            dispatch(fetchConditionSuccess('patchUpdateVisitorDetails', true));
        } catch (error) {
            dispatch(fetchConditionFail('patchUpdateVisitorDetails', error));
        }
    };
};