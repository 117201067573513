import React from 'react';
import PropTypes from 'prop-types';
import { getStyles } from '../../../../shared/utility';

import localStyles from './Backdrop.module.scss';
const styles = getStyles(localStyles);

const backdrop = (props) => (
    <div className={styles('backdrop', (props.isOpen ? 'backdrop--open' : null))} onClick={props.click} />
);

backdrop.propTypes = {
    // properties
    isOpen: PropTypes.bool.isRequired,

    // functions
    click: PropTypes.func
};

export default backdrop;
