import { isString, isFullString, isFullArray, isArray } from './utility';


/* * * * * * * * *
 * LOCALIZATION  *
 * * * * * * * * */
export const localizeDateTime = (dateTime, locale = 'nl-NL', opts = {}) => {
    const options = {
        // timeZone: 'Europe/Amsterdam',
        ...opts
    };
    const date = new Date(dateTime);
    const dateObject = !isNaN(date) ? date : Date.now();
    return Intl ? Intl.DateTimeFormat(locale, options).format(dateObject) : dateObject.toLocaleDateString(locale, options);
};

/* * * * * * * * * *
 * DATE UTILITIES  *
 * * * * * * * * * */
export const isDate = (value) => (isArray(value) ? value : [ value ]).every((val) => (val instanceof Date && !isNaN(val)));

export const isDateToday = (date, currentDate = new Date()) => (isDate([ date, currentDate ]) && sameDates(unsetTime(date), unsetTime(currentDate)));

export const getDateObject = (value) => isDate(value) ? value : isFullString(value) ? (new Date(value)) : null;

export const getLastDayOfMonth = (year, month) => {
    const date = new Date(year, month + 1, 0);
    return date.getDate();
};

export const sameDates = (firstDate, secondDate, compareTimesOnly = false) => (
    isDate([ firstDate, secondDate ]) ?
        compareTimesOnly ?
            timeToMS(firstDate) === timeToMS(secondDate) :
            firstDate.getTime() === secondDate.getTime() :
        firstDate === secondDate
);

export const differentDates = (firstDate, secondDate, compareTimesOnly = false) => !sameDates(firstDate, secondDate, compareTimesOnly);

export const isEarlierThan = (date, compare = new Date()) => isDate([ date, compare ]) ? (date.getTime() < compare.getTime()) : false;
export const isLaterThan = (date, compare = new Date()) => isDate([ date, compare ]) ? (date.getTime() > compare.getTime()) : false;

export const isEarlierTime = (date, compare = new Date()) => isDate([ date, compare ]) ? (date.getHours() < compare.getHours() || (date.getHours() === compare.getHours() && date.getMinutes() < compare.getMinutes())) : false;

export const getDateRoundedToDuration = (date, ISODuration, roundUp = false, roundDown = false) => {
    //if the compare date is a few months back as it was before, the difference between
    //the current date and the compare date does not account for Summer/Winter Time
    let compareDate = modifyDate(date, { hours: '-1', minutes: 0 });
    const duration = durationToMS(ISODuration);
    const diff = Math.ceil((date - compareDate) / duration) + (roundUp ? 1 : 0) - (roundDown ? 1 : 0);
    return modifyDate(compareDate, { milliseconds: diff * duration });
};

export const duration = (firstDate, secondDate) => (isDate([ firstDate, secondDate ]) ? Math.abs(modifyDate(firstDate).getTime() - modifyDate(secondDate).getTime()) : 0);

export const unsetTime = (date) => (isDate(date) ? modifyDate(new Date(date), { hours: 0, minutes: 0 }) : date);

export const sortDates = (firstDate, secondDate, desc = false) => {
    firstDate = getDateObject(firstDate);
    secondDate = getDateObject(secondDate);

    const result = Math.sign(firstDate.getTime() - secondDate.getTime());
    return !desc ? result : -result;
};

export const modifyDate = (date, components = {}, resetSeconds = true) => {
    if (isDate(date)) {
        const newDate = new Date(date);
        if (isFullArray(Object.keys(components))) {
            for (let key in components) {
                if (![ 'fullYear', 'month', 'date', 'hours', 'minutes', 'seconds', 'milliseconds' ].includes(key)) {
                    continue;
                }

                const value = components[key];
                const prop = `${key.slice(0, 1).toUpperCase()}${key.slice(1)}`;

                const newValue = isDate(value) ? value[`get${prop}`]() :
                    isString(value) && [ '-', '+' ].includes(value.substr(0, 1)) ?
                        value.substr(0, 1) === '+' ?
                            newDate[`get${prop}`]() + parseInt(value.substr(1), 10) :
                            newDate[`get${prop}`]() - parseInt(value.substr(1), 10) :
                        parseInt(value, 10);

                newDate[`set${prop}`](newValue);
            }
        }
        if (resetSeconds) {
            newDate.setSeconds(0);
            newDate.setMilliseconds(0);
        }
        return newDate;
    }

    return date;
};

export const timeToMS = (date) => isDate(date) ? (date.getTime() - unsetTime(date).getTime()) : 0;

export const durationToMS = (ISODuration) => {
    if (!isFullString(ISODuration)) {
        return ISODuration;
    }

    // pattern to match ISO 8601 Durations
    // https://en.wikipedia.org/wiki/ISO_8601#Durations
    // in the spec the order of designators doesnt' matter except for the `T` designator
    // in this implementation designators should come in order from large (`Y`) to small (`S`)
    const pattern = /^P(?!$)(((\d+)Y)?((\d+)M)?((\d+)D)?(T((\d+)H)?((\d+)M)?((\d+)S)?)?)$/;

    const matches = ISODuration.match(pattern);

    const diff = {
        years: parseInt(matches[3], 10) || 0,
        months: parseInt(matches[5], 10) || 0,
        days: parseInt(matches[7], 10) || 0,
        hours: parseInt(matches[10], 10) || 0,
        minutes: parseInt(matches[12], 10) || 0,
        seconds: parseInt(matches[14], 10) || 0
    };

    const compareDate = new Date();
    compareDate.setMilliseconds(0);
    const newDate = new Date(
        compareDate.getFullYear() + diff.years,
        compareDate.getMonth() + diff.months,
        compareDate.getDate() + diff.days,
        compareDate.getHours() + diff.hours,
        compareDate.getMinutes() + diff.minutes,
        compareDate.getSeconds() + diff.seconds,
        0
    );

    return (newDate - compareDate);
};
