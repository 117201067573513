import React, { useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, createUniqueIdentifier, translate, addModifierClasses, addToArray, getEventAttributes, isObject } from '../../../shared/utility';

import Button from '../Button/Button';
import { Dots } from '../Loading/Loading';

import CharacterCounter from './CharacterCounter/CharacterCounter';
import Dropdown from './Dropdown/Dropdown';

import localStyles from './Input.module.scss';
const styles = getStyles(localStyles);

const Input = React.memo((props) => {
    // destruct props
    const {
        inputReference, id, name,
        value, type, placeholder, maxLength, attrs = {},
        events = {}, modifiers = [], classes = '',
        invalid, touched, loading,
        readonly, required, disabled, checked,
        description, error,
        shouldValidate = {},
        addCounter,
        children, label, labelClasses, labelContentClasses,

        // dropdown (select) props
        options, multiple, max, collapsed,
        placeholderIcon, placeholderCustomIcon,

        language
    } = props;

    /* * * * * * * * * *
     * INPUT REFERENCE *
    ** * * * * * * * * */
    const inputRef = isObject(inputReference) ? inputReference : useRef();
    const [inputHasFocus, setInputHasFocus] = useState(inputRef.current === document.activeElement);

    useEffect(() => {
        const inputEl = inputRef.current;
        if (inputEl) {
            inputEl.onfocus = () => { setInputHasFocus(true); };
            inputEl.onblur = () => { setInputHasFocus(false); };
        }
        return () => {
            if (inputEl) {
                inputEl.onfocus = null;
                inputEl.onblur = null;
            }
        };
    }, [inputRef]);

    /* * * * * * * * * * *
     * INPUT PROPERTIES  *
    ** * * * * * * * * * */
    const showInvalidState = !inputHasFocus && touched && (invalid || error);

    const inputType = type || attrs.type || 'text';
    const isToggleInput = inputType === 'radio' || inputType === 'checkbox';

    let inputClasses = [];
    if (!isToggleInput) {
        const mods = modifiers
            .concat(showInvalidState ? 'invalid' : [])
            .concat(inputType === 'textarea' ? 'textarea' : [])
            .concat(inputType === 'select' ? ['stretch', disabled && 'disabled'] : []);

        inputClasses = addModifierClasses(mods, 'input', ['input']);
        inputClasses = addToArray([classes], inputClasses);
    }

    // immutable id
    const [input_id] = useState(id || (attrs && attrs.id ? attrs.id : createUniqueIdentifier('input', 10)));

    const evtAttrs = getEventAttributes(events, {});

    const properties = {
        ref: inputRef,
        id: input_id,
        name: name || attrs.name,
        placeholder: placeholder,
        maxLength: maxLength,
        className: styles(isToggleInput ? 'input' : inputClasses),
        readOnly: (readonly || loading),
        disabled, required,
        ...evtAttrs,
        ...(evtAttrs.onChange || isToggleInput ? { value } : { defaultValue: value }),
        ...(isToggleInput && (evtAttrs.onChange ? { checked } : { defaultChecked: checked }))
    };

    /* * * * * * * * *
     * HTML ELEMENT  *
    ** * * * * * * * */
    const htmlAttributes = { ...attrs, ...properties };
    let inputElement = null;
    switch (inputType) {
        case 'textarea':
            inputElement = <textarea {...htmlAttributes}>{htmlAttributes.value}</textarea>;
            break;
        case 'select':
            inputElement = (
                <Dropdown
                    inputRef={inputRef}
                    inputClasses={inputClasses}
                    inputHasFocus={inputHasFocus}

                    options={options}
                    multiple={multiple}
                    max={max}
                    collapsed={collapsed}
                    noInput={isObject(inputReference)}

                    placeholder={htmlAttributes.placeholder || translate('ui.select', language)}
                    placeholderIcon={placeholderIcon}
                    placeholderCustomIcon={placeholderCustomIcon}

                    callback={events.change}

                    value={value}
                    id={htmlAttributes.id}
                    name={htmlAttributes.name}
                    required={htmlAttributes.required}
                    disabled={htmlAttributes.disabled}
                />
            );
            break;
        default:
            htmlAttributes.type = inputType;
            if (inputType === 'number') {
                htmlAttributes.inputMode = 'numeric';
            }
            inputElement = <input {...htmlAttributes} />;
    }

    /* * * * *
     * LABEL *
    ** * * * */
    const labelContent = label || children;
    let labelElement;
    if (labelContent || isToggleInput) {
        const labelModifiers = []
            .concat(modifiers)
            .concat(inputType === 'select' ? 'dropdown' : null)
            .concat(htmlAttributes.disabled ? 'disabled' : null)
            .concat(isToggleInput && !modifiers.includes('button') ? 'toggle' : null);

        let labelElementClasses = ['label']
            .concat(addCounter ? ['flex', 'flex--justify-between', 'flex--align-baseline'] : []);
        labelElementClasses = addModifierClasses(labelModifiers, 'label', labelElementClasses);
        labelElementClasses = labelElementClasses.concat(labelClasses);

        const labelContentModifiers = []
            .concat(htmlAttributes.required ? 'required' : null)
            .concat(isToggleInput ? modifiers : null)
            .concat(isToggleInput && !modifiers.includes('button') ? 'toggle' : null);

        let labelElementContentClasses = ['label__content'];
        labelElementContentClasses = addModifierClasses(labelContentModifiers, 'label__content', labelElementContentClasses);
        labelElementContentClasses = labelElementContentClasses.concat(labelContentClasses);

        labelElement = (
            <label
                className={styles(labelElementClasses)}
                htmlFor={(inputType !== 'select' && htmlAttributes.id) || null}
                onClick={inputType === 'select' ? () => {
                    if (inputRef.current && !readonly && !disabled) {
                        inputRef.current.focus();
                    }
                } : null}
            >
                {isToggleInput ? inputElement : null}
                <span className={styles(labelElementContentClasses)}>
                    {labelContent}
                </span>
                {addCounter && (['textarea', 'text'].includes(inputType) || (inputType === 'select' && multiple && max > 1)) ? (
                    <CharacterCounter
                        current={value ? value.length : 0}
                        suffix={inputType === 'select' ? null : translate('ui.characters', props.language)}
                        min={inputType === 'select' ? 0 : (shouldValidate.minLength || (shouldValidate.required ? 1 : -1))}
                        max={inputType === 'select' ? max : (shouldValidate.maxLength || -1)}
                    />
                ) : null}
            </label>
        );
    }

    /* * * * * * * * * * * *
     * DESCRIPTION / ERROR *
    ** * * * * * * * * * * */
    const descriptionElement = useMemo(() => (
        <p className={styles('description', 'tiny', 'line-height--double', showInvalidState ? 'c--error' : 'c--text-light', modifiers.includes('stretch') ? 'description--stretch' : '')}>
            {showInvalidState ? (error || translate('ui.defaultInputError', language)) :
                loading ? <Dots min={1} max={5}/> :
                    description}
        </p>
    ), [showInvalidState, error, language, loading, description, modifiers]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    // button like
    if (['button', 'reset', 'submit'].includes(inputType)) {
        return <Button {...props} />;
    }

    // toggle input
    if (isToggleInput) {
        return labelElement;
    }

    // select
    if (inputType === 'select') {
        const wrapperClasses = addModifierClasses(modifiers, 'select-wrapper', ['select-wrapper']);
        return (
            <div className={styles(wrapperClasses)}>
                {labelElement}
                {inputElement}
                {descriptionElement}
            </div>
        );
    }

    // default
    return (
        <>
            {labelElement}
            {inputElement}
            {descriptionElement}
        </>
    );
});

Input.propTypes = {
    attrs: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.node,
    maxLength: PropTypes.number,

    classes: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),

    children: PropTypes.node,
    label: PropTypes.node,
    labelClasses: PropTypes.string,
    labelContentClasses: PropTypes.string,

    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    checked: PropTypes.bool,
    invalid: PropTypes.bool,

    loading: PropTypes.bool,
    touched: PropTypes.bool,

    description: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    shouldValidate: PropTypes.object,

    events: PropTypes.object,
    addCounter: PropTypes.bool,

    // select properties
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,
            value: PropTypes.any,
            text: PropTypes.string.isRequired,
            html: PropTypes.node,
            classes: PropTypes.string,
            icon: PropTypes.string,
            customIcon: PropTypes.string
        }).isRequired
    ),

    multiple: PropTypes.bool,
    max: PropTypes.number,
    placeholderIcon: PropTypes.string,
    placeholderCustomIcon: PropTypes.string,
    collapsed: PropTypes.bool,
    inputReference: PropTypes.object,

    language: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
        language: state.global.localization.language
    };
};

export default connect(mapStateToProps, null)(Input);
