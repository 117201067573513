import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { getStyles, translate, isInteger, isArray, isObject, isFullArray, isFullString, isEmptyString, pushEvent } from '../../../../shared/utility';

import SearchDropdown from './SearchDropdown/SearchDropdown';

import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import Tag from '../../../UI/Tag/Tag';
import Icon from '../../../UI/Icon/Icon';

import localStyles from './Search.module.scss';
const styles = getStyles(localStyles);

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputConfig: {
                type: 'text',
                name: 'input',
                id: this.props.id
            },
            searchValue: '',
            isDropdownOpen: false, // is the dropdown opened
            dropdownMode: 'toggle', // modes: 'autocomplete', 'toggle'
            hilightedPlaceId: null, // place_id of 'hilighted' element in autocomplete list
            historyItems: [],// array with recent searches,
            requestedCurrentLocation: false
        };

        this.refSearchBox = React.createRef();
    }

    /* * * * * * * * *
     * REACT METHODS *
     * * * * * * * * */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.searchAutocomplete.data !== this.props.searchAutocomplete.data && isArray(this.props.searchAutocomplete.data)) {
            const [ firstLocation ] = this.props.searchAutocomplete.data;
            this.setState({
                hilightedPlaceId: firstLocation.place_id,
                ...(!this.state.isDropdownOpen && { isDropdownOpen: true })
            });
        }

        if (prevState.isDropdownOpen !== this.state.isDropdownOpen) {
            this.state.isDropdownOpen ? document.addEventListener('click', this.documentOnClick) : document.removeEventListener('click', this.documentOnClick);
        }

        if (prevProps.searchLocation.data !== this.props.searchLocation.data && this.props.searchLocation.data) {
            this.props.onSetFilter('activeSearchLocation', this.props.searchLocation.data);
        }
        if (prevProps.filters.activeSearchLocation !== this.props.filters.activeSearchLocation && isObject(this.props.filters.activeSearchLocation)) {
            const { activeMainType, activeSearchLocation, selectedItemTypeIds } = this.props.filters;
            const historyItems = this.state.historyItems.filter(currentItem => currentItem.id !== activeSearchLocation.id).concat(activeSearchLocation);

            if (isObject(activeMainType)) {
                const location = isObject(activeSearchLocation) ? `?location=${activeSearchLocation.id}` : null;
                const itemTypes = isFullArray(selectedItemTypeIds) ? `${location ? '&' : '?'}itemTypes=${selectedItemTypeIds.toString()}` : null;
                this.props.history.push({ pathname: `/items/${activeMainType.id}`, search: `${location ? location : ''}${itemTypes ? itemTypes : ''}` });
            }

            this.setState({ searchValue: '', isDropdownOpen: false, dropdownMode: 'toggle', historyItems: historyItems });
        }

        if(prevProps.currentLocation !== this.props.currentLocation && !this.props.currentLocation.loading && this.props.currentLocation.error && this.state.requestedCurrentLocation) {
            this.props.addNotification({
                message: translate('notifications.details.currentLocation.error', this.props.language),
                //message: `${translate('notifications.details.currentLocation.error', this.props.language)} ${this.props.currentLocation.error}`,
                type: 'error'
            });
        }
    }


    /* * * * * * * * * * * * *
     * AUTOCOMPLETE METHODS  *
     * * * * * * * * * * * * */
    updateHilightedPlaceId = (placeId, addition) => {
        const currentIndex = this.props.searchAutocomplete.data.findIndex(item => item.place_id === this.state.hilightedPlaceId);
        const newIndex = Math.max(0, Math.min(this.props.searchAutocomplete.data.length - 1, (currentIndex+addition)));

        const newPlaceId = isInteger(addition) ? this.props.searchAutocomplete.data[newIndex].place_id : placeId;

        this.setState({ hilightedPlaceId: newPlaceId });
    }

    /* * * * * * * * * * * * * * *
     * CURRENT LOCATION METHODS  *
     * * * * * * * * * * * * * * */
    activateCurrentLocation = () => {
        if (this.props.currentLocation.data) {
            this.props.onSetFilter('activeSearchLocation', this.props.currentLocation.data);
        } else if (!this.props.currentLocation.loading && this.props.currentLocationAvailable) {
            this.props.onFetchCurrentLocation();
            this.setState({requestedCurrentLocation: true});
        }
    }

    /* * * * * * * * * * * * * *
     * MAIN ITEM TYPE METHODS  *
     * * * * * * * * * * * * * */
    toggleMainItemType = (id, name) => {
        this.setState({ isDropdownOpen: false });
        const { activeMainType, activeSearchLocation, selectedItemTypeIds } = this.props.filters;

        if (isObject(activeMainType) && activeMainType.id === id) {
            pushEvent('searchEvent', {eventCategory: 'Searchbar', eventAction: 'Toggle main item type: Off', eventLabel: name});
            this.props.onResetFilters(['activeMainType', 'activeItemTypes', 'selectedIds', 'pageNumber']);
            this.props.history.push({ pathname: '/' });
        } else {
            pushEvent('searchEvent', {eventCategory: 'Searchbar', eventAction: 'Toggle main item type: On', eventLabel: name});
            const location = isObject(activeSearchLocation) ? `?location=${activeSearchLocation.id}` : null;
            const itemTypes = isFullArray(selectedItemTypeIds) ? `${location ? '&' : '?'}itemTypes=${selectedItemTypeIds.toString()}` : null;

            this.props.history.push({ pathname: `/items/${id}`, search: `${location ? location : ''}${itemTypes ? itemTypes : ''}` });
        }
    }

    /* * * * * * * * * *
     * EVENT HANDLERS  *
     * * * * * * * * * */
    handleChange = (event) => {
        if (isFullString(event.target.value)) {
            this.props.getSearchAutocomplete(event.target.value, this.props.locale);
        }

        const dropdownMode = isFullString(event.target.value) ? 'autocomplete' : 'toggle';

        this.setState({ searchValue: event.target.value, dropdownMode: dropdownMode });
    }

    handleKeyUp = (event) => {
        if (this.state.dropdownMode === 'autocomplete') {
            switch (event.key) {
                case 'Enter':
                    event.preventDefault();
                    if(isArray(this.props.searchAutocomplete.data)) {
                        pushEvent('searchEvent', {eventCategory: 'Searchbar', eventAction: 'Autocomplete', eventLabel: 'On enter'});
                        this.props.getSearchLocationDetails(this.state.hilightedPlaceId);
                    }
                    break;

                default:
                    return;
            }
        }
    }

    handleKeyDown = (event) => {
        if (this.state.dropdownMode === 'autocomplete') {
            switch (event.key) {
                case 'ArrowDown':
                    event.preventDefault();
                    this.updateHilightedPlaceId(this.state.hilightedPlaceId, 1);
                    break;

                case 'ArrowUp':
                    event.preventDefault();
                    this.updateHilightedPlaceId(this.state.hilightedPlaceId, -1);
                    break;

                default:
                    return;

            }
        }
    }

    handleFocus = (event) => {
        pushEvent('searchEvent', {eventCategory: 'Searchbar', eventAction: 'Focus', eventLabel: 'None'});
        this.handleChange(event);
    }

    documentOnClick = (event) => {
        if (this.refSearchBox && this.refSearchBox.current && !this.refSearchBox.current.contains(event.target)) {
            this.setState({ isDropdownOpen: false });
        }
    }

    wrapperOnClick = () => {
        this.setState({ isDropdownOpen: true });
    }

    handleButtonClick = (event) => {
        event.stopPropagation();
        const { activeMainType, activeSearchLocation } = this.props.filters;

        if (isObject(activeSearchLocation) || isObject(activeMainType)) {
            pushEvent('searchEvent', {eventCategory: 'Searchbar', eventAction: 'Clear', eventLabel: 'None'});
            this.setState({ isDropdownOpen: false });
            this.props.onResetFilters(['activeMainType', 'activeItemTypes', 'selectedIds', 'activeSearchLocation']);

            this.props.history.push({ pathname: '/' });
        } else if (this.state.dropdownMode === 'autocomplete' && this.state.isDropdownOpen && isArray(this.props.searchAutocomplete.data)) {
            pushEvent('searchEvent', {eventCategory: 'Searchbar', eventAction: 'Clicked search button', eventLabel: 'None'});
            this.props.getSearchLocationDetails(this.state.hilightedPlaceId);
        }
    }

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const { activeMainType, activeSearchLocation } = this.props.filters;
        const locationTag = isObject(activeSearchLocation) ? (
            <Tag classes={styles('search__tag')}>
                {activeSearchLocation.value === 'CURRENT_LOCATION' ?
                    translate('toolbar.searchbar.currentLocation.tagValue', this.props.language) :
                    activeSearchLocation.value
                }
            </Tag>
        ) : null;

        const typeTag = isObject(activeMainType) ? (
            <Tag classes={styles('search__tag')}>{activeMainType.translations[this.props.language]}</Tag>
        ) : null;

        const searchTags = locationTag || typeTag ? (
            <div className={styles('search__tags-container', 'flex')}>
                {typeTag}
                {locationTag}
            </div>
        ) : null;

        return (
            <div className={styles('search')} ref={this.refSearchBox}>
                <div
                    className={styles('search__box', 'flex', 'flex--align-center')}
                    onClick={this.wrapperOnClick}
                >
                    <label
                        htmlFor={this.state.inputConfig.id}
                        className={styles('search__label')}
                    >
                        <Icon icon={'search'} />
                    </label>

                    {searchTags}

                    <Input
                        attrs={{ ...this.state.inputConfig }}
                        placeholder={translate('toolbar.searchbar.placeholder', this.props.language)}
                        value={this.state.searchValue}
                        events={{
                            keyDown: this.handleKeyDown,
                            keyUp: this.handleKeyUp,
                            focus: this.handleFocus,
                            change: this.handleChange
                        }}
                        classes={styles('search__input')}
                        modifiers={['no-border', 'collapsed', 's', 'stretch']}
                    />
                    {searchTags ? (
                        <Button
                            modifiers={['xxs', 'circle']}
                            classes={styles('search__button--remove')}
                            events={{ click: this.handleButtonClick}}
                        >
                            {'✕'}
                        </Button>
                    ) : (
                        <Button
                            modifiers={['m', 'primary']}
                            classes={styles('search__button')}
                            events={{ click: this.handleButtonClick}}
                            disabled={isEmptyString(this.state.searchValue)}
                        >
                            {translate('toolbar.searchbar.buttonText', this.props.language)}
                        </Button>
                    )}
                </div>

                <SearchDropdown
                    isOpen={this.state.isDropdownOpen}
                    mode={this.state.dropdownMode}
                    hilighted={this.state.hilightedPlaceId}
                    historyItems={this.state.historyItems}

                    updateHilighted={(placeId) => this.updateHilightedPlaceId(placeId)}
                    setActiveHistoryItem={(item) => this.props.onSetFilter('activeSearchLocation', item)}
                    setActiveCurrentLocation={this.activateCurrentLocation}
                    toggleMainItemType={(id, name) => this.toggleMainItemType(id, name)}
                />
            </div>
        );
    }
}

Search.propTypes = {
    // properties
    history: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    filters: PropTypes.object,

    searchAutocomplete: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    searchLocation: PropTypes.shape({
        //data: PropTypes.arrayOf(PropTypes.object),
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    currentLocationAvailable: PropTypes.bool.isRequired,
    currentLocation: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    locale: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,

    // functions
    onFetchCurrentLocation: PropTypes.func.isRequired,
    getSearchAutocomplete: PropTypes.func.isRequired,
    getSearchLocationDetails: PropTypes.func.isRequired,
    onSetFilter: PropTypes.func.isRequired,
    onResetFilters: PropTypes.func.isRequired,
    addNotification: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        filters: state.filters,

        searchAutocomplete: state.list.searchAutocomplete,
        searchLocation: state.details.searchLocation,
        currentLocationAvailable: state.global.currentLocationAvailable,
        currentLocation: state.details.currentLocation,

        locale: state.global.localization.locale,
        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchCurrentLocation: () => dispatch(actions.fetchCurrentLocation()),
        getSearchAutocomplete: (search, locale) => dispatch(actions.fetchSearchAutocomplete(search, locale)),
        getSearchLocationDetails: (locationId) => dispatch(actions.fetchSearchLocationDetails(locationId)),
        onSetFilter: (activeMainType, activeItemTypes) => dispatch(actions.setFilter(activeMainType, activeItemTypes)),
        onResetFilters: (activeMainType, activeItemTypes) => dispatch(actions.resetFilters(activeMainType, activeItemTypes)),
        addNotification: (data) => dispatch(actions.addNotification(data))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
