import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { getStyles, translate, isObject } from '../../../shared/utility';
import { fetchConditionFail } from '../../../store/actions/condition';

import Modal from '../../../components/UI/Modal/Modal';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';

import localStyles from './LinkPaymentAccount.module.scss';
const styles = getStyles(localStyles);

class LinkPaymentAccount extends Component{
    constructor(props){
        super(props);
        this.state = {
            touched: false,
            value: '',
            loading: false,
            error: null
        };
    }

    componentDidUpdate(prevProps, prevState){
        if(!prevProps.linkPaymentAccount.success && this.props.linkPaymentAccount.success){
            this.props.onCloseLinkPaymentAccountModal();
            return;
        }
        if(prevProps.isOpen && !this.props.isOpen){
            this.props.onResetLinkPaymentAccountError();
            this.setState({value: '', touched: false, loading: false, error: null});
            return;
        }
    }

    static getDerivedStateFromProps(props, state){
        const newState = {};
        if(props.linkPaymentAccount.loading !== state.loading){
            newState.loading = props.linkPaymentAccount.loading;
        }
        if(props.linkPaymentAccount.error !== state.error){
            newState.error = props.linkPaymentAccount.error;
        }
        if(Object.keys(newState).length){
            return newState;
        }
        return null;
    }

    render(){
        return this.props.isOpen ? (
            <Modal
                isOpen={this.props.isOpen}
                noButton={true}
                title={translate('pages.linkPaymentAccount.title', this.props.language)}
                close={() => { this.props.onCloseLinkPaymentAccountModal(); }}
                pages={[{
                    title: translate('pages.linkPaymentAccount.title', this.props.language),
                    content: (
                        <>
                            <p className="c--text-light line-height--small">
                                {translate('pages.linkPaymentAccount.description', this.props.language)}
                            </p>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                //e.currentTarget.children[0].children[1].onblur(); also works
                                if(document.activeElement.tagName.toLowerCase() === 'input'){
                                    document.activeElement.onblur();
                                }
                                this.props.onLinkPaymentAccount(this.state.value, this.props.paymentAccounts.data);
                            }}>
                                <fieldset>
                                    <Input
                                        required={true}
                                        value={this.state.value}
                                        label={translate('pages.linkPaymentAccount.companyCode', this.props.language)}
                                        modifiers={['stretch']}
                                        loading={this.state.loading}
                                        touched={this.state.touched}
                                        invalid={!this.state.loading && (!this.state.value.length || this.state.error !== null)}
                                        error={this.state.error}
                                        events={{
                                            change: (event) => {
                                                this.props.onResetLinkPaymentAccountError();
                                                this.setState({
                                                    touched: true,
                                                    value: event.target.value
                                                });
                                            }
                                        }}
                                    />
                                </fieldset>
                                <fieldset className={styles('align--right')}>
                                    <Button
                                        modifiers={['transparent', 'border', 'm', 'wide']}
                                        events={{
                                            click: () => { this.props.onCloseLinkPaymentAccountModal(); }
                                        }}
                                        disabled={this.state.loading}
                                    >{translate('ui.cancel', this.props.language)}</Button>
                                    <Button
                                        attrs={{ type: 'submit' }}
                                        modifiers={['primary', 'wide', 'm']}
                                        disabled={!this.state.value.length}
                                        loading={this.state.loading}
                                    >{translate('pages.linkPaymentAccount.saveButton', this.props.language)}</Button>
                                </fieldset>
                            </form>
                        </>
                    )
                }]}
            />
        ) : null;
    }
}

LinkPaymentAccount.propTypes = {
    isAuthorized: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    paymentAccounts: PropTypes.object,
    linkPaymentAccount: PropTypes.object.isRequired,
    onCloseLinkPaymentAccountModal: PropTypes.func.isRequired,
    onResetLinkPaymentAccountError: PropTypes.func.isRequired,
    onLinkPaymentAccount: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthorized: isObject(state.details.user.data),
        isOpen: state.global.isLinkPaymentAccountOpen,
        paymentAccounts: state.list.paymentAccounts,
        linkPaymentAccount: state.condition.linkPaymentAccount,
        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCloseLinkPaymentAccountModal: () => dispatch(actions.setGlobalState('isLinkPaymentAccountOpen', false)),
        onLinkPaymentAccount: (code, paymentAccounts) => dispatch(actions.linkPaymentAccount(code, paymentAccounts)),
        onResetLinkPaymentAccountError: () => dispatch(fetchConditionFail('linkPaymentAccount', null))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkPaymentAccount);
