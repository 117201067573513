import conditionReducer from './condition';
import detailsReducer from './details';
import listReducer from './list';
import pagedReducer from './paged';
import globalReducer from './global';
import filtersReducer from './filters';

// combine Redux reducers into one reducer to access Redux states accross the project
const rootReducer = {
    condition: conditionReducer,
    details: detailsReducer,
    list: listReducer,
    paged: pagedReducer,
    global: globalReducer,
    filters: filtersReducer
};

export default rootReducer;
