/* global gapi */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStyles, translate, isObject } from '../../../shared/utility';
import { validateInput } from '../../../shared/validation';
import * as actions from '../../../store/actions/index';

import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import PoweredBy from '../../../components/UI/PoweredBy/PoweredBy';
import Modal from '../../../components/UI/Modal/Modal';

import localStyles from './Login.module.scss';
const styles = getStyles(localStyles);

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: {
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    isEmail: true
                }
            },
            password: {
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true
                }
            },
            rememberUser: false,
            formIsValid: false,
            googleLoaded: false
        };
    }

    componentDidMount(){
        this._mounted = true;
        this.initGoogleSignIn();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        this.renderGoogleSignInButton();
    };

    initGoogleSignIn = () => {
        const scriptId = 'google-sign-in-script';
        if(!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/platform.js';
            script.async = true;
            script.defer = true;
            script.id = scriptId;
            script.onload = () => {
                gapi.load('auth2', () => {
                    if (this._mounted) {
                        gapi.auth2.init({
                            client_id : process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID
                        });
                        this.setState({googleLoaded: true});
                    }
                });
            };
            document.body.appendChild(script);
        } else {
            const script = document.getElementById(scriptId);
            if(script.getAttribute('gapi_processed') && !this.state.googleLoaded) {
                if (this._mounted) {
                    this.setState({ googleLoaded: true});
                }
            } else {
                const onload = script.onload;
                script.onload = () => {
                    if (this._mounted) {
                        if (!gapi.auth2) {
                            gapi.load('auth2', () => {
                                gapi.auth2.init({
                                    client_id : process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID
                                });
                            });
                        }
                        onload();
                        this.setState({googleLoaded: true});
                    }
                };
            }
        }
    }

    renderGoogleSignInButton = () => {
        if(this.state.googleLoaded && gapi.auth2) {
            const btn = document.getElementById('g-login');
            if(!btn) {
                return;
            }
            gapi.auth2.getAuthInstance().attachClickHandler(btn, {}, googleUser => {
                this.onGoogleSignIn(googleUser);
            });
        } else {
            this.initGoogleSignIn();
        }
    }

    onGoogleSignIn = (googleUser) => {
        const idToken = googleUser.getAuthResponse().id_token;
        const email = googleUser.getBasicProfile().getEmail();
        this.props.onFetchGoogleUserDetails(idToken, email);
    }

    handleChange = ({ target }) => {
        const updatedState = {
            userName: this.state.userName,
            password: this.state.password,
            [target.name]: {
                ...this.state[target.name],
                value: target.value,
                valid: validateInput(target.value, this.state[target.name].validation),
                touched: true
            }
        };

        const formIsValid = updatedState.userName.valid && updatedState.password.valid;

        this.setState({ ...updatedState, formIsValid });
    }

    handleRememberUserChange = () => {
        this.setState(prevState => ({ rememberUser: !prevState.rememberUser }));
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.formIsValid) {
            this.props.onFetchUserDetails(this.state.userName.value, this.state.password.value, this.state.rememberUser);
        }
    }

    handleCloseLoginModal = () => this.props.onSetGlobalState('isLoginOpen', false);

    handleResetPasswordRedirect = () => {
        this.handleCloseLoginModal();

        this.props.history.push({ pathname: '/start-reset/' });
    }

    handleOpenSignup = () => {
        this.handleCloseLoginModal();
        this.props.onSetGlobalState('isSignupOpen', true);
    }

    render() {
        const form = (
            <form className={styles('login__form')} onSubmit={this.handleSubmit}>
                <Input
                    attrs={{ type: 'email', name: 'userName', id: 'login-email' }}
                    modifiers={['stretch']}
                    label={translate('formControls.userName', this.props.language)}
                    required={true}
                    loading={this.props.userDetails.loading}
                    invalid={!this.state.userName.valid}
                    touched={this.state.userName.touched}
                    value={this.state.userName.value}
                    events={{ change: this.handleChange }}
                />
                <Input
                    attrs={{ type: 'password', name: 'password', id: 'login-password' }}
                    modifiers={['stretch']}
                    label={translate('formControls.password', this.props.language)}
                    required={true}
                    loading={this.props.userDetails.loading}
                    invalid={!this.state.password.valid}
                    touched={this.state.password.touched}
                    value={this.state.password.value}
                    events={{ change: this.handleChange }}
                />
                <Input
                    attrs={{ type: 'checkbox' }}
                    checked={this.state.rememberUser}
                    events={{ change: this.handleRememberUserChange }}
                    disabled={this.props.userDetails.loading}
                    modifiers={['stretch']}
                >
                    <p className={styles('c--text-light')}>
                        {translate('pages.login.rememberUser', this.props.language)}
                    </p>
                </Input>
                <Button
                    attrs={{ type: 'submit' }}
                    modifiers={['primary', 'stretch', 'm', 'spaced']}
                    disabled={!this.state.formIsValid}
                    loading={this.props.userDetails.loading}
                >{translate('pages.login.button', this.props.language)}</Button>
            </form>
        );

        const loginFooter= (
            <div className={styles('login__footer', 'flex', 'flex--wrap')}>
                <div className={styles('col--100 col__xxs--50')}>
                    <h6>{translate('pages.login.forgotPasswordTitle', this.props.language)}</h6>
                    <p>
                        <Button
                            modifiers={['text']}
                            classes={styles('c--text-light', 'underline')}
                            events={{ click: this.handleResetPasswordRedirect }}
                            disabled={this.props.userDetails.loading}
                        >{translate('pages.login.forgotPasswordLink', this.props.language)}</Button>
                    </p>
                </div>

                <div className={styles('col--100 col__xxs--50')}>
                    <h6>{translate('pages.login.signupTitle', this.props.language)}</h6>
                    <p>
                        <Button
                            modifiers={['text']}
                            classes={styles('c--text-light', 'underline')}
                            events={{ click: this.handleOpenSignup }}
                            disabled={this.props.userDetails.loading}
                        >{translate('pages.login.signupLink', this.props.language)}</Button>
                    </p>
                </div>

                {!this.props.noModal ? (
                    <PoweredBy />
                ) : null}
            </div>
        );

        const loginContent = (
            <>
                {this.state.googleLoaded && (
                    <div className={styles('google-sign-in-wrapper')}>
                        <div id="g-login" className={styles('google-sign-in-button')}>
                            <div className={styles('abcRioButton', 'abcRioButtonBlue')}>
                                <div className={styles('abcRioButtonContentWrapper')}>
                                    <div className={styles('abcRioButtonIcon')}>
                                        <div className={styles('abcRioButtonSvgImageWithFallback', 'abcRioButtonIconImage', 'abcRioButtonIconImage18')}>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className={styles('abcRioButtonSvg')}>
                                                <g>
                                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <span className={styles('abcRioButtonContents')}>
                                        <span>{translate('pages.login.loginWithGoogle', this.props.language)}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this.props.userDetails.error ? (
                    <p className={styles('c--error')}>{this.props.userDetails.error}</p>
                ) : null}
                {form}
                {loginFooter}
            </>
        );

        return this.props.isLoginOpen && !this.props.noModal ? (
            <Modal
                isOpen={this.props.isLoginOpen}
                title={translate('pages.login.title', this.props.language)}
                noClosing={this.props.userDetails.loading}
                close={this.handleCloseLoginModal}
            >
                {loginContent}
            </Modal>
        ) : !this.props.isLoginOpen && this.props.noModal ? (
            <div className={styles('container')}>
                {loginContent}
            </div>
        ): null;
    }
}

Login.propTypes = {
    // properties
    history: PropTypes.object.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
    isLoginOpen: PropTypes.bool.isRequired,
    noModal: PropTypes.bool,

    userDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,

    // functions
    onFetchUserDetails: PropTypes.func.isRequired,
    onFetchGoogleUserDetails: PropTypes.func.isRequired,
    onSetGlobalState: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthorized: isObject(state.details.user.data),
        isLoginOpen: state.global.isLoginOpen,

        userDetails: state.details.user,

        language: state.global.localization.language

    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchUserDetails: (userName, password, remember) => dispatch(actions.fetchUserDetails(userName, password, remember)),
        onFetchGoogleUserDetails : (idToken, email) => dispatch(actions.fetchGoogleUserDetails(idToken, email)),
        onSetGlobalState: (identifier, value) => dispatch(actions.setGlobalState(identifier, value))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
