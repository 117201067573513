import React from 'react';
import PropTypes from 'prop-types';
import { getStyles, addModifierClasses, addToArray, getTextColor, isString, isArray } from '../../../shared/utility';

import Button from '../Button/Button';

import localStyles from './Tag.module.scss';
const styles = getStyles(localStyles);

const tag = (props) => {

    if (!props.children) {
        return null;
    }
    
    const tagClasses = addToArray(props.classes,  addModifierClasses(props.modifiers, 'tag', ['tag']));
    const tagButtonClasses = addModifierClasses(props.modifiers, 'tag__button', ['tag__button']);
    const tagContentClasses = addModifierClasses(props.modifiers, 'tag__content', ['tag__content', 'ellipsis']);

    const htmlAttributes = props.color ? {
        style: {
            backgroundColor: props.color,
            color: getTextColor(props.color)
        }
    } :{};

    //const tagCloseButton = !props.events || !props.events.click ? tagButtonClasses.push('default-cursor') : null;
    const tagTitle = isString(props.children) ? props.children : (isArray(props.children) ? props.children.join('') : '');
    return (
        <span className={styles(tagClasses)} {...htmlAttributes}>
            <Button
                modifiers={['text', 'stretch']}
                classes={styles(tagButtonClasses)}
                events={props.events}
            >
                <abbr title={tagTitle} className={styles(tagContentClasses)}>
                    {props.children}
                </abbr>
            </Button>
        </span>
    );
};

tag.propTypes = {
    // properties
    children: PropTypes.node.isRequired,

    classes: PropTypes.string,
    modifiers: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.string,
    remove: PropTypes.bool,
    events: PropTypes.object
};

export default tag;
