import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getStyles } from '../../shared/utility';

import Header from '../../components/Elements/Header/Header';
import Footer from '../../components/Elements/Footer/Footer';

import Notifications from '../../components/UI/Notifications/Notifications';

import SelectPaymentAccount from '../../containers/User/SelectPaymentAccount/SelectPaymentAccount';
import LinkPaymentAccount from '../../containers/User/LinkPaymentAccount/LinkPaymentAccount';
import Login from '../../containers/User/Login/Login';
import Signup from '../../containers/User/Signup/Signup';
import DriversLicenseVerificationModal from '../../containers/User/DriversLicenseVerification/Modal/Modal';

import localStyles from './Layout.module.scss';
const styles = getStyles(localStyles);

const AGENT_IS_INTERNET_EXPLORER = !!document.documentMode;

function handleVoidActions() {
    return undefined;
}

const Layout = ({ headerHeight, hideHeader, hideFooter, children }) => {
    const mainElementRef = useRef();

    useEffect(() => {
        if (AGENT_IS_INTERNET_EXPLORER && mainElementRef.current) {
            mainElementRef.current.style.top = `${headerHeight}px`;
            mainElementRef.current.style.marginBottom = `${headerHeight}px`;
        }
    }, [headerHeight]);

    return (
        <>
            <Notifications />

            {hideHeader ? null : <Header onVoid={handleVoidActions} />}

            <Signup />
            <Login />
            <SelectPaymentAccount />
            <LinkPaymentAccount />
            <DriversLicenseVerificationModal />

            <main
                ref={mainElementRef}
                className={styles('layout')}
                onClick={handleVoidActions}
            >
                {children}
            </main>

            {hideFooter ? null : <Footer onVoid={handleVoidActions} />}
        </>
    );
};

Layout.propTypes = {
    // properties
    children: PropTypes.node.isRequired,
    hideHeader: PropTypes.bool,
    hideFooter: PropTypes.bool,
    headerHeight: PropTypes.number.isRequired
};

const mapStateToProps = state => {
    return {
        headerHeight: state.global.headerHeight
    };
};

export default connect(mapStateToProps, null)(Layout);
