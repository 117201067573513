/* global gapi */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { fetchConditionSuccess } from '../../../store/actions/condition';
import { getStyles, translate } from '../../../shared/utility';
import { validateInput } from '../../../shared/validation';

import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import PoweredBy from '../../../components/UI/PoweredBy/PoweredBy';
import Modal from '../../../components/UI/Modal/Modal';
import FlashMessage from '../../../components/UI/FlashMessage/FlashMessage';

import localStyles from './Signup.module.scss';
const styles = getStyles(localStyles);

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: {
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 1,
                    maxLength: 128
                }
            },
            email: {
                value: '',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    isEmail: true
                }
            },
            formIsValid: false,
            googleLoaded: false
        };
    }

    componentDidMount(){
        this._mounted = true;
        this.initGoogleSignIn();
    }

    componentWillUnmount(){
        this._mounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.isSignupOpen !== this.props.isSignupOpen && !this.props.isSignupOpen && this.props.signup.success) {
            // reset success message after closing the modal
            this.props.onFetchConditionSuccess('signup', false);
        }
        this.renderGoogleSignInButton();
    }

    initGoogleSignIn = () => {
        const scriptId = 'google-sign-in-script';
        if(!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.src = 'https://apis.google.com/js/platform.js';
            script.async = true;
            script.defer = true;
            script.id = scriptId;
            script.onload = () => {
                gapi.load('auth2', () => {
                    if (this._mounted) {
                        gapi.auth2.init({
                            client_id : process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID
                        });
                        this.setState({googleLoaded: true});
                    }
                });
            };
            document.body.appendChild(script);
        } else {
            const script = document.getElementById(scriptId);
            if(script.getAttribute('gapi_processed') && !this.state.googleLoaded) {
                if (this._mounted) {
                    this.setState({ googleLoaded: true});
                }
            } else {
                const onload = script.onload;
                script.onload = () => {
                    if (this._mounted) {
                        if (!gapi.auth2) {
                            gapi.load('auth2', () => {
                                gapi.auth2.init({
                                    client_id : process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID
                                });
                            });
                        }
                        onload();
                        this.setState({googleLoaded: true});
                    }
                };
            }
        }
    }

    renderGoogleSignInButton = () => {
        if(this.state.googleLoaded && gapi.auth2) {
            const btn = document.getElementById('g-signup');
            if(!btn) {
                return;
            }
            gapi.auth2.getAuthInstance().attachClickHandler(btn, {}, googleUser => {
                this.onGoogleSignIn(googleUser);
            });
        } else {
            this.initGoogleSignIn();
        }
    }

    onGoogleSignIn = (googleUser) => {
        const idToken = googleUser.getAuthResponse().id_token;
        const email = googleUser.getBasicProfile().getEmail();
        this.props.onFetchGoogleUserDetails(idToken, email);
    }

    handleChange = ({ target }) => {
        const updatedState = {
            firstName: this.state.firstName,
            email: this.state.email,
            [target.name]: {
                ...this.state[target.name],
                value: target.value,
                valid: validateInput(target.value, this.state[target.name].validation),
                touched: true
            }
        };

        const formIsValid = updatedState.firstName.valid && updatedState.email.valid;

        this.setState({ ...updatedState, formIsValid });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.state.formIsValid) {
            const url = window.location.origin;
            this.props.onSignup(this.state.firstName.value, this.state.email.value, this.props.communityDetails.data.id, url);
        }
    }

    handleCloseSignupModal = () => this.props.onSetGlobalState('isSignupOpen', false);

    handleResetPasswordRedirect = () => {
        this.handleCloseSignupModal();

        this.props.history.push({ pathname: '/start-reset/' });
    }

    handleOpenLogin = () => {
        this.handleCloseSignupModal();
        this.props.onSetGlobalState('isLoginOpen', true);
    }

    render() {
        const formContent = (
            <>
                {this.state.googleLoaded && (
                    <div className={styles('google-sign-in-wrapper')}>
                        <div id="g-signup" className={styles('google-sign-in-button')}>
                            <div className={styles('abcRioButton', 'abcRioButtonBlue')}>
                                <div className={styles('abcRioButtonContentWrapper')}>
                                    <div className={styles('abcRioButtonIcon')}>
                                        <div className={styles('abcRioButtonSvgImageWithFallback', 'abcRioButtonIconImage', 'abcRioButtonIconImage18')}>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className={styles('abcRioButtonSvg')}>
                                                <g>
                                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                    <span className={styles('abcRioButtonContents')}>
                                        <span>{translate('pages.signup.signupWithGoogle', this.props.language)}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <form className={styles('signup__form')} onSubmit={this.handleSubmit}>
                    <Input
                        attrs={{ type: 'text', name: 'firstName', id: 'signup-firstname' }}
                        label={translate('formControls.firstName', this.props.language)}
                        modifiers={['stretch']}
                        required={true}
                        loading={this.props.signup.loading}
                        invalid={!this.state.firstName.valid}
                        touched={this.state.firstName.touched}
                        value={this.state.firstName.value}
                        events={{ change: this.handleChange }}
                    />
                    <Input
                        attrs={{ type: 'email', name: 'email', id: 'signup-email' }}
                        label={translate('formControls.emailLong', this.props.language)}
                        modifiers={['stretch']}
                        required={true}
                        loading={this.props.signup.loading}
                        invalid={!this.state.email.valid}
                        touched={this.state.email.touched}
                        value={this.state.email.value}
                        events={{ change: this.handleChange }}
                    />
                    <Button
                        attrs={{type:'submit'}}
                        modifiers={['primary', 'stretch', 'm', 'spaced']}
                        disabled={!this.state.formIsValid}
                        loading={this.props.signup.loading}
                    >{translate('pages.signup.button', this.props.language)}</Button>
                </form>
            </>
        );

        const footerContent = (
            <div className={styles('signup__footer')}>
                <h6>{translate('pages.signup.loginTitle', this.props.language)}</h6>
                <p>
                    <Button
                        modifiers={['text']}
                        classes={styles('c--text-light', 'underline')}
                        events={{ click: this.handleOpenLogin }}
                        disabled={this.props.signup.loading}
                    >{translate('pages.signup.loginLink', this.props.language)}</Button>
                </p>
                {!this.props.noModal ? (
                    <PoweredBy />
                ) : null}
            </div>
        );

        const signupContent = (
            <>
                {this.props.signup.success ? (
                    <FlashMessage
                        success
                        noContainer
                        title={translate('pages.signup.successTitle', this.props.language)}
                        subtitle={translate('pages.signup.successSubtitle', this.props.language)}
                    />
                ) : (
                    <>
                        {this.props.signup.error ? (
                            <p className={styles('c--error')}>{this.props.signup.error}</p>
                        ): null}
                        {formContent}
                        {footerContent}
                    </>
                )}
            </>
        );

        return this.props.isSignupOpen && !this.props.noModal ? (
            <Modal
                isOpen={this.props.isSignupOpen}
                close={this.handleCloseSignupModal}
                title={!this.props.signup.success ? translate('pages.signup.title', this.props.language) : ''}
                noClosing={this.props.signup.loading}
            >
                {signupContent}
            </Modal>
        ) : !this.props.isSignupOpen && this.props.noModal ? (
            <div className={styles('container')}>
                {signupContent}
            </div>
        ) : null;
    }
}

Signup.propTypes = {
    // properties
    history: PropTypes.object.isRequired,
    isSignupOpen: PropTypes.bool.isRequired,
    noModal: PropTypes.bool,
    communityDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }).isRequired,

    signup: PropTypes.shape({
        success: PropTypes.bool,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }).isRequired,

    language: PropTypes.string.isRequired,

    // functions
    onSignup: PropTypes.func.isRequired,
    onSetGlobalState: PropTypes.func.isRequired,
    onFetchConditionSuccess: PropTypes.func.isRequired,
    onFetchGoogleUserDetails: PropTypes.func
};

const mapStateToProps = state => {
    return {
        isSignupOpen: state.global.isSignupOpen,
        communityDetails: state.details.community,

        signup: state.condition.signup,

        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignup: (name, email, communityId, url) => dispatch(actions.signup(name, email, communityId, url)),
        onSetGlobalState: (identifier, value) => dispatch(actions.setGlobalState(identifier, value)),
        onFetchConditionSuccess: (identifier, value) => dispatch(fetchConditionSuccess(identifier, value)),
        onFetchGoogleUserDetails : (idToken, email) => dispatch(actions.fetchGoogleUserDetails(idToken, email))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));
