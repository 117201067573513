import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReduxPropTypes } from '../../shared/propTypes';
import { connect } from 'react-redux';
import { debounce } from 'debounce';
import * as actions from '../../store/actions/index';
import { fetchListSuccess } from '../../store/actions/list';
import { setBrandColors, isObject, isNull, isUndefined, isInteger, isFullString } from '../../shared/utility';

import asyncComponent from '../../hoc/asyncComponent/asyncComponent';
import Layout from '../../hoc/Layout/Layout';

import Home from '../Home/Home';
import Invoice from '../Invoice/Invoice';

// Lazy load components, only import them if they are open by the user (code splitting, smaller bundle file size by splitting code into multiple files)
const asyncResults = asyncComponent(async () => await import('../Results/Results'));
const asyncItemDetails = asyncComponent(async () => await import('../Details/ItemDetails/ItemDetails'));
const asyncCatererDetails = asyncComponent(async () => await import('../Details/CatererDetails/CatererDetails'));

const asyncCompanyDetails = asyncComponent(async () => await import('../CompanyDetails/CompanyDetails'));

const asyncCheckoutItem = asyncComponent(async () => await import('../Checkout/Item/Item'));
const asyncCheckoutConfirmation = asyncComponent(async () => await import('../Checkout/Confirmation/Confirmation'));

const asyncCart = asyncComponent(async () => await import('../Cart/Cart'));
const asyncCheckoutCartMessage = asyncComponent(async () => await import('../Cart/CheckoutCartMessage/CheckoutCartMessage'));
const asyncPaymentPreferences = asyncComponent(async () => await import('../PaymentPreferences/PaymentPreferences'));
const asyncAddDirectDebit = asyncComponent(async () => await import('../PaymentPreferences/AddDirectDebit/AddDirectDebit'));

const asyncUserProfile = asyncComponent(async () => await import('../User/Profile/Profile'));
const asyncUpdateEmailAddress = asyncComponent(async () => await import('../User/Profile/UpdateEmailAddress/UpdateEmailAddress'));
const asyncStartPasswordReset = asyncComponent(async () => await import('../User/ResetPassword/StartPasswordReset'));
const asyncConfirmPasswordReset = asyncComponent(async () => await import('../User/ResetPassword/ConfirmPasswordReset'));
const asyncStartVerification = asyncComponent(async () => await import('../User/Verification/StartVerification'));
const asyncConfirmVerification = asyncComponent(async () => await import('../User/Verification/ConfirmVerification'));

const asyncBookingOverview = asyncComponent(async () => await import('../Bookings/Bookings'));
const asyncBooking = asyncComponent(async () => await import('../Bookings/Booking'));

const asyncExtendReservation = asyncComponent(async () => await import('../Bookings/Extend/Extend'));
const asyncExtendReservationConfirmation = asyncComponent(async () => await import('../Bookings/Extend/Confirmation/Confirmation'));

const asyncOrderOverview = asyncComponent(async () => await import('../Orders/Orders'));
const asyncOrderDetails = asyncComponent(async () => await import('../Orders/OrderDetails/OrderDetails'));

const asyncTicketOverview = asyncComponent(async () => await import('../Tickets/Tickets'));
const asyncTicketDetails = asyncComponent(async () => await import('../Tickets/TicketDetails/TicketDetails'));
const asyncAddTicket = asyncComponent(async () => await import('../Tickets/AddTicket/AddTicket'));

const asyncDriversLicenseVerification = asyncComponent(async () => await import('../User/DriversLicenseVerification/DriversLicenseVerification'));

const asyncRedirectUri = asyncComponent(async () => await import('../RedirectUri/RedirectUri'));
// const asyncTest = asyncComponent(async () => await import('../Test/Test'));

// const asyncVisitsOverview = asyncComponent(async () => await import('../Visits/Visits'));
// const asyncVisitDetails = asyncComponent(async () => await import('../Visits/VisitDetails/VisitDetails'));
// const asyncCreateMeeting = asyncComponent(async () => await import('../Visits/CreateMeeting/CreateMeeting'));
// const asyncAddVisitor = asyncComponent(async () => await import('../Visits/AddVisitors/AddVisitors'));
// const asyncEditMeeting = asyncComponent(async () => await import('../Visits/EditMeeting/EditMeeting'));
// const asyncEditVisitor = asyncComponent(async () => await import('../Visits/EditVisitor/EditVisitor'));

class App extends Component {
    /* * * * * * * * * * * * * *
     * REACT LIFECYCLE METHODS *
     * * * * * * * * * * * * * */
    componentDidMount() {
        if (!this.props.communityDetails.data && !this.props.communityDetails.loading) {
            this.props.onFetchCommunityDetails();
        }
        if (this.props.currentLocationAvailable) {
            this.props.onFetchCurrentLocation();
        }
        this.props.onCheckUserDataValidation();
        this.props.onSetLocalization(null);

        this.resizeHandler();
        this.props.onSetScrollBarWidth();

        window.addEventListener('resize', this.debouncedResizeHandler);
    }

    componentDidUpdate(prevProps, prevState) {
        const { id: paymentAccountId = null } = this.props.paymentAccountDetails.data || {};
        const { id: prevPaymentAccountId = null } = prevProps.paymentAccountDetails.data || {};

        const { id: communityId = null } = this.props.communityDetails.data || {};
        const { id: prevCommunityId = null } = prevProps.communityDetails.data || {};

        if (paymentAccountId !== prevPaymentAccountId || communityId !== prevCommunityId) {
            this.props.onFetchMainTypes();
            this.props.onFetchItemTypes();
        }

        // scroll back to top on page nav
        if (prevProps.location.pathname !== this.props.location.pathname) {
            if (!!document.documentMode) {
                window.location.reload(true);
            }
            window.scrollTo(0, 0);
        }

        if (communityId !== prevCommunityId && !isNull(communityId)) {
            // object destructorin, have default values for: data and applicationCustomization
            const {data: { applicationCustomization: { primaryColor, secondaryColor } = {}} = {}} = this.props.communityDetails;
            // set brand colors
            setBrandColors(primaryColor, secondaryColor);
        }

        if (prevProps.userRedirect !== this.props.userRedirect && this.props.userRedirect) {
            this.props.history.push( { pathname: this.props.userRedirect.pathname, search: this.props.userRedirect.search ? this.props.userRedirect.search : '' });
            this.props.onSetGlobalState('userRedirect', null);
        }

        if (prevProps.currentLocation.data !== this.props.currentLocation.data && this.props.currentLocation.data) {
            this.props.onFetchNearbyItemsPaged(this.props.currentLocation.data);
        }

        if (this.props.isAuthorized && isObject(this.props.userDetails.data.preferences)) {
            const {
                data: {
                    id: userId,
                    preferences: {
                        preferredLanguageCode,
                        preferredPaymentAccountId
                    } = {}
                } = {}
            } = this.props.userDetails;

            // when just logged in or loaded while already logged in
            if (prevProps.isAuthorized !== this.props.isAuthorized) {
                this.props.onSetGlobalState(['isLoginOpen','isSignupOpen','isMenuOpen', 'isLanguageMenuOpen'], false);

                this.props.onSetLocalization(preferredLanguageCode);

                if (isInteger(preferredPaymentAccountId)) {
                    this.props.onFetchPaymentAccountDetails(preferredPaymentAccountId);
                } else {
                    this.props.onFetchPaymentAccounts();
                }
            }

            if (prevProps.locale !== this.props.locale && isFullString(this.props.locale) && this.props.locale !== preferredLanguageCode) {
                this.props.onUpdateUserPreferences(userId, { preferredLanguageCode: this.props.locale });
            }

            // check for changed payment account id
            if (paymentAccountId !== prevPaymentAccountId) {
                this.props.onUpdateUserPreferences(userId, { preferredPaymentAccountId: paymentAccountId });
                this.props.onSetFilter('paymentAccountId', paymentAccountId);
                this.props.onFetchCartDetails(paymentAccountId);

                if (isObject(this.props.currentLocation.data) && !this.props.nearbyItemsPaged.loading) {
                    this.props.onFetchNearbyItemsPaged(this.props.currentLocation.data);
                }
            }
        }

        // if (prevProps.language !== this.props.language && isFullString(prevProps.language) && isFullString(this.props.language)) {
        //     // TODO: remove local translation for types, when proper backend translation is provided
        //     if (prevProps.mainTypesList === this.props.mainTypesList && isArray(this.props.mainTypesList.data)) {
        //         const updatedMainItemTypes = this.props.mainTypesList.data.map(type => (
        //             { ...type, ... { translatedName: translateType(type.name, this.props.language) } }
        //         ));
        //         this.props.onFetchListSuccess('mainTypes', updatedMainItemTypes);
        //     }
        //
        //     if (prevProps.itemTypesList === this.props.itemTypesList && isArray(this.props.itemTypesList.data)) {
        //         const updatedItemTypes = this.props.itemTypesList.data.map(type => (
        //             { ...type, ... { translatedName: translateType(type.name, this.props.language) } }
        //         ));
        //         this.props.onFetchListSuccess('itemTypes', updatedItemTypes);
        //     }
        //
        //     const { activeMainType, activeItemTypes, selectedItemTypeIds } = this.props.filters;
        //     const translatedMainType = isObject(activeMainType) ? (
        //         { ...activeMainType, ... { translatedName: translateType(activeMainType.name, this.props.language) } }
        //     ) : null;
        //     const translatedActiveItemTypes = isFullArray(activeItemTypes) ? activeItemTypes.map(type => (
        //         { ...type, ... { translatedName: translateType(type.name, this.props.language) } }
        //     )) : [];
        //
        //     this.props.onSetFilter('activeMainType', translatedMainType);
        //     this.props.onSetFilter('activeItemTypes', translatedActiveItemTypes);
        //     this.props.onSetFilter('selectedItemTypeIds', selectedItemTypeIds);
        // }

        this.props.onSetHeaderHeight();
        this.props.onSetStickyNavigationHeight();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.debouncedResizeHandler);
    }

    resizeHandler = () => {
        this.props.onSetWindowDimensions();
        this.props.onSetHeaderHeight();
        this.props.onSetStickyNavigationHeight();
    }

    debouncedResizeHandler = debounce(() => this.resizeHandler(), 200);

    /* * * * * *
     * RENDER  *
     * * * * * */
    render() {
        const availableRoutes = [
            /*
            {
                path: path to page
                component: <Component/> for route
                auth: true -> only authorized users; false -> only non-authorized users; undefined -> both
                exact:  true -> would check the exact path ('/' displays Home component only there is nothing else that follows),
                        false -> would accept additional characters ('/' displays Home component always, unless it comes after other Route items, then it will be checked last)
                hideHeader: true -> hides Header where not needed, false/missing (default) -> shows Header
                hideFooter: true -> hides Footer where not needed, false/missing (default) -> shows Footer
                hideWrap: true -> hides Layout wrapper/hoc where not needed, false/missing (default) -> uses Layout wrapper/hoc to display components
            },
            */
            {
                path: '/',
                component: Home,
                exact: true
            },
            {
                path: '/items/:mainTypeId/',
                component: asyncResults,
                hideFooter: true,
                exact: true
            },
            {
                path: '/item/:itemId/',
                component: asyncItemDetails,
                exact: true
            },
            {
                path: '/checkout/:itemId/confirmation',
                component: asyncCheckoutConfirmation,
                exact: true,
                auth: true
            },
            {
                path: '/checkout/:itemId/:restore?',
                component: asyncCheckoutItem,
                exact: true,
                auth: true
            },
            {
                path: '/caterer/:catererId/',
                component: asyncCatererDetails,
                exact: true
            },
            {
                path: '/company/:companyId/',
                component: asyncCompanyDetails,
                exact: true
            },
            {
                path: '/cart/',
                component: asyncCart,
                exact: true,
                auth: true
            },
            {
                path: '/cart/checkout/',
                component: asyncCheckoutCartMessage,
                auth: true
            },
            {
                path: '/profile/',
                component: asyncUserProfile,
                exact: true,
                auth: true
            },
            {
                path: '/profile/update-email/',
                component: asyncUpdateEmailAddress,
                exact: true,
                auth: true
            },
            {
                path: '/profile/drivers-license-verification-status/',
                component: asyncDriversLicenseVerification,
                exact: true,
                auth: true
            },
            {
                path: '/payment-preferences/',
                component: asyncPaymentPreferences,
                exact: true,
                auth: true
            },
            {
                path: '/payment-preferences/add/',
                component: asyncAddDirectDebit,
                exact: true,
                auth: true
            },
            {
                path: '/bookings/',
                component: asyncBookingOverview,
                exact: true,
                auth: true
            },
            {
                path: '/bookings/:bookingType/:bookingId/',
                component: asyncBooking,
                exact: true,
                auth: true,
            },
            {
                path: '/bookings/:bookingType/:bookingId/extend/confirmation',
                component: asyncExtendReservationConfirmation,
                exact: true,
                auth: true
            },
            {
                path: '/bookings/:bookingType/:bookingId/extend/:restore?',
                component: asyncExtendReservation,
                exact: true,
                auth: true
            },
            {
                path: '/bookings/:bookingType/:bookingId/report-problem/',
                component: asyncAddTicket,
                exact: true,
                auth: true
            },
            {
                path: '/orders/',
                component: asyncOrderOverview,
                exact: true,
                auth: true
            },
            {
                path: '/orders/:orderId/',
                component: asyncOrderDetails,
                exact: true,
                auth: true
            },
            {
                path: '/invoice/:invoiceId/',
                component: Invoice,
                hideWrap: true,
                exact: true,
                auth: true
            },
            {
                path: '/start-reset/',
                component: asyncStartPasswordReset,
                auth: false
            },
            {
                path: '/confirm-reset/',
                component: asyncConfirmPasswordReset,
                auth: false
            },
            {
                path: '/verification/start/',
                component: asyncStartVerification,
                auth: false
            },
            {
                path: '/verification/confirm/',
                component: asyncConfirmVerification,
                auth: false
            },
            {
                path: '/tickets/',
                component: asyncTicketOverview,
                exact: true,
                auth: true
            },
            {
                path: '/tickets/add/',
                component: asyncAddTicket,
                exact: true,
                auth: true
            },
            {
                path: '/tickets/:ticketId/',
                component: asyncTicketDetails,
                exact: true,
                auth: true
            },
            {
                path: '/redirect/',
                component: asyncRedirectUri
            },
            // {
            //     path: '/visits/',
            //     component: asyncVisitsOverview,
            //     exact: true,
            //     auth: true
            // },
            // {
            //     path: '/visits/add/',
            //     component: asyncCreateMeeting,
            //     exact: true,
            //     auth: true
            // },
            // {
            //     path: '/visits/add/visitors/',
            //     component: asyncAddVisitor,
            //     exact: true,
            //     auth: true
            // },
            // {
            //     path: '/visits/:visitId/',
            //     component: asyncVisitDetails,
            //     exact: true,
            //     auth: true
            // },
            // {
            //     path: '/visits/:visitId/add/',
            //     component: asyncAddVisitor,
            //     exact: true,
            //     auth: true
            // },
            // {
            //     path: '/visits/:visitId/edit/',
            //     component: asyncEditMeeting,
            //     exact: true,
            //     auth: true
            // },
            // {
            //     path: '/visits/:meetingId/:visitorId/edit/',
            //     component: asyncEditVisitor,
            //     exact: true,
            //     auth: true
            // }
        ];

        const routes = availableRoutes.filter(route => isUndefined(route.auth) || (this.props.isAuthorized && route.auth) || (!this.props.isAuthorized && !route.auth));

        return this.props.locale ? (
            <Switch>
                {routes.map((route, index) => (
                    <Route
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={!route.hideWrap ? null : route.component}
                        render={!route.hideWrap ? () => (
                            <Layout
                                hideFooter={!!route.hideFooter}
                                hideHeader={!!route.hideHeader}
                            >
                                <route.component/>
                            </Layout>
                        ) : null}
                    />
                ))}
                <Redirect to={'/'}/>
            </Switch>
        ) : null;
    }
}

App.propTypes = {
    // properties
    history: PropTypes.object.isRequired,
    location: PropTypes.shape( {
        search: PropTypes.string,
        pathname: PropTypes.string
    }).isRequired,

    isAuthorized: PropTypes.bool.isRequired,
    userDetails: ReduxPropTypes.details,
    userRedirect: PropTypes.object,
    communityDetails: ReduxPropTypes.details,
    currentLocationAvailable: PropTypes.bool.isRequired,
    currentLocation: ReduxPropTypes.details,

    mainTypesList: ReduxPropTypes.list,
    itemTypesList: ReduxPropTypes.list,

    filters: PropTypes.object,

    nearbyItemsPaged: ReduxPropTypes.paged,
    paymentAccountDetails: ReduxPropTypes.details,

    locale: PropTypes.string,
    language: PropTypes.string,

    // functions
    onFetchListSuccess: PropTypes.func.isRequired,
    onSetFilter: PropTypes.func.isRequired,
    onResetFilters: PropTypes.func.isRequired,
    onCheckUserDataValidation: PropTypes.func.isRequired,
    onFetchCommunityDetails: PropTypes.func.isRequired,
    onFetchMainTypes: PropTypes.func.isRequired,
    onFetchItemTypes: PropTypes.func.isRequired,
    onFetchNearbyItemsPaged: PropTypes.func.isRequired,
    onFetchCurrentLocation: PropTypes.func.isRequired,
    onFetchPaymentAccounts: PropTypes.func.isRequired,
    onFetchPaymentAccountDetails: PropTypes.func.isRequired,
    onUpdateUserPreferences: PropTypes.func.isRequired,
    onFetchCartDetails: PropTypes.func.isRequired,
    onSetLocalization: PropTypes.func.isRequired,
    onSetGlobalState: PropTypes.func.isRequired,
    onSetWindowDimensions: PropTypes.func.isRequired,
    onSetHeaderHeight: PropTypes.func.isRequired,
    onSetScrollBarWidth: PropTypes.func.isRequired,
    onSetStickyNavigationHeight: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        // user
        isAuthorized: isObject(state.details.user.data),
        userDetails: state.details.user,
        userRedirect: state.global.userRedirect,
        // community
        communityDetails: state.details.community,
        // current location
        currentLocation: state.details.currentLocation,
        currentLocationAvailable: state.global.currentLocationAvailable,
        mainTypesList: state.list.mainTypes,
        itemTypesList: state.list.itemTypes,
        filters: state.filters,
        nearbyItemsPaged: state.paged.nearbyItems,
        // payment accounts
        paymentAccountId: state.filters.paymentAccountId,
        paymentAccountDetails: state.details.paymentAccount,

        locale: state.global.localization.locale,
        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchListSuccess: (identifier, data) => dispatch(fetchListSuccess(identifier, data)),
        onSetFilter: (identifier, value) => dispatch(actions.setFilter(identifier, value)),
        onResetFilters: (identifiers) => dispatch(actions.resetFilters(identifiers)),
        onCheckUserDataValidation: () => dispatch(actions.checkUserDataValidation()),
        onFetchCommunityDetails: (subdomain) => dispatch(actions.fetchCommunityDetails(subdomain)),
        onFetchMainTypes: () => dispatch(actions.fetchMainTypes()),
        onFetchItemTypes: () => dispatch(actions.fetchItemTypes()),
        onFetchNearbyItemsPaged: (location) => dispatch(actions.fetchNearbyItems(location)),
        onFetchCurrentLocation: () => dispatch(actions.fetchCurrentLocation()),
        onFetchPaymentAccounts: () => dispatch(actions.fetchPaymentAccounts()),
        onFetchPaymentAccountDetails: (accountId) => dispatch(actions.fetchPaymentAccountDetails(accountId)),
        onUpdateUserPreferences: (userId, properties) => dispatch(actions.updateUserPreferences(userId, properties)),
        onFetchCartDetails: (paymentId) => dispatch(actions.fetchCartDetails(paymentId)),
        onSetLocalization: (locale) => dispatch(actions.setLocalization(locale)),
        onSetGlobalState: (identifier, value) => dispatch(actions.setGlobalState(identifier, value)),
        onSetWindowDimensions: () => dispatch(actions.setWindowDimensions()),
        onSetHeaderHeight: () => dispatch(actions.setHeaderHeight()),
        onSetStickyNavigationHeight: () => dispatch(actions.setStickyNavigationHeight()),
        onSetScrollBarWidth: () => dispatch(actions.setScrollBarWidth())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
