import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getStyles, translate, isObject } from '../../../../shared/utility';
import * as actions from '../../../../store/actions/index';

import Menu from '../../../Navigation/Menu/Menu';
import SubMenu from '../../../Navigation/Menu/SubMenu/SubMenu';

import Popup from '../../../UI/Popup/Popup';
import Button from '../../../UI/Button/Button';
import Icon from '../../../UI/Icon/Icon';

import CartButton from '../../../../containers/Cart/CartButton/CartButton';

import userPlaceholder from '../../../../assets/images/img-contact-placeholder.svg';

import localStyles from './NavItems.module.scss';
const styles = getStyles(localStyles);

class NavItems extends Component {

    toggleMenu = () => {
        this.props.onSetGlobalState('isMenuOpen');
        this.props.onSetGlobalState(['isMobileSearchOpen', 'isSignupOpen', 'isLoginOpen'], false);
    };

    toggleLocaleMenu = () => {
        this.props.onSetGlobalState('isLanguageMenuOpen');
        this.props.onSetGlobalState(['isMobileSearchOpen', 'isSignupOpen', 'isLoginOpen'], false);
    };

    toggleMobileSearch = () => {
        this.props.onSetGlobalState('isMobileSearchOpen');
        this.props.onSetGlobalState(['isMenuOpen', 'isLanguageMenuOpen', 'isSignupOpen', 'isLoginOpen'], false);
    };

    openLogin = (type) => {
        this.props.onSetGlobalState('isLoginOpen', true);
        this.props.onSetGlobalState(['isMenuOpen','isLanguageMenuOpen', 'isMobileSearchOpen'], false);
    };

    openSignup = (type) => {
        this.props.onSetGlobalState('isSignupOpen', true);
        this.props.onSetGlobalState(['isMenuOpen','isLanguageMenuOpen', 'isMobileSearchOpen'], false);
    };

    render() {
        const { data: paymentAccountData } = this.props.paymentAccountDetails;

        return (
            <nav>
                <ul className={styles('nav', 'no-list-style')}>
                    {this.props.isAuthorized ? (
                        /* Menu + button */
                        <li className={styles('nav__item', 'nav__user')}>
                            <Button modifiers={['text']} events={{ click: this.toggleMenu }}>
                                <img className={styles('nav__user__picture', 'nav__user__picture--border', 'circle')}
                                    src={paymentAccountData && paymentAccountData.imageReference ? paymentAccountData.imageReference.source : userPlaceholder}
                                    alt='' />
                                <Icon icon={'arrow-down'} classes={styles('nav__user__icon', 'portrait')} modifiers={['small']} />
                            </Button>
                            <Popup
                                isOpen={this.props.isMenuOpen}
                                close={this.toggleMenu}
                                arrowPosition={'right'}
                                fullscreenOnDevices
                                stretchMaxHeight
                            >
                                <Menu
                                    menu={'user'}
                                    type={'dropdown'}
                                    iconSize={'default'}
                                    items={[
                                        { title: 'menu.user.preferences', icon: 'settings', submenu: 'preferences' },
                                        { title: 'menu.user.logout', click: this.props.onLogout, icon: 'signout' }
                                    ]}
                                    close={() => { setTimeout(() => { this.toggleMenu(); }, 90); }}
                                />
                            </Popup>
                        </li>
                    ) : (
                        /* Portrait Login and Signup buttons */
                        <>
                            <li className={styles('nav__item', 'portrait')}>
                                <Button modifiers={['text']} events={{ click: this.openSignup }}>
                                    {translate('menu.main.signup', this.props.language)}
                                </Button>
                            </li>
                            <li className={styles('nav__item', 'portrait')}>
                                <Button modifiers={['text']} events={{ click: this.openLogin }}>
                                    {translate('menu.main.login', this.props.language)}
                                </Button>
                            </li>
                            {/* Language + button */}
                            <li className={styles('nav__item', 'nav__user')}>
                                <Button modifiers={['text']} events={{ click: this.toggleLocaleMenu }}>
                                    {this.props.language.toUpperCase()}
                                    <Icon icon={'arrow-down'} classes={styles('nav__user__icon', 'portrait')} modifiers={['small']} />
                                </Button>
                                <Popup
                                    isOpen={this.props.isLanguageMenuOpen}
                                    close={this.toggleLocaleMenu}
                                    fullscreenOnDevices={true}
                                    arrowPosition={'right'}
                                >
                                    <SubMenu menu={'language'} />
                                </Popup>
                            </li>
                            {/* Mobile Login and Signup buttons */}
                            <li className={styles('nav__item', 'nav__user', 'mobile-only')}>
                                <Button modifiers={['square']} events={{ click: this.toggleMenu }}>
                                    <Icon icon={'user'} modifiers={['large']} classes={styles('nav__mobile-icon')} />
                                </Button>
                                <Popup
                                    isOpen={this.props.isMenuOpen}
                                    close={this.toggleMenu}
                                    fullscreenOnDevices={true}
                                    arrowPosition={'right'}
                                >
                                    <Menu
                                        menu={'custom'}
                                        type={'dropdown'}
                                        iconSize={'default'}
                                        items={[
                                            { title: 'menu.main.signup', click: this.openSignup },
                                            { title: 'menu.main.login', click: this.openLogin }
                                        ]}
                                    />
                                </Popup>
                            </li>
                        </>
                    )}
                    {/* Mobile Search Button */}
                    <li className={styles('nav__item', 'nav__user', 'mobile-only')}>
                        <Button modifiers={['square']} events={{ click: this.toggleMobileSearch }}>
                            <Icon icon={'search'} modifiers={['large']} classes={styles('nav__mobile-icon')} />
                        </Button>
                    </li>
                    {/* Cart button */}
                    {this.props.isAuthorized ? (
                        <li className={styles('nav__item')}>
                            <CartButton />
                        </li>
                    ) : null}
                </ul>
            </nav>
        );
    }
}

NavItems.propTypes = {
    // properties
    isAuthorized: PropTypes.bool.isRequired,
    paymentAccountDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    isMenuOpen: PropTypes.bool,
    isLanguageMenuOpen: PropTypes.bool,

    locale: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,

    // functions
    onLogout: PropTypes.func.isRequired,
    onSetGlobalState: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        isAuthorized: isObject(state.details.user.data),
        paymentAccountDetails: state.details.paymentAccount,
        isMenuOpen: state.global.isMenuOpen,
        isLanguageMenuOpen: state.global.isLanguageMenuOpen,

        locale: state.global.localization.locale,
        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        onSetGlobalState: (identifier, value) => dispatch(actions.setGlobalState(identifier, value))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(NavItems);
