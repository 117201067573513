import React from 'react';
import PropTypes from 'prop-types';
import { addModifierClasses, addToArray, getStyles } from '../../shared/utility';

import Bar from '../UI/Loading/Bar/Bar';

import localStyles from './Logo.module.scss';
const styles = getStyles(localStyles);

const logo = (props) => {

    let logoClasses = addModifierClasses(props.modifiers, 'logo', 'logo');
    logoClasses = addToArray(props.classes, logoClasses);

    const type = props.logo || 'skopei';
    const src = props.custom || require(`../../assets/images/logos/${type}.svg`);

    return (
        <picture className={styles(logoClasses)}>
            {props.loading ? <Bar classes={styles('logo__img')} modifiers={['darker']} /> : <img src={src} className={styles('logo__img')} alt="" />}
        </picture>
    );
};

logo.propTypes = {
    // properties
    modifiers: PropTypes.arrayOf(PropTypes.string),
    classes: PropTypes.any,
    logo: PropTypes.string,
    custom: PropTypes.string,
    loading: PropTypes.bool,
};

export default logo;
