import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getStyles, isObject, isFunction } from '../../../shared/utility';

import Gallery from '../Gallery/Gallery';
import CardContent from './CardContent/CardContent';
import Bar from '../Loading/Bar/Bar';

import localStyles from './Card.module.scss';
const styles = getStyles(localStyles);

const card = (props) => {
    const gallery = (props.loading || (isObject(props.item) && props.item.imageReferences)) && !props.productMode ? (
        <div className={styles('card__gallery', `card__gallery--${props.viewMode}`)}>
            {props.loading ? (
                <Bar modifiers={['no-animation', 'fillout']} />
            ) : (
                <Gallery images={props.item.imageReferences} classes={styles(`gallery__${props.viewMode}`)} />
            )}
        </div>
    ) : null;

    const content = (
        <div className={styles(['card__content', `card__content--${props.viewMode}`, ...(props.viewMode === 'list' || props.viewMode === 'compact' ? ['flex', 'flex--column', 'flex--justify-between'] : [])])}>
            <CardContent
                loading={props.loading}
                item={props.loading ? null : props.item}
                viewMode={props.viewMode}
                catererMode={!!props.catererMode}
                productMode={!!props.productMode}
            />
        </div>
    );

    const cardClasses = ['card', `card--${props.viewMode}`, 'no-text-decoration']
        .concat(props.viewMode === 'list' || props.viewMode === 'card' || props.viewMode === 'compact' ? 'flex' : null)
        .concat(props.viewMode === 'card' ? 'flex--column' : null)
        .concat(props.loading ? 'card--loading' : null)
        .concat(props.productMode ? 'card--product' : null);

    return props.loading || props.viewMode === 'map' || props.viewMode === 'compact' ? (
        <div className={styles(cardClasses)}>
            {gallery}
            {content}
            {props.viewMode === 'map' && <div className={styles('card__arrow')}/>}
        </div>
    ) : isFunction(props.callback) ? (
        <div className={styles(cardClasses)} onClick={props.callback}>
            {gallery}
            {content}
        </div>
    ) : (
        <NavLink className={styles(cardClasses)} to={isObject(props.item) ? `/${props.catererMode ? 'caterer' : 'item'}/${props.item.id}` : null}>
            {gallery}
            {content}
        </NavLink>
    );
};

card.propTypes = {
    // properties
    item: PropTypes.object,
    loading: PropTypes.bool,
    viewMode: PropTypes.oneOf(['card', 'list', 'map', 'compact']).isRequired,
    catererMode: PropTypes.bool,
    productMode: PropTypes.bool,

    callback: PropTypes.func
};

export default card;
