import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HTMLNode } from '../../../shared/propTypes';
import { getStyles } from '../../../shared/utility';

import localStyles from './Popup.module.scss';
const styles = getStyles(localStyles);

const MIN_HORIZONTAL_OFFSET = 8;
const MIN_VERTICAL_OFFSET = 30; // also needs to account for arrow on top/bottom of content container
const MAX_HEIGHT = 470; // max height (magic number 🧙‍♂️)

const VALID_ARROW_POSITIONS = ['center', 'left', 'right'];

const Popup = React.memo((props) => {
    const {
        children, targets = [],
        classes =[],
        isOpen, arrowPosition, close,
        stretch, collapsed, overflow,
        fullscreenOnDevices, stretchMaxHeight,
        windowWidth, windowHeight, headerHeight
    } = props;

    const popupRef = useRef();

    const [verticalDirection, setVerticalDirection] = useState('bottom');
    const [isVisible, setIsVisible] = useState(false);
    const [contentOffset, setContentOffset] = useState(0);
    const [contentHeight, setContentHeight] = useState(0);

    useLayoutEffect(() => {
        if (popupRef.current && isOpen) {
            const { left, right, top } = popupRef.current.getBoundingClientRect();
            const vDir = top - headerHeight > windowHeight - top ? 'top' : 'bottom';

            setVerticalDirection(vDir);

            if (left < MIN_HORIZONTAL_OFFSET) {
                setContentOffset(MIN_HORIZONTAL_OFFSET - left);
            } else if (right > windowWidth - MIN_HORIZONTAL_OFFSET) {
                setContentOffset(right - windowWidth - MIN_HORIZONTAL_OFFSET);
            }

            if (vDir === 'bottom') {
                setContentHeight(Math.min(windowHeight - MIN_VERTICAL_OFFSET - top, MAX_HEIGHT));
            } else {
                setContentHeight(Math.min(top - 2 * MIN_VERTICAL_OFFSET - headerHeight, MAX_HEIGHT)); // 🧙‍♂️
            }

            setIsVisible(true);
        }

        return () => {
            setIsVisible(false);
            setVerticalDirection('bottom');
            setContentOffset(0);
            setContentHeight(0);
        };
    }, [isOpen, windowWidth, windowHeight, headerHeight]);

    useEffect(() => {
        const closeOnClickOutside = (e) => {
            const clickTargets = [popupRef.current].concat(targets);
            if (!clickTargets.find(node => node && node.contains(e.target))) {
                close();
            }
        };
        const closeOnEscape = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                close();
            }
        };

        if (isOpen) {
            document.body.addEventListener('click', closeOnClickOutside);
            document.body.addEventListener('keydown', closeOnEscape);
        }
        return () => {
            document.body.removeEventListener('click', closeOnClickOutside);
            document.body.removeEventListener('keydown', closeOnEscape);
        };
    }, [isOpen, close, targets]);

    /* * * * * *
     * OUTPUT  *
    ** * * * * */
    const arrowPos = VALID_ARROW_POSITIONS.includes(arrowPosition) ?
        arrowPosition :
        VALID_ARROW_POSITIONS[0];

    const popupClasses = classes
        .concat(['popup', `popup--${verticalDirection}`, `popup--arrow-${arrowPos}`])
        .concat(!stretch && collapsed ? 'popup--collapsed' : [])
        .concat(stretch ? ['popup--stretch', `popup--arrow-${arrowPos}--stretch`] : [])
        .concat(stretchMaxHeight ? 'popup--stretch-maxheight' : [])
        .concat(fullscreenOnDevices ? 'popup--fullscreen' : [])
        .concat(isOpen && isVisible ? ['popup--open', `popup--${verticalDirection}--open`, `popup--arrow-${arrowPos}--open`] : []);

    const popupStyle = windowWidth < 500 && fullscreenOnDevices ? {
        top: `${headerHeight}px`,
        ...(isOpen && { height: `${windowHeight - headerHeight}px` })
    } : null;

    return (
        <div
            ref={popupRef}
            className={styles(popupClasses)}
            style={popupStyle}
            onClick={(e) => { e.stopPropagation(); }}
        >
            <div className={styles('popup__arrow', `popup__arrow--${arrowPos}`, `popup__arrow--${verticalDirection}`)}/>
            <div
                className={styles(
                    'popup__content',
                    `popup__content--arrow-${arrowPos}`,
                    `popup__content--${verticalDirection}`,
                    'b--white',
                    stretchMaxHeight ? 'popup__content--stretch-maxheight' : '',
                    overflow ? 'popup__overflow' : ''
                )}
                style={{
                    ...(contentOffset && { transform: `translateX(${contentOffset}px)` }),
                    ...(!stretchMaxHeight && contentHeight && { maxHeight: `${contentHeight}px`} )
                }}
            >
                {children}
            </div>
        </div>
    );
});

Popup.propTypes = {
    // properties
    children: PropTypes.node.isRequired,
    targets: PropTypes.oneOfType([HTMLNode, PropTypes.arrayOf(HTMLNode)]),

    isOpen: PropTypes.bool.isRequired,

    classes: PropTypes.array,

    stretch: PropTypes.bool,
    collapsed: PropTypes.bool,
    overflow: PropTypes.bool,
    fullscreenOnDevices: PropTypes.bool,
    stretchMaxHeight: PropTypes.bool,
    arrowPosition: PropTypes.oneOf(VALID_ARROW_POSITIONS),

    headerHeight: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
    windowHeight: PropTypes.number.isRequired,

    // functions
    close: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        headerHeight: state.global.headerHeight,
        windowWidth: state.global.windowDimensions.width,
        windowHeight: state.global.windowDimensions.height
    };
};

export default connect(mapStateToProps, null)(Popup);
