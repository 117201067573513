import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStyles } from '../../../shared/utility';

import Logo from '../../Logo/Logo';
import NavItems from './NavItems/NavItems';
import Search from './Search/Search';

import localStyles from './Header.module.scss';
const styles = getStyles(localStyles);

const header = (props) => (
    <header className={styles('header')} id="header" onClick={props.onVoid}>
        <div className={styles('header__grid', props.content ? 'header__grid--custom' : '', 'flex', props.content ? '' : 'flex--justify-between', 'flex--align-center', 'b--white')}>

            <div className={styles('header__col')}>
                <NavLink to="/" exact activeClassName='active' className={styles('header__logo')}>
                    <Logo
                        custom={props.communityDetails.data && props.communityDetails.data.applicationCustomization.logoImageReference ?
                            props.communityDetails.data.applicationCustomization.logoImageReference.source : null}
                        loading={props.communityDetails.loading}
                    />
                </NavLink>
            </div>

            {props.content ? props.content : (
                <>
                    <div className={styles('portrait', 'header__search')}>
                        <Search id={'desktop-search'} />
                    </div>
                    <NavItems />
                </>
            )}
        </div>

        {props.isMobileSearchOpen ? (
            <div className={styles(['header__mobile-search', 'b--white', 'mobile-only'])}>
                <Search id={'mobile-search'} />
            </div>
        ) : null}
    </header>
);

header.propTypes = {
    // properties
    content: PropTypes.node,
    communityDetails: PropTypes.shape({
        data: PropTypes.object,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),
    isMobileSearchOpen: PropTypes.bool,

    // functions
    onVoid: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        communityDetails: state.details.community,
        isMobileSearchOpen: state.global.isMobileSearchOpen
    };
};

export default connect(mapStateToProps, null)(header);
