/* * * * * * * * * * * * *
 * FORM INPUT VALIDATION *
 * * * * * * * * * * * * */
import { isUndefined, isEmptyString, isFullString, isFullArray } from './utility';

const FORBIDDEN_PASSWORDS = ['123456', '123456789', 'qwerty', '12345678', '111111', '1234567890', '1234567', 'password', '123123', '987654321', 'qwertyuiop', 'mynoob', '123321', '666666', '18atcskd2w', '7777777', '1q2w3e4r', '654321', '555555', '3rjs1la7qe', 'google', '1q2w3e4r5t', '123qwe','zxcvbnm','1q2w3e'];

const toLowerCaseArray = (input) => [].concat(input).map(str => `${str}`.toLowerCase());

export const validateInput = (value, rules, data = {}) => {
    if (!rules || (!rules.required && isEmptyString(value))) {
        return true;
    }

    const availableRules = [
        {
            name: 'required',
            test: (val) => isFullString(val.toString())
        },
        {
            name: 'minLength',
            test: (val) => val.length >= rules.minLength
        },
        {
            name: 'maxLength',
            test: (val) => val.length <= rules.maxLength
        },
        {
            name: 'isEmail',
            test: (val) => {
                // const pattern = /[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                const pattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
                return pattern.test(val);
            }
        },
        {
            name: 'isUrl',
            test: (val) => {
                const pattern = '^(?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$';
                return pattern.test(val);
            }
        },
        {
            name: 'isNumeric',
            test: (val) => {
                const pattern = /^\d+$/;
                return pattern.test(val);
            }
        },
        {
            name: 'isPassword',
            test: (val) => {
                // not one of the top 25 common passwords
                return !FORBIDDEN_PASSWORDS.includes(val);
            }
        },
        {
            name: 'is',
            test: (val) => {
                if (!isFullString(rules.is)) {
                    return true;
                }
                return val === rules.is;
            }
        },
        {
            name: 'isNot',
            test: (val) => {
                if (!isFullArray(rules.isNot) && !isFullString(rules.isNot)) {
                    return true;
                }
                const isNot = toLowerCaseArray(rules.isNot);
                return !isNot.includes(`${val}`.toLowerCase());
            }
        },
        {
            name: 'contains',
            test: (val) => {
                if (!isFullArray(rules.contains) && !isFullString(rules.contains)) {
                    return true;
                }
                const containsValues = toLowerCaseArray(rules.contains);

                let contains = false;
                containsValues.forEach((test) => {
                    if (val.toLowerCase().includes(test)) {
                        contains = true;
                    }
                });

                return contains;
            }
        },
        {
            name: 'containsNot',
            test: (val) => {
                if (!isFullArray(rules.containsNot) && !isFullString(rules.containsNot)) {
                    return true;
                }
                const containsNotValues = [].concat(rules.containsNot).map((str) => `${str}`.toLowerCase());

                let contains = false;
                containsNotValues.forEach((test) => {
                    if (val.toLowerCase().includes(test)) {
                        contains = true;
                    }
                });

                return !contains;
            }
        },
        {
            name: 'isDutchAddress',
            test: (val) => {
                const pattern = /^([1-9][e][\s])*([a-zA-Z]+(([.][\s])|([\s]))?)+[1-9][0-9]*(([-][1-9][0-9]*)|([\s]?[a-zA-Z]+))?$/i;
                return pattern.test(val);
            }
        },
        {
            name: 'isDutchPostcode',
            test: (val) => {
                const pattern = /^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/i;
                return pattern.test(val);
            }
        },
        {
            name: 'isDutchMobileNumber',
            test: (val) => {
                const pattern_vast = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\\+31|0|0031)[1-9][0-9][-]?[1-9][0-9]{6}))$/;
                const pattern_mobiel = /^(((\\+31|0|0031)6){1}[1-9]{1}[0-9]{7})$/i;
                return (pattern_vast.test(val) || pattern_mobiel.test(val));
            }
        },
        {
            name: 'isDutchBankNumber',
            test: (val) => {
                const pattern = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i;
                return pattern.test(val);
            }
        },
        {
            name: 'custom',
            test: rules.custom
        }
    ];

    let isValid = true;

    availableRules.forEach(({ name, test }) => {
        if (!isUndefined(rules[name]) && !test(value)) {
            isValid = false;
        }
    });

    return isValid;
};
