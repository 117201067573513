import React from 'react';
import PropTypes from 'prop-types';
import { getStyles, addModifierClasses, addToArray } from '../../../shared/utility';

import localStyles from './Icon.module.scss';
const styles = getStyles(localStyles);

const Icon = (props) => {
    if (!props.icon && !props.customIcon) {
        return null;
    }

    let iconClasses = ['icon'];
    iconClasses = addModifierClasses(props.modifiers, 'icon', iconClasses);
    iconClasses = addToArray(props.classes, iconClasses);

    const attrs = props.attrs ? props.attrs : null;

    const src = props.customIcon || require(`../../../assets/icons/ic-${props.icon}${props.color ? '-' + props.color : ''}.svg`);
    return (
        <img className={styles(iconClasses)} alt="" src={src} {...attrs} />
    );
};

Icon.modifiers = [
    {
        name: 'default',
        description: 'Default size: `rem(19)` x `rem(19)`. Only use this modifier to overwrite width or height.'
    },
    {
        name: 'small',
        description: 'Small size: `rem(14)` x `rem(14)`.'
    },
    {
        name: 'medium',
        description: 'Medium size: `rem(21)` x `rem(12)`.'
    },
    {
        name: 'large',
        description: 'Large size: `rem(32)` x `rem(32)`.'
    },
    {
        name: 'extra-large',
        description: 'Extra large size: `rem(36)` x `rem(36)`.'
    },
    {
        name: 'huge',
        description: 'Huge size: `rem(62)` x `rem(62)`.'
    },
    {
        name: 'inline',
        description: 'Size `1em` x `1em` and margin right of `0.5em`. In combination with `small` `0.8em` x `0.8em`; in combination with `large` `1.5em` x `1.5em`.'
    },
    {
        name: 'after',
        description: 'Only applies to `inline` Icons. Removes margin right and adds margin left `0.5em`.'
    }
];

Icon.propTypes = {
    /** looks up `/assets/icons/ic-${icon}.svg`*/
    icon: PropTypes.string,
    /** looks up `/assets/icons/ic-${icon}-${color}.svg`*/
    color: PropTypes.string,
    /** path to custom image. Overwrites `icon` property.*/
    customIcon: PropTypes.string,
    /** icon modifiers */
    modifiers: PropTypes.oneOfType([
        PropTypes.oneOf(Icon.modifiers.map(mod => mod.name)),
        PropTypes.arrayOf(PropTypes.oneOf(Icon.modifiers.map(mod => mod.name)))
    ]),
    /** extra css classes */
    classes: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    /** Object with attributes add to outputted `<img/>`*/
    attrs: PropTypes.object
};

Icon.defaultProps = {
    icon: undefined,
    color: undefined,
    customIcon: undefined,
    modifiers: undefined,
    classes: undefined,
    attrs: undefined
};

export default Icon;
