import PropTypes from 'prop-types';

const defaultReduxPropTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ])
};

export const ReduxPropTypes = {
    condition: PropTypes.shape({
        success: PropTypes.bool.isRequired,
        ...defaultReduxPropTypes
    }),
    details: PropTypes.shape({
        data: PropTypes.object,
        ...defaultReduxPropTypes
    }),
    list: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object.isRequired),
        ...defaultReduxPropTypes
    }),
    paged: PropTypes.shape({
        data: PropTypes.shape({
            hasMore: PropTypes.bool.isRequired,
            pageNumber: PropTypes.number.isRequired,
            pageSize: PropTypes.number.isRequired,
            total: PropTypes.number.isRequired,
            results: PropTypes.arrayOf(PropTypes.object.isRequired)
        }),
        ...defaultReduxPropTypes
    })
};

/* * * * * *
 * COLORS  *
** * * * * */
const COLORS = ['white', 'gray-1', 'gray-2', 'gray-3', 'gray-4', 'gray-5', 'gray-6', 'black', 'background', 'error', 'valid', 'success', 'warning', 'brand', 'brand-faded', 'brand-light', 'brand-dark', 'brand-secondary', 'brand-secondary-faded', 'brand-secondary-dark'];

export const ColorPropType = PropTypes.oneOf(COLORS);

/* * * * * * *
 * UTILITIES *
** * * * * * */
function getErrorMessage (propName, componentName, suffix ) {
    return new Error('Warning: Failed prop type: The prop `' + propName + '` is ' + suffix + '.');
}

function getRequiredErrorMessage (propName, componentName, value) {
    const valueString = `${value}`;
    return getErrorMessage(propName, componentName, 'marked as required in `' + componentName + '`, but its value is `' + valueString + '`');
}

function propTypeFactory (test, errorSuffix) {
    function check (isRequired, props, propName, componentName) {
        const value = props[propName];
        if (isRequired && (value === null || value === undefined)) {
            return getRequiredErrorMessage(propName, componentName, value);
        }
        if (value && !test(value)) {
            return getErrorMessage(propName, componentName, errorSuffix);
        }
        return null;
    }

    const chainedCheck = check.bind(null, false);
    chainedCheck.isRequired = check.bind(null, true);

    return chainedCheck;
}

/* * * * * * * * *
 * ISO DURATION  *
** * * * * * * * */
export const ISODuration = propTypeFactory(
    (value) => /^P(?!$)(((\d+)Y)?((\d+)M)?((\d+)D)?(T((\d+)H)?((\d+)M)?((\d+)S)?)?)$/.test(value),
    'no valid ISO 8601 Duration'
);

/* * * * * * *
 * HTML NODE *
** * * * * * */
export const HTMLNode = propTypeFactory(
    (value) => !!value.tagName,
    'not a valid HTML element.'
);
