import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { getStyles, translate, isFullArray, isArray, pushEvent } from '../../../../../shared/utility';

import Button from '../../../../UI/Button/Button';
import Icon from '../../../../UI/Icon/Icon';

import localStyles from './SearchAutocomplete.module.scss';
const styles = getStyles(localStyles);

const searchAutocomplete = (props) => {
    const autocompleteList = isFullArray(props.searchAutocomplete.data) ? props.searchAutocomplete.data : (
        isArray(props.searchAutocomplete.data) ? [{ 
            place_id: 'ZERO_RESULTS', 
            description: translate('errors.search.noresultsAutocomplete', props.language)
        }] : []
    );

    const autocompleteElement = autocompleteList.map(item => (
        <li key={item.place_id}
            className={styles('autocomplete__item', (props.hilighted === item.place_id ? 'autocomplete__item--hilighted' : null))}
            onMouseEnter={() => props.updateHilighted(item.place_id)}
        >
            <Button
                modifiers={['text', 'stretch']}
                classes={styles('autocomplete__item__button')}
                events={{ click: () => {
                    props.getSearchLocationDetails(item.place_id);
                    pushEvent('searchEvent', {eventCategory: 'Searchbar', eventAction: 'Autocomplete', eventLabel: 'On clicking on autocomplete item.'});
                }}}
            >
                <Icon icon={'location'} modifiers={['inline']} />
                <span className={styles('autocomplete__item__content')}>
                    {item.description}
                </span>
            </Button>
        </li>
    ));

    return (
        <ul className={styles('autocomplete', 'no-list-style')}>
            {autocompleteElement}
        </ul>
    );

};

searchAutocomplete.propTypes = {
    // properties
    hilighted: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),

    searchAutocomplete: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.object),
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }),

    language: PropTypes.string.isRequired,

    // functions
    getSearchLocationDetails: PropTypes.func.isRequired,
    updateHilighted: PropTypes.func.isRequired
};

const mapStateToProps = state => {
    return {
        searchAutocomplete: state.list.searchAutocomplete,

        language: state.global.localization.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSearchLocationDetails: (locationId) => dispatch(actions.fetchSearchLocationDetails(locationId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(searchAutocomplete);
